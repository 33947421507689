import React from "react";
import { FastField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

import { FieldProps } from "formik/dist/Field";
import { ErrorLabel, ExtraLabel } from "components";
import { parseErrorMessageByFieldName } from "utils/parse";

interface Option {
  label: string;
  value: boolean;
}

interface FormikRadioGroupProps {
  name: string;
  options: Option[];
  extraLabel?: string;
}

export const FormikRadioButtonField = ({
  name,
  options,
  extraLabel,
}: FormikRadioGroupProps) => {
  const { t } = useTranslation();
  const { setFieldValue, errors, submitCount } = useFormikContext();

  const errorMessage = parseErrorMessageByFieldName(errors, name);
  const displayError = submitCount && errorMessage;

  return (
    <>
      {extraLabel && <ExtraLabel label={extraLabel} />}
      <FastField name={name}>
        {({ field }: FieldProps<boolean>) => (
          <FormGroup row>
            {options.map((rb) => (
              <FormControlLabel
                key={String(rb.value)}
                control={
                  <Checkbox
                    checkedIcon={<RadioButtonCheckedIcon />}
                    icon={<RadioButtonUncheckedIcon />}
                    checked={rb.value === field.value}
                    onChange={() => setFieldValue(name, rb.value)}
                  />
                }
                label={t(rb.label)}
              />
            ))}
          </FormGroup>
        )}
      </FastField>
      {!!displayError && <ErrorLabel message={errorMessage} />}
    </>
  );
};
