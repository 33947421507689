import styled from 'styled-components';
import { TextField } from '@mui/material';

export const TextFieldSelect = styled(TextField)`
  .MuiSelect-select {
    height: 2rem;
    white-space: normal !important;
  }

,
`
