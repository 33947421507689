import React, { useContext, useState } from "react";
import { FieldArray, Formik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { AlertColor } from "@mui/material/Alert/Alert";
import {
  CASE_BY_ID_SHORT,
  HouseholdMemberShort,
} from "graphql/query/caseByIdShort";
import { Notification } from "components/Notification";
import {
  CREATE_FILE_UPLOAD,
  CREATE_FILE_UPLOAD_data,
  CREATE_FILE_UPLOAD_vars,
} from "graphql/mutation/createFileUpload";
import { routes } from "routes";
import { FormActionButtons } from "components/FormActionButtons";
import { UploadDocumentRow } from "./UploadDocumentRow";
import { getFileExtensionWithDot } from "utils/strings";
import { NotFound } from "components/NotFound";
import { CONFIRM_FILE_UPLOAD } from "graphql/mutation/confirmFileUplaod";
import { usePersistentCurrentStep } from "hooks/usePersistentCurrentStep";
import { DataFormsContext } from "context/DataFormsContext";
import { Loader } from "components/Loader";
import { Form, FormContent, MemberRows } from "../Forms.css";
import { documentKeyByType, validationSchema } from "./formUtils";
import { ButtonContainer } from "./UploadDocuments.css";

export interface UploadFormValues {
  uploadDocumentsMembers: HouseholdMemberShort[];
}

export const UploadDocuments = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCurrentStep] = usePersistentCurrentStep(1);
  const { updateData } = useContext(DataFormsContext);

  const { id } = useParams<{ id: string }>();
  const caseId = String(id);
  const navigate = useNavigate();

  const {
    loading,
    data: householdMemberData,
    error,
  } = useQuery(CASE_BY_ID_SHORT, {
    variables: { caseAccessId: caseId },
  });

  const [createFileUpload] = useMutation<
    CREATE_FILE_UPLOAD_data,
    CREATE_FILE_UPLOAD_vars
  >(CREATE_FILE_UPLOAD);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmUpload] = useMutation(CONFIRM_FILE_UPLOAD);
  const [messageType, setMessageType] = React.useState<AlertColor>("success");
  const [uploadMessage, setUploadMessage] = React.useState<string | null>(null);

  if (loading || isLoading) return <Loader />;
  if (!loading && !householdMemberData && error) {
    return <NotFound />;
  }

  const householdMembers =
    householdMemberData?.caseById?.mnsureEnrollment?.householdMembers ?? [];
  const transformedHouseholdMembers = householdMembers.map(
    (m: any, idx: number) => ({
      caseAccessId: caseId,
      householdMemberId: m.id,
      name: `${m.firstName} ${m.lastName}`,
      documentsList: !idx
        ? [
            {
              documentDescription: "",
              documentUploadType: "",
              file: undefined,
            },
          ]
        : [],
    }),
  );

  const onBack = async () => {
    await updateData((_) => null);
    await setCurrentStep(1);
    navigate(routes.form);
  };

  const onSubmit = async ({ uploadDocumentsMembers }: UploadFormValues) => {
    setIsLoading(true);
    try {
      for (const member of uploadDocumentsMembers) {
        for (const document of member.documentsList) {
          const documentUploadType =
            documentKeyByType[document.documentUploadType];
          const fileExtension = getFileExtensionWithDot(
            document?.file?.name ?? "",
          );

          const variables = {
            caseAccessId: caseId,
            fileExtension: fileExtension,
            householdMemberId: member.householdMemberId,
            documentDescription: document.documentDescription,
            documentUploadType: documentUploadType,
          };

          const createFileRes = await createFileUpload({
            variables,
          });

          const url = createFileRes.data?.createFileUpload.url as string;
          const fields = createFileRes.data?.createFileUpload.fields as string;
          await uploadToS3(document.file, url, fields);
          const confirmResponse = await confirmUpload({
            variables: {
              fileUploadId: createFileRes.data?.createFileUpload.id,
              fileUploadResult: null,
            },
          });
          setIsLoading(false);
          if (confirmResponse.data?.confirmFileUpload?.status) {
            navigate(routes.uploadSuccess);
          } else {
            setUploadMessage(`Something went wrong`);
            setMessageType("error");
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      setUploadMessage(`Error during the upload process:", ${error}`);
      setMessageType("error");
    }
  };

  const uploadToS3 = async (
    file: File | undefined,
    url: string,
    fields: string,
  ) => {
    let isSuccess;
    let responseHeaders = null;
    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]: any) => {
      formData.append(key, value);
    });

    if (file) {
      formData.append("file", file);
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      isSuccess = response.ok;
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(
          `Server responded with ${response.status}: ${errorMessage}`,
        );
      }

      responseHeaders = {
        xAmzId2: response.headers.get("X-Amz-Id-2"),
        xAmzRequestId: response.headers.get("X-Amz-Request-Id"),
        awsDate: response.headers.get("Date"),
        ETag: response.headers.get("ETag"),
      };
    } catch (error) {
      isSuccess = false;
    }
    return { isSuccess, responseHeaders };
  };

  return (
    <>
      <Formik
        initialValues={{ uploadDocumentsMembers: transformedHouseholdMembers }}
        enableReinitialize={false}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await onSubmit(values);
        }}
      >
        {({ errors, setFieldValue, handleSubmit, values }) => {
          return (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <FieldArray name="uploadDocumentsMembers">
                {() => (
                  <FormContent>
                    <MemberRows>
                      {values.uploadDocumentsMembers.map(
                        (member: any, index: number) => (
                          <FieldArray
                            key={index}
                            name={`uploadDocumentsMembers.${index}.documentsList`}
                          >
                            {({
                              push: addDocument,
                              remove: removeDocument,
                            }) => (
                              <UploadDocumentRow
                                key={index}
                                index={index}
                                member={member}
                                addDocument={addDocument}
                                removeDocument={removeDocument}
                                errors={errors}
                                setFieldValue={setFieldValue}
                              />
                            )}
                          </FieldArray>
                        ),
                      )}
                    </MemberRows>
                  </FormContent>
                )}
              </FieldArray>
              <ButtonContainer>
                <FormActionButtons onBack={onBack} rightButtonName="Upload" />
              </ButtonContainer>
            </Form>
          );
        }}
      </Formik>
      <Notification
        type={messageType}
        message={uploadMessage}
        setMessage={setUploadMessage}
      />
    </>
  );
};
