import { validation } from "formUtils/validation";
import * as Yup from "yup";
import { DocumentTypeEnum } from "enums";
import { errorsMessage } from "formUtils/errorMessages";

export const documentTypeOptions = [
  { uuid: "prove_of_income", name: DocumentTypeEnum.PROOF_OF_INCOME },
  { uuid: "immigration_status", name: DocumentTypeEnum.IMMIGRATION_STATUS },
  { uuid: "other", name: DocumentTypeEnum.OTHER },
];

export const documentTypeByKey = {
  PROOF_OF_INCOME: DocumentTypeEnum.PROOF_OF_INCOME,
  IMMIGRATION_STATUS: DocumentTypeEnum.IMMIGRATION_STATUS,
  OTHER: DocumentTypeEnum.OTHER,
};

export const documentKeyByType: Record<string, string> = Object.fromEntries(
  Object.entries(documentTypeByKey).map(([key, value]) => [value, key]),
);

const documentListTypeShape = {
  documentDescription: validation.stringNotRequired,
  documentUploadType: validation.stringRequired,
  file: Yup.mixed().required(errorsMessage.required),
};

export const documentMembersTypeShape = {
  name: validation.stringRequired,
  documentsList: Yup.array().of(Yup.object().shape(documentListTypeShape)),
};

export const validationSchema = Yup.object().shape({
  uploadDocumentsMembers: Yup.array().of(
    Yup.object().shape(documentMembersTypeShape),
  ),
});
