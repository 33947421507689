import React from 'react';

export const PageTwentyTwo: React.FC = () => {
  return (
    <div className='container-fluid mt-12 pt-12'>
      <div className='contentContainer flex flex-col'>
        <div className='mt-0'>
          <h1 className='text-sm font-bold text-center'>Attachment B</h1>
          <p className='text-2xs text-end -mt-4'>CB3 (HC-Medical) 10-22</p>
        </div>
        <h2 className='text-center font-bold text-2xl'>Agency Addresses </h2>
        <p className='text-2xs text-center'>(Effective Date: August 2022)</p>
        <div className='grid-text-container grid grid-cols-4 gap-8 mt-8'>
          <div className='col-1'>
            <article>
              <h3 className='font-bold text-2xs'>Aitkin County</h3>
              <ul className='text-2xs'>
                <li>204 First Street NW</li>
                <li>Aitkin, MN 56431-1291</li>
                <li>218-927-7200 / 800-328-3744</li>
                <li>Fax: 218-927-7210</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Anoka County</h3>
              <ul className='text-2xs'>
                <li>Economic Assistance Department</li>
                <li>1201 89th Ave NE, Suite 400</li>
                <li>Blaine, MN 55434</li>
                <li>763-422-7200</li>
                <li>Fax: 763-324-3620</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Becker County</h3>
              <ul className='text-2xs'>
                <li>712 Minnesota Avenue</li>
                <li>Detroit Lakes, MN 56501</li>
                <li>218-847-5628</li>
                <li>Fax: 218-847-6738</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Beltrami County</h3>
              <ul className='text-2xs'>
                <li>616 America Ave NW</li>
                <li>Bemidji, MN 56601</li>
                <li>218-333-8300</li>
                <li>Fax: 218-333-4150</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Benton County</h3>
              <ul className='text-2xs'>
                <li>531 Dewey Street</li>
                <li>Foley, MN 56329-0740</li>
                <li>320-968-5087 / 800-530-6254</li>
                <li>Fax: 320-968-5330</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Big Stone County</h3>
              <ul className='text-2xs'>
                <li>340 2nd Street NW, PO Box 338</li>
                <li>Ortonville, MN 56278-0338</li>
                <li>320-839-2555</li>
                <li>Fax: 320-839-3966</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Blue Earth County</h3>
              <ul className='text-2xs'>
                <li>410 S 5th Street</li>
                <li>Mankato, MN 56002-3526</li>
                <li>507-304-4335</li>
                <li>Fax: 507-304-4336</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Brown County</h3>
              <ul className='text-2xs'>
                <li>1117 Center Street, PO Box 788</li>
                <li>New Ulm, MN 56073-0788</li>
                <li>507-354-8246 / 800-450-8246</li>
                <li>Fax: 507-359-4146</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Carlton County</h3>
              <ul className='text-2xs'>
                <li>14 N. 11th Street, Suite 100</li>
                <li>Cloquet, MN 55720-0660</li>
                <li>218-879-4583 / 800-642-9082</li>
                <li>Fax: 218-878-2500</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Carver County</h3>
              <ul className='text-2xs'>
                <li>602 East Fourth Street</li>
                <li>Chaska, MN 55318-2102</li>
                <li>952-361-1600</li>
                <li>Fax: 952-361-1660</li>
              </ul>
            </article>
            <article className='mt-28 pt-8'>
              <h3 className='font-bold text-2xs'>Cass County</h3>
              <ul className='text-2xs'>
                <li>400 Michigan Avenue W</li>
                <li>Walker, MN 56484-0519</li>
                <li>218-547-1340</li>
                <li>Fax: 218-547-1448</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Chippewa County</h3>
              <ul className='text-2xs'>
                <li>719 N Seventh Street, Suite 200</li>
                <li>Montevideo, MN 56265-1397</li>
                <li>320-269-6401 / 877-450-6401</li>
                <li>Fax: 320-269-6405</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Meeker County</h3>
              <ul className='text-2xs'>
                <li>114 North Holcombe Ave, #180</li>
                <li>Litchfield, MN 55355-2273</li>
                <li>320-693-5300 / 877-915-5300</li>
                <li>Fax: 320-693-5344</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Mille Lacs County</h3>
              <ul className='text-2xs'>
                <li>525 Second Street SE</li>
                <li>Milaca, MN 56353</li>
                <li>320-983-8208 / 888-270-8208</li>
                <li>Fax: 320-983-8306</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Morrison County</h3>
              <ul className='text-2xs'>
                <li>213 SE First Avenue</li>
                <li>Little Falls, MN 56345-3196</li>
                <li>320-632-2951 / 800-269-1464</li>
                <li>Fax: 320-632-0225</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Mower County</h3>
              <ul className='text-2xs'>
                <li>201 1st Street NE, Suite 18</li>
                <li>Austin, MN 55912-3405</li>
                <li>507-437-9700</li>
                <li>Fax: 507-437-9721</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Murray County</h3>
              <ul className='text-2xs'>
                <li>SWHHS</li>
                <li>3001 Maple Road, Suite 100</li>
                <li>Slayton, MN 56172</li>
                <li>507-836-6144 / 800-657-3811</li>
                <li>Fax: 507-836-8841</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Nicollet County</h3>
              <ul className='text-2xs'>
                <li>622 South Front Street</li>
                <li>St. Peter, MN 56082-2106</li>
                <li>507-934-8559</li>
                <li>Fax: 507-934-8552</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Nobles County</h3>
              <ul className='text-2xs'>
                <li>318 9th Street, PO Box 189</li>
                <li>Worthington, MN 56187-0189</li>
                <li>507-295-5213</li>
                <li>Fax: 507-372-5094</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Norman County</h3>
              <ul className='text-2xs'>
                <li>15 Second Avenue East, Room 108</li>
                <li>Ada, MN 56510-1389</li>
                <li>218-784-5400</li>
                <li>Fax: 218-784-7142</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Olmsted County</h3>
              <ul className='text-2xs'>
                <li>2117 Campus Drive SE, Suite 200</li>
                <li>Rochester, MN 55904</li>
                <li>507-328-6500</li>
                <li>Fax: 507-328-7956</li>
              </ul>
            </article>
            <article className='mt-28 pt-8'>
              <h3 className='font-bold text-2xs'>Otter Tail County</h3>
              <ul className='text-2xs'>
                <li>535 Fir Avenue W</li>
                <li>Fergus Falls, MN 56537</li>
                <li>218-998-8230</li>
                <li>Fax: 218-998-8270</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Pennington County</h3>
              <ul className='text-2xs'>
                <li>318 N Knight Avenue</li>
                <li>Thief River Falls, MN 56701-0340</li>
                <li>218-681-2880</li>
                <li>Fax: 218-683-7013</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Pine County</h3>
              <ul className='text-2xs'>
                <li>635 Northridge Dr NW, Suite 220</li>
                <li>Pine City, MN 55063</li>
                <li>320-591-1570</li>
                <li>Fax: 320-591-1601</li>
              </ul>
            </article>
            <article className=''>
              <h3 className='font-bold text-2xs'>Or</h3>
              <ul className='text-2xs'>
                <li>1602 Highway 23 N</li>
                <li>Sandstone, MN 55072-5009</li>
                <li>320-216-4100</li>
                <li>Fax: 320-216-4101</li>
              </ul>
            </article>
          </div>
          <div className='col-1'>
            <article className=''>
              <h3 className='font-bold text-2xs'>Chisago County</h3>
              <ul className='text-2xs'>
                <li>313 North Main Street, Rm 239</li>
                <li>Center City, MN 55012-9665</li>
                <li>651-213-5640 / 888-234-1246</li>
                <li>Fax: 651-213-5685</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Clay County</h3>
              <ul className='text-2xs'>
                <li>715 North 11th Street, Suite 102</li>
                <li>Moorhead, MN 56560-2095</li>
                <li>218-299-5200 / 800-757-3880</li>
                <li>Fax: 218-299-7106</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Clearwater County</h3>
              <ul className='text-2xs'>
                <li>216 Park Avenue NW</li>
                <li>Bagley, MN 56621-9500</li>
                <li>218-694-6164 / 800-245-6064</li>
                <li>Fax: 218-694-3535</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Cook County</h3>
              <ul className='text-2xs'>
                <li>411 West Second Street</li>
                <li>Grand Marais, MN 55604-2307</li>
                <li>218-387-3620</li>
                <li>Fax: 218-387-3020</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Cottonwood County</h3>
              <ul className='text-2xs'>
                <li>DVHHS</li>
                <li>11 Fourth Street, PO Box 9</li>
                <li>Windom, MN 56101-0009</li>
                <li>507-831-1891</li>
                <li>Fax: 507-831-0126</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Crow Wing County</h3>
              <ul className='text-2xs'>
                <li>204 Laurel Street, PO Box 686</li>
                <li>Brainerd, MN 56401-0686</li>
                <li>218-824-1250 / 888-772-8212</li>
                <li>Fax: 218-824-1141</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Dakota County</h3>
              <ul className='text-2xs'>
                <li>1 Mendota Road West, #100</li>
                <li>West St. Paul, MN 55118-4765</li>
                <li>651-554-5611</li>
                <li>Fax: 651-554-5748</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Dept of Human Services</h3>
              <ul className='text-2xs'>
                <li>Health Care Consumer Support</li>
                <li>540 Cedar Street, PO Box 64252</li>
                <li>St. Paul, MN 55164-0252</li>
                <li>651-297-3862 / 800-657-3672</li>
                <li>Fax: 651-431-7750</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Dodge County MnPrairie</h3>
              <ul className='text-2xs'>
                <li>22 Sixth Street East, Dept. 401</li>
                <li>Mantorville, MN 55955</li>
                <li>507-923-2900 / 888-850-9419</li>
                <li>Fax: 507-635-6186</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Douglas Country</h3>
              <ul className='text-2xs'>
                <li>809 Elm Street, Suite 1186</li>
                <li>Alexandria, MN 56308</li>
                <li>320-762-2302</li>
                <li>Fax: 320-762-3833</li>
              </ul>
            </article>
            <article className='mt-28 pt-8'>
              <h3 className='font-bold text-2xs'>Faribault Country</h3>
              <ul className='text-2xs'>
                <li>FMCHS</li>
                <li>412 Nicollet Street North</li>
                <li>Blue Earth, MN 56013</li>
                <li>507-526-3265</li>
                <li>507-526-3265</li>
                <li>Fax: 507-526-2039</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Pipestone Country</h3>
              <ul className='text-2xs'>
                <li>SWHHS</li>
                <li>1091 North Hiawatha Avenue</li>
                <li>Pipestone, MN 56164</li>
                <li>507-825-6720 / 888-632-4325</li>
                <li>Fax: 507-825-6727</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Polk Country</h3>
              <ul className='text-2xs'>
                <li>612 N Broadway, Room 302</li>
                <li>Crookston, MN 56716</li>
                <li>218-281-3127 / 877-281-3127</li>
                <li>Fax: 218-281-3926</li>
              </ul>
            </article>
            <article className=''>
              <h3 className='font-bold text-2xs'>Or</h3>
              <ul className='text-2xs'>
                <li>1424 Central Avenue NE</li>
                <li>East Grand Forks, MN 56721</li>
                <li>218-773-2431 / 877-281-3127</li>
                <li>Fax: 218-773-3602</li>
              </ul>
            </article>
            <article className=''>
              <h3 className='font-bold text-2xs'>Or</h3>
              <ul className='text-2xs'>
                <li>250 SW Cleveland Avenue</li>
                <li>PO Box 100</li>
                <li>McIntosh, MN 56556</li>
                <li>218-435-1585 / 877-281-3127</li>
                <li>Fax: 218-435-1552</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Pope County</h3>
              <ul className='text-2xs'>
                <li>Western Prairie Human Services</li>
                <li>211 East MN Avenue</li>
                <li>Glenwood, MN 56334-1629</li>
                <li>320-634-7755 / 800-291-2827</li>
                <li>Fax: 320-634-0164</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Ramsey County</h3>
              <ul className='text-2xs'>
                <li>160 East Kellogg Boulevard</li>
                <li>St. Paul, MN 55101-1494</li>
                <li>651-266-4444</li>
                <li>Fax: 651-266-3942</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Red Lake County</h3>
              <ul className='text-2xs'>
                <li>125 Edward Avenue SW</li>
                <li>Red Lake Falls, MN 56750-0356</li>
                <li>218-253-4131 / 877-294-0846</li>
                <li>Fax: 218-253-2926</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>
                Red Lake Nation Oshkiimaajitahdah
              </h3>
              <ul className='text-2xs'>
                <li>15525 Mendota Ave, PO Box 416</li>
                <li>Redby, MN 56670</li>
                <li>218-679-3350 / 888-404-0686</li>
                <li>Fax: 218-679-4317</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Redwood County</h3>
              <ul className='text-2xs'>
                <li>SWHHS</li>
                <li>266 E Bridge Street</li>
                <li>Redwood Falls, MN 56283</li>
                <li>507-637-4050 / 888-234-1292</li>
                <li>Fax: 507-637-4055</li>
              </ul>
            </article>
            <article className='mt-28 pt-8'>
              <h3 className='font-bold text-2xs'>Renville County</h3>
              <ul className='text-2xs'>
                <li>105 S 5th Street, Suite 203H</li>
                <li>Olivia, MN 56277</li>
                <li>320-523-2202</li>
                <li>Fax: 320-523-3565</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Rice County</h3>
              <ul className='text-2xs'>
                <li>320 NW Third Street, #2</li>
                <li>Faribault, MN 55021-0718</li>
                <li>507-332-6115</li>
                <li>Fax: 507-332-6247</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Rock County</h3>
              <ul className='text-2xs'>
                <li>SWHHS</li>
                <li>2 Roundwind Road, PO Box 715</li>
                <li>Luverne, MN 56156-0715</li>
                <li>507-283-5070</li>
                <li>Fax: 507-283-5074</li>
              </ul>
            </article>
          </div>
          <div className='col-1'>
            <article className=''>
              <h3 className='font-bold text-2xs'>Fillmore County</h3>
              <ul className='text-2xs'>
                <li>902 Houston Street NW, #1</li>
                <li>Preston, MN 55965-1080</li>
                <li>507-765-2175</li>
                <li>Fax: 507-765-3895</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Freeborn County</h3>
              <ul className='text-2xs'>
                <li>203 W Clark Street</li>
                <li>Albert Lea, MN 56007-1246</li>
                <li>507-377-5400</li>
                <li>Fax: 507-377-5498</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Goodhue County</h3>
              <ul className='text-2xs'>
                <li>426 West Avenue</li>
                <li>Red Wing, MN 55066</li>
                <li>651-385-3200</li>
                <li>Fax: 651-267-4879</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Grant County</h3>
              <ul className='text-2xs'>
                <li>Western Prairie Human Services</li>
                <li>15 Central Avenue N, PO Box 1006</li>
                <li>Elbow Lake, MN 56531-1006</li>
                <li>218-685-8200 / 800-291-2827</li>
                <li>Fax: 218-685-4978</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Hennepin County</h3>
              <ul className='text-2xs'>
                <li>PO Box 107</li>
                <li>Minneapolis, MN 55440-0107</li>
                <li>612-596-1300</li>
                <li>Fax: 612-288-2981</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Houston County</h3>
              <ul className='text-2xs'>
                <li>304 S. Marshall Street, Rm 104</li>
                <li>Caledonia, MN 55921-0310</li>
                <li>507-725-5811</li>
                <li>Fax: 507-725-3990</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Hubbard County</h3>
              <ul className='text-2xs'>
                <li>205 Court Avenue</li>
                <li>Park Rapids, MN 56470</li>
                <li>218-732-1451 / 877-450-1451</li>
                <li>Fax: 218-732-3231</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Isanti County</h3>
              <ul className='text-2xs'>
                <li>1700 E Rum River Dr S, Suite A</li>
                <li>Cambridge, MN 55008-2547</li>
                <li>763-689-1711</li>
                <li>Fax: 763-689-9877</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Itasca County</h3>
              <ul className='text-2xs'>
                <li>1209 SE Second Avenue</li>
                <li>Grand Rapids, MN 55744-3983</li>
                <li>218-327-2941 / 800-422-0312</li>
                <li>Fax: 218-327-5548</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Jackson County</h3>
              <ul className='text-2xs'>
                <li>DVHHS</li>
                <li>407 5th Street, PO Box 67</li>
                <li>Jackson, MN 56143-0067</li>
                <li>507-847-4000</li>
                <li>Fax: 507-847-5616</li>
              </ul>
            </article>
            <article className='mt-28 pt-8'>
              <h3 className='font-bold text-2xs'>Kanabec County</h3>
              <ul className='text-2xs'>
                <li>905 Forest Avenue East, #150</li>
                <li>Mora, MN 55051-1316</li>
                <li>320-679-6350</li>
                <li>Fax: 320-679-6351</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Kandiyohi County</h3>
              <ul className='text-2xs'>
                <li>2200 23rd Street NE, Suite 1020</li>
                <li>Willmar, MN 56201-9423</li>
                <li>320-231-7800 / 877-464-7800</li>
                <li>Fax: 320-231-6285</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Roseau County</h3>
              <ul className='text-2xs'>
                <li>208 6th Street SW</li>
                <li>Roseau, MN 56751-1451</li>
                <li>218-463-2411 / 866-255-2932</li>
                <li>Fax: 218-463-3872</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>St. Louis County</h3>
              <ul className='text-2xs'>
                <li>320 West 2nd Street</li>
                <li>Duluth, MN 55802-1495</li>
                <li>218-726-2101 / 800-450-9777</li>
                <li>Fax: 218-733-2975</li>
              </ul>
            </article>
            <article className=''>
              <h3 className='font-bold text-2xs'>Or</h3>
              <ul className='text-2xs'>
                <li>201 S 3rd Avenue W, PO Box 1148</li>
                <li>Virginia, MN 55792-1148</li>
                <li>218-471-7137</li>
                <li>Fax: 218-471-7123</li>
              </ul>
            </article>
            <article className=''>
              <h3 className='font-bold text-2xs'>Or</h3>
              <ul className='text-2xs'>
                <li>320 Miners Drive E</li>
                <li>Ely, MN 55731-1402</li>
                <li>218-365-8220</li>
                <li>Fax: 218-365-8217</li>
              </ul>
            </article>
            <article className=''>
              <h3 className='font-bold text-2xs'>Or</h3>
              <ul className='text-2xs'>
                <li>1814 14th Avenue East</li>
                <li>Hibbing, MN 55746-1314</li>
                <li>218-262-6000</li>
                <li>Fax: 218-262-6049</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Scott County</h3>
              <ul className='text-2xs'>
                <li>Scott County Health and Human</li>
                <li>Services</li>
                <li>200 4th Avenue West</li>
                <li>Shakopee, MN 55379</li>
                <li>952-445-7751</li>
                <li>Fax: 952-496-8685</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Sherburne County</h3>
              <ul className='text-2xs'>
                <li>13880 Business Center Drive</li>
                <li>Elk River, MN 55330-4600</li>
                <li>763-765-4000 / 800-433-5239</li>
                <li>Fax: 763-765-4096</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Sibley County</h3>
              <ul className='text-2xs'>
                <li>111 8th Street, PO Box 237</li>
                <li>Gaylord, MN 55334-0237</li>
                <li>507-237-4000</li>
                <li>Fax: 507-237-4031</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Stearns County</h3>
              <ul className='text-2xs'>
                <li>PO Box 1107</li>
                <li>705 Courthouse Square</li>
                <li>St. Cloud, MN 56302-1107</li>
                <li>320-656-6000 / 800-450-3663</li>
                <li>Fax: 320-656-6447</li>
              </ul>
            </article>
            <article className='mt-28 pt-8'>
              <h3 className='font-bold text-2xs'>Steele County MnPrairie</h3>
              <ul className='text-2xs'>
                <li>PO Box 890</li>
                <li>630 Florence Ave</li>
                <li>Owatonna, MN 55060</li>
                <li>507-431-5600</li>
                <li>Fax: 507-451-5947</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Stevens County</h3>
              <ul className='text-2xs'>
                <li>400 Colorado Avenue, Suite 104</li>
                <li>Morris, MN 56267-1235</li>
                <li>320-208-6600 / 800-950-4429</li>
                <li>Fax: 320-589-3972</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Swift County</h3>
              <ul className='text-2xs'>
                <li>410 21st Street South, PO Box 208</li>
                <li>Benson, MN 56215-0208</li>
                <li>320-843-3160</li>
                <li>Fax: 320-843-4582</li>
              </ul>
            </article>
          </div>
          <div className='col-1'>
            <article className=''>
              <h3 className='font-bold text-2xs'>Kittson County</h3>
              <ul className='text-2xs'>
                <li>410 South Fifth Street, Suite 100</li>
                <li>Hallock, MN 56728</li>
                <li>218-843-2689 / 800-672-8026</li>
                <li>Fax: 218-843-2607</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Koochiching County</h3>
              <ul className='text-2xs'>
                <li>1000 Fifth Street</li>
                <li>Int’l Falls, MN 56649-2485</li>
                <li>218-283-7000 / 800-950-4630</li>
                <li>Fax: 218-283-7013</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Lac Qui Parle County</h3>
              <ul className='text-2xs'>
                <li>930 First Avenue</li>
                <li>Madison, MN 56256-0007</li>
                <li>320-598-7594</li>
                <li>Fax: 320-598-7597</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Lake County</h3>
              <ul className='text-2xs'>
                <li>616 Third Avenue</li>
                <li>Two Harbors, MN 55616-1560</li>
                <li>218-834-8400 / 800-450-8832</li>
                <li>Fax: 218-834-8412</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Lake of the Woods County</h3>
              <ul className='text-2xs'>
                <li>206 8th Avenue SE, Suite 200</li>
                <li>Baudette, MN 56623</li>
                <li>218-634-2642</li>
                <li>Fax: 218-634-4520</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Le Sueur County</h3>
              <ul className='text-2xs'>
                <li>88 South Park Avenue</li>
                <li>Le Center, MN 56057-1646</li>
                <li>507-357-8288</li>
                <li>Fax: 507-357-6122</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Lincoln County</h3>
              <ul className='text-2xs'>
                <li>SWHHS</li>
                <li>319 North Rebecca St., PO Box 44</li>
                <li>Ivanhoe, MN 56142</li>
                <li>507-694-1452 / 800-657-3781</li>
                <li>Fax: 507-694-1859</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Lyon County</h3>
              <ul className='text-2xs'>
                <li>SWHHS</li>
                <li>607 West Main Street, Suite 100</li>
                <li>Marshall, MN 56258</li>
                <li>507-537-6747 / 800-657-3760</li>
                <li>Fax: 507-537-6088</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>McLeod County</h3>
              <ul className='text-2xs'>
                <li>520 Chandler Avenue North</li>
                <li>Glencoe, MN 55336</li>
                <li>320-864-3144 / 800-247-1756</li>
                <li>Fax: 320-864-5265</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Mahnomen County</h3>
              <ul className='text-2xs'>
                <li>PO Box 460</li>
                <li>Mahnomen, MN 56557-0460</li>
                <li>218-935-2568</li>
                <li>Fax: 218-935-5459</li>
              </ul>
            </article>
            <article className='mt-28 pt-8'>
              <h3 className='font-bold text-2xs'>Marshall County</h3>
              <ul className='text-2xs'>
                <li>208 East Colvin Avenue, Suite 14</li>
                <li>Warren, MN 56762-1695</li>
                <li>218-745-5124 / 800-642-5444</li>
                <li>Fax: 218-745-5260</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Martin County</h3>
              <ul className='text-2xs'>
                <li>FMCHS</li>
                <li>115 West First Street</li>
                <li>Fairmont, MN 56031</li>
                <li>507-238-4757</li>
                <li>Fax: 507-238-1574</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Todd County</h3>
              <ul className='text-2xs'>
                <li>212 Second Avenue South</li>
                <li>Long Prairie, MN 56347-1640</li>
                <li>320-732-4500 / 888-838-4066</li>
                <li>Fax: 320-732-4540</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Traverse County</h3>
              <ul className='text-2xs'>
                <li>202 8th Street North, PO Box 46</li>
                <li>Wheaton, MN 56296</li>
                <li>320-422-7777 / 855-735-8916</li>
                <li>Fax: 320-563-4230</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Wabasha County</h3>
              <ul className='text-2xs'>
                <li>411 Hiawatha Drive E</li>
                <li>Wabasha, MN 55981-1573</li>
                <li>651-565-3351 / 888-315-8815</li>
                <li>Fax: 651-565-3084</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Wadena County</h3>
              <ul className='text-2xs'>
                <li>124 First Street SE</li>
                <li>Wadena, MN 56482-1553</li>
                <li>218-631-7605 / 888-662-2737</li>
                <li>Fax: 218-631-7616</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Waseca County MnPrairie</h3>
              <ul className='text-2xs'>
                <li>1000 West Elm Ave</li>
                <li>Waseca, MN 56093-2498</li>
                <li>507-837-6600</li>
                <li>Fax: 507-635-6186</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Washington County</h3>
              <ul className='text-2xs'>
                <li>14949 62nd Street North</li>
                <li>PO Box 30</li>
                <li>Stillwater, MN 55082-0030</li>
                <li>651-430-6455</li>
                <li>Fax: 651-430-6605</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Watonwan County</h3>
              <ul className='text-2xs'>
                <li>715 Second Avenue S, PO Box 31</li>
                <li>St. James, MN 56081-0031</li>
                <li>507-375-3294 / 888-299-5941</li>
                <li>Fax: 507-375-7359</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>White Earth Financial Services</h3>
              <ul className='text-2xs'>
                <li>PO Box 100</li>
                <li>Naytahwaush, MN 56566</li>
                <li>218-935-2359</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Wilkin County</h3>
              <ul className='text-2xs'>
                <li>227 6th Street North</li>
                <li>PO Box 369</li>
                <li>Breckenridge, MN 56520-0369</li>
                <li>218-643-7161</li>
                <li>Fax: 218-643-7175</li>
              </ul>
            </article>
            <article className='mt-28 pt-8'>
              <h3 className='font-bold text-2xs'>Winona County</h3>
              <ul className='text-2xs'>
                <li>202 West Third Street</li>
                <li>Winona, MN 55987-3146</li>
                <li>507-457-6500 / 844-317-8960</li>
                <li>Fax: 507-454-9381</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Wright County</h3>
              <ul className='text-2xs'>
                <li>3650 Braddock Ave NE, Suite 2100</li>
                <li>Buffalo, MN 55313-3675</li>
                <li>763-682-7400 / 800-362-3667</li>
                <li>Fax: 763-682-8920</li>
              </ul>
            </article>
            <article className='mt-2'>
              <h3 className='font-bold text-2xs'>Yellow Medicine County</h3>
              <ul className='text-2xs'>
                <li>415 9th Avenue, Suite 202</li>
                <li>Granite Falls, MN 56241</li>
                <li>320-564-2211</li>
                <li>Fax: 320-564-4165</li>
              </ul>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};
