import { gql } from "@apollo/client";
import { DocumentItem } from "models";

export interface CASE_BY_ID_SHORT_vars {
  caseAccessId: string;
}

export interface HouseholdMemberShort {
  caseAccessId: string;
  householdMemberId: string;
  name: string;
  documentsList: DocumentItem[];
}

export interface CASE_BY_ID_SHORT_data {
  caseById: {
    mnsureEnrollment: {
      householdMembers: HouseholdMemberShort[];
    };
  };
}

export const CASE_BY_ID_SHORT = gql`
  query caseById($caseAccessId: UUID!) {
    caseById(caseAccessId: $caseAccessId) {
      mnsureEnrollment {
        householdMembers {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
