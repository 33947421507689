import { Typography } from 'components';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { Button } from '@mui/material';

export const Container = styled('div')(()=>({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '1',
  textAlign: 'center',
  flexDirection: 'column',
  gap: '24px'

}))

export const WelcomeText = styled('div')(()=>({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}))

export const WelcomeTitle = styled(Typography)(()=>({
  color: colors.primary
}))

export const SignInButton = styled(Button)(()=>({
  '&.MuiButtonBase-root': {
    padding: '16px 48px',
    border: `1px solid ${colors.primary}`,
    color: colors.primary,
    '&:hover': {
      border: `1px solid ${colors.primary}`,
    }
  }
}))
SignInButton.defaultProps = {
  size: 'large',
}