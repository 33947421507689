import React from "react";
import { FieldArray, useFormikContext } from "formik";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { Alert } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { SeasonalJobForm } from "./SeasonalJobForm";
import {
  DetailsJobFormType,
  FORM_LIMIT_COUNT,
  seasonableJobPlaceholder,
} from "../../formUtils";
import { AddFormButton } from "../DetailMemberJobRow.css";

interface Props {
  index: number;
}
export const SeasonalJobsForm = ({ index }: Props) => {
  const { values } = useFormikContext<DetailsJobFormType>();

  const notificationAlert = (
    <Alert icon={<InfoOutlinedIcon fontSize="inherit" />} severity="info">
      {`Please contact a Navigator if you have more than ${FORM_LIMIT_COUNT} jobs`}
    </Alert>
  );

  return (
    <FieldArray name={`detailJobMembers.${index}.seasonalJobs`}>
      {({ push, remove }) => {
        const displayAddFormButton =
          values.detailJobMembers[index].seasonalJobs.length <=
          FORM_LIMIT_COUNT;
        return (
          <>
            {values.detailJobMembers[index].seasonalJobs?.map(
              (_, seasonalIndex) => (
                <SeasonalJobForm
                  key={seasonalIndex}
                  index={index}
                  seasonalIndex={seasonalIndex}
                  remove={() => remove(seasonalIndex)}
                  showDeleteButton={
                    values.detailJobMembers[index].seasonalJobs.length > 1
                  }
                />
              ),
            )}
            {displayAddFormButton ? (
              <AddFormButton
                startIcon={<AddCircleOutlineRoundedIcon />}
                variant="outlined"
                onClick={() => push(seasonableJobPlaceholder)}
              >
                Add Seasonal Job
              </AddFormButton>
            ) : (
              notificationAlert
            )}
          </>
        );
      }}
    </FieldArray>
  );
};
