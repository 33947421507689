import React from "react";
import { CaseNumber } from "pages/PdfPages/components";
import RadioButtons from "pages/PdfPages/components/RadioButtons/RadioButtons";
import { HouseholdMembersFragment } from "graphql/fragments/householdMembersFragment";

interface Props {
  caseNumber: string;
  householdMembers: HouseholdMembersFragment[];
}

interface DetailMember
  extends Pick<HouseholdMembersFragment, "otherIncomeSource"> {
  id: string;
  name: string;
}

export const PageEight = ({ caseNumber, householdMembers }: Props) => {
  const hasAnyoneOtherIncome = householdMembers.some(
    (h) => h.otherIncomeSource,
  );

  const otherIncomeSourceMembers = householdMembers.map((i) => {
    return {
      id: i.id,
      name: `${i.firstName} ${i.lastName}`,
      otherIncomeSource: i.otherIncomeSource,
    };
  });

  const renderDetailMemberRow = (item: Partial<DetailMember> | undefined) => {
    return (
      <table key={item?.id ?? 1} className="table bg-white">
        <thead>
          <tr>
            <th
              className="border border-gray-700 text-left text-sm"
              colSpan={parseInt("12")}
            >
              Other income
            </th>
          </tr>
        </thead>
        <tbody className="text-xs">
          <tr>
            <td className="border border-gray-700" colSpan={parseInt("12")}>
              NAME
              <br />
              <input type="text" readOnly value={item?.name ?? ""} />
            </td>
          </tr>
          <tr>
            <td className="border border-gray-700" colSpan={parseInt("6")}>
              TYPE OF INCOME
              <br />
              <input
                type="text"
                readOnly
                value={item?.otherIncomeSource?.typeOfIncome ?? ""}
              />
            </td>
            <td className="border border-gray-700" colSpan={parseInt("6")}>
              HOW OFTEN RECEIVED?
              <br />
              <input
                type="text"
                readOnly
                value={item?.otherIncomeSource?.howOftenReceived ?? ""}
              />
            </td>
          </tr>
          <tr>
            <td className="border border-gray-700" colSpan={parseInt("4")}>
              AMOUNT
              <br />
              <span>
                $
                <input
                  type="text"
                  readOnly
                  className="border-b border-black"
                  value={item?.otherIncomeSource?.amount ?? ""}
                />
              </span>
            </td>
            <td className="border border-gray-700" colSpan={parseInt("8")}>
              IF INTEREST INCOME, HOW MUCH OF THIS AMOUNT IS NOT TAXABLE?
              <br />
              <span>
                $
                <input
                  type="text"
                  readOnly
                  className="border-b border-black"
                  value={
                    item?.otherIncomeSource?.notTaxableInterestIncome ?? ""
                  }
                />
              </span>
            </td>
          </tr>
          <tr>
            <td className="border border-gray-700" colSpan={parseInt("8")}>
              Other taxable income that is expected within the next 12 months
              (Taxable income is income you would list on the Income section of
              the IRS form 1040.)
              <br />
              <span className="inline-flex space-x-2">
                <span className="inline-flex space-x-2">
                  <p>TYPE </p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={item?.otherIncomeSource?.income[0]?.type ?? ""}
                    />
                  </p>
                </span>
                <span className="inline-flex items-end">
                  <p>AMOUNT </p>
                  <p>$</p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={item?.otherIncomeSource?.income[0]?.amount ?? ""}
                    />
                  </p>
                </span>
                <span className="inline-flex items-end">
                  <p>HOW OFTEN RECEIVED? </p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={
                        item?.otherIncomeSource?.income[0]?.frequency ?? ""
                      }
                    />
                  </p>
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td className="border border-gray-700" colSpan={parseInt("8")}>
              Other taxable income this month
              <br />
              <span className="inline-flex space-x-2">
                <span className="inline-flex space-x-2">
                  <p>TYPE</p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={item?.otherIncomeSource?.income[1]?.type ?? ""}
                    />
                  </p>
                </span>
                <span className="inline-flex items-end">
                  <p>AMOUNT</p>
                  <p>$</p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={item?.otherIncomeSource?.income[1]?.amount ?? ""}
                    />
                  </p>
                </span>
                <span className="inline-flex items-end">
                  <p>HOW OFTEN RECEIVED?</p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={
                        item?.otherIncomeSource?.income[1]?.frequency ?? ""
                      }
                    />
                  </p>
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div className="container-fluid">
      <div className="contentContainer flex flex-col space-y-4 pt-12">
        <CaseNumber caseNumber={caseNumber} />
        <div className="array_container border border-gray-700 bg-[#66666631] px-12 py-2">
          <div>
            <h2 className="font-bold text-base">
              10. Did anyone get money this month or does anyone expect to get
              money next month from sources other than work?
            </h2>
            <RadioButtons
              items={[
                {
                  checked: hasAnyoneOtherIncome,
                  label:
                    "Yes – fill in the information. List the amount before taxes and deductions",
                },
                {
                  checked: !hasAnyoneOtherIncome,
                  label: "No",
                },
              ]}
            />
            <p className="ml-4 text-xs">
              NOTE: Do not list child support, nontaxable veteran’s payments,
              money from an Achieving a Better Life Experience (ABLE) account,
              or Supplemental Security Income (SSI).
            </p>
            <div className="ml-4 mt-4 text-xs">
              <p>Include:</p>
              <ul className="flex space-x-8 mb-4">
                <span>
                  <li>• Alimony received*</li>
                  <li>• Interest</li>
                  <li>• Net rental or royalty income</li>
                  <li>
                    • Retirement or pension payment, including taxable veteran’s
                    pensions
                  </li>
                </span>
                <span>
                  <li>• Social Security benefits**</li>
                  <li>• Unemployment</li>
                  <li>• Any other payments</li>
                </span>
              </ul>
              <p>
                * Do not list alimony received if your divorce decree or
                separation agreement is dated after 2018.
              </p>
              <p>
                ** Social Security benefits include retirement, disability and
                Railroad Retirement benefits. Supplemental Security Income (SSI)
                is not Social Security benefits. List the gross amount before
                any deductions. Include both taxable and nontaxable Social
                Security benefits.
              </p>
            </div>
          </div>
          {hasAnyoneOtherIncome
            ? otherIncomeSourceMembers?.map((item) => {
                if (!item.otherIncomeSource) return null;
                return renderDetailMemberRow(item);
              })
            : renderDetailMemberRow(undefined)}

          {renderDetailMemberRow(undefined)}
        </div>
      </div>
    </div>
  );
};
