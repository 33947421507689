import React from 'react';
import {MenuItem, SelectChangeEvent} from '@mui/material';
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {useTranslation} from "react-i18next";
import {StyledSelect} from './ManualSelect.css'

interface Option {
  uuid: string;
  name: string;
}

interface SelectFieldProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value: string;
  label: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  options: Option[];
  settingKeyName?: keyof Option;
}

export const ManualSelect = ({
                               label,
                               value,
                               onChange,
                               name,
                               options,
                               settingKeyName = 'name',
                               ...rest
                             }: SelectFieldProps) => {
  const {t} = useTranslation()
  return (
    <>
      <StyledSelect
        {...rest}
        label={t(label)}
        size="small"
        value={value || ''}
        select
        onChange={(event) => onChange(event as SelectChangeEvent<string>)}
      >
        {options?.map((option) => (
          <MenuItem key={option.uuid} value={option[settingKeyName as keyof Option]}>
            {option.name}
          </MenuItem>
        ))}
      </StyledSelect>
    </>
  );
};
