import React from "react";
import { parseStringToBoolean } from "utils/parse";
import { CaseNumber } from "pages/PdfPages/components";
import { compareTranslatedValue } from "utils/lang";
import { HouseholdMembersFragment } from "graphql/fragments/householdMembersFragment";

interface Props {
  caseNumber: string;
  householdMembers: HouseholdMembersFragment[];
}

export const PageEleven = ({ caseNumber, householdMembers }: Props) => {
  const healthCoverageMembers = householdMembers.map((h) => {
    return {
      id: h.id,
      name: `${h.firstName} ${h.lastName}`,
      ...h.healthCoverage,
    };
  });
  return (
    <div className="container-fluid pt-12">
      <div className="contentContainer flex flex-col space-y-4">
        <CaseNumber caseNumber={caseNumber} />
        <div className="array_container border border-gray-700 bg-[#66666631] p-2">
          <div className="">
            <h2 className="font-bold text-base">
              13. Is anyone enrolled in other health coverage?
            </h2>
          </div>
          {healthCoverageMembers?.map((item) => {
            return (
              <div>
                <div className="mt-2">
                  <p className="text-xs ml-2 space-x-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name={`coverage${item.name}`}
                        value="Yes"
                        defaultChecked={parseStringToBoolean(
                          item?.isEnrolledHealthCoverage,
                        )}
                      />
                      <span className="ml-1">
                        Yes – check the type of coverage and provide the
                        information
                      </span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name={`coverage${item.name}`}
                        value="No"
                        defaultChecked={
                          !parseStringToBoolean(item?.isEnrolledHealthCoverage)
                        }
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </p>
                  <div className="text-base font-bold border-t border-b border-gray-700 min-w-full -ml-2 -mr-2 bg-neutral-200 pl-4 mt-2 mb-2">
                    Policy
                  </div>
                  <div className="ml-2 flex space-x-8 pb-2 text-xs">
                    <span>
                      <label htmlFor="" className="flex space-x-1">
                        <input
                          type="radio"
                          name="provide"
                          id=""
                          checked={compareTranslatedValue(
                            item?.healthCoverage,
                            "employer",
                          )}
                          readOnly
                        />
                        <p>Employer</p>
                      </label>
                      <label htmlFor="" className="flex space-x-1">
                        <input
                          type="radio"
                          name="provide"
                          id=""
                          readOnly
                          checked={compareTranslatedValue(
                            item?.healthCoverage,
                            "tricare",
                          )}
                        />
                        <p>
                          TRICARE (Do not check if you have direct care or line
                          of duty)
                        </p>
                      </label>
                      <label htmlFor="" className="flex space-x-1">
                        <input
                          type="radio"
                          name="provide"
                          id=""
                          readOnly
                          checked={compareTranslatedValue(
                            item?.healthCoverage,
                            "private_other_insurance",
                          )}
                        />
                        <p>Private or other insurance</p>
                      </label>
                      <label htmlFor="" className="flex space-x-1">
                        <input
                          type="radio"
                          name="provide"
                          readOnly
                          checked={compareTranslatedValue(
                            item?.healthCoverage,
                            "dental",
                          )}
                        />
                        <p>Dental</p>
                      </label>
                      <label htmlFor="" className="flex space-x-1">
                        <input
                          type="radio"
                          name="provide"
                          readOnly
                          checked={compareTranslatedValue(
                            item?.healthCoverage,
                            "vision",
                          )}
                        />
                        <p>Vision</p>
                      </label>
                    </span>
                    <span>
                      <label htmlFor="" className="flex space-x-1">
                        <input
                          type="radio"
                          name={`provide_${item.name}`}
                          readOnly
                          checked={compareTranslatedValue(
                            item?.healthCoverage,
                            "medicare",
                          )}
                        />
                        <p>Medicare</p>
                      </label>
                      <label htmlFor="" className="flex space-x-1">
                        <input
                          type="radio"
                          name={`provide_${item.name}`}
                          readOnly
                          checked={compareTranslatedValue(
                            item?.healthCoverage,
                            "peace_corps",
                          )}
                        />
                        <p>Peace Corps</p>
                      </label>
                      <label htmlFor="" className="flex space-x-1">
                        <input
                          type="radio"
                          name={`provide_${item.name}`}
                          readOnly
                          checked={compareTranslatedValue(
                            item?.healthCoverage,
                            "cobra",
                          )}
                        />
                        <p>COBRA</p>
                      </label>
                      <label htmlFor="" className="flex space-x-1">
                        <input
                          type="radio"
                          name={`provide_${item.name}`}
                          readOnly
                          checked={compareTranslatedValue(
                            item?.healthCoverage,
                            "prescription_drug_coverage",
                          )}
                        />
                        <p>Prescription drug coverage</p>
                      </label>
                      <label htmlFor="" className="flex space-x-1">
                        <input
                          type="radio"
                          name={`provide_${item.name}`}
                          readOnly
                          checked={compareTranslatedValue(
                            item?.healthCoverage,
                            "long_term_care",
                          )}
                        />
                        <p>Long-term-care (LTC) insurance</p>
                      </label>
                      <label htmlFor="" className="flex space-x-1">
                        <input
                          type="radio"
                          name={`provide_${item.name}`}
                          readOnly
                          checked={compareTranslatedValue(
                            item?.healthCoverage,
                            "va_health_care_programs",
                          )}
                        />
                        <p>VA health care programs</p>
                      </label>
                    </span>
                  </div>
                </div>
                <table key={item.id} className="table">
                  <tbody className="text-xs">
                    <tr>
                      <td
                        className="border border-gray-700"
                        colSpan={parseInt("4")}
                      >
                        POLICY HOLDER'S NAME
                        <br />
                        <input
                          type="text"
                          readOnly
                          value={item?.policyHolderName}
                        />
                      </td>
                      <td
                        className="border border-gray-700"
                        colSpan={parseInt("4")}
                      >
                        POLICY HOLDER'S DATE OF BIRTH
                        <br />
                        <input
                          type="text"
                          readOnly
                          value={item?.policyDateOfBirth ?? undefined}
                        />
                      </td>
                      <td
                        className="border border-gray-700"
                        colSpan={parseInt("4")}
                      >
                        INSURANCE COMPANY NAME
                        <br />
                        <input
                          type="text"
                          readOnly
                          value={item?.insuranceCompanyName}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="border border-gray-700"
                        colSpan={parseInt("2")}
                      >
                        START DATE
                        <br />
                        <input
                          type="text"
                          readOnly
                          value={item?.startDate ?? undefined}
                        />
                      </td>
                      <td
                        className="border border-gray-700"
                        colSpan={parseInt("2")}
                      >
                        END DATE
                        <br />
                        <input
                          type="text"
                          readOnly
                          value={item?.endDate ?? undefined}
                        />
                      </td>
                      <td
                        className="border border-gray-700"
                        colSpan={parseInt("4")}
                      >
                        GROUP NUMBER
                        <br />
                        <input
                          type="text"
                          readOnly
                          value={item?.groupNumber ?? ""}
                        />
                      </td>
                      <td
                        className="border border-gray-700"
                        colSpan={parseInt("4")}
                      >
                        NAME OF INSURANCE POLICY
                        <br />
                        <input
                          type="text"
                          readOnly
                          value={item?.insurancePolicyName ?? ""}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="border border-gray-700"
                        colSpan={parseInt("10")}
                      >
                        LIST EVERYONE THAT IS COVERED BY THIS POLICY
                        <br />
                        <input
                          type="text"
                          readOnly
                          value={item?.policyCoverageList ?? ""}
                        />
                      </td>
                      <td
                        className="border border-gray-700"
                        colSpan={parseInt("2")}
                      >
                        POLICY NUMBER
                        <br />
                        <input
                          type="text"
                          readOnly
                          value={item?.policyNumber ?? ""}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
        <div className="array_container border border-gray-700 bg-[#66666631] p-2 pt-5">
          <div className="">
            <h2 className="font-bold text-base">
              14. Is anyone offered, but not enrolled in, health coverage from a
              job?
            </h2>
            <p className="text-xs ml-8">
              Check yes even if the coverage is from someone else's job, such as
              a parent or spouse.
            </p>
            <p className="text-xs ml-8 space-x-2">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio"
                  name="radio"
                  checked={false}
                  defaultChecked={false}
                />
                <span className="ml-1">Yes – fill in the information</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio"
                  name="radio"
                  value="2"
                  defaultChecked={true}
                />
                <span className="ml-1">No</span>
              </label>
            </p>
            <table>
              <tbody className="text-xs">
                <tr>
                  <td className="" colSpan={parseInt("8")}>
                    EMPLOYEE NAME
                    <br />
                    <input type="text" />
                  </td>
                  <td colSpan={parseInt("4")}>
                    EMPLOYEE DATE OF BIRTH
                    <br />
                    <input type="text" />
                  </td>
                </tr>
                <tr>
                  <td className="" colSpan={parseInt("8")}>
                    EMPLOYER NAME: Write the employer name that appears on your
                    paycheck.
                    <br />
                    <input type="text" />
                  </td>
                  <td colSpan={parseInt("4")}>
                    EMPLOYER IDENTIFICATION NUMBER (EIN)
                    <br />
                    <input type="text" />
                  </td>
                </tr>
                <tr>
                  <td className="" colSpan={parseInt("4")}>
                    EMPLOYER STREET ADDRESS
                    <br />
                    <input type="text" />
                  </td>
                  <td colSpan={parseInt("2")}>
                    CITY
                    <br />
                    <input type="text" />
                  </td>
                  <td colSpan={parseInt("2")}>
                    STATE
                    <br />
                    <input type="text" />
                  </td>
                  <td colSpan={parseInt("2")}>
                    ZIP CODE
                    <br />
                    <input type="text" />
                  </td>
                  <td colSpan={parseInt("2")}>
                    EMPLOYER PHONE NUMBER
                    <br />
                    <input type="text" />
                  </td>
                </tr>
                <tr>
                  <td className="" colSpan={parseInt("8")}>
                    Whom can we contact about employee health coverage at this
                    job? (this information is not required but you can provide
                    it to make it easier for us to contact your employer)
                    <br />
                    <input type="text" />
                  </td>
                  <td colSpan={parseInt("4")}>
                    PHONE NUMBER <br />
                    (if different from above)
                    <br />
                    <input type="text" />
                  </td>
                </tr>
                <tr>
                  <td colSpan={parseInt("12")}>
                    Were you offered coverage through a job for the current plan
                    year, or will you be eligible for coverage in the next three
                    months? <br />
                    Note: Answer yes if you could have enrolled but did not,
                    even if you did not want coverage or thought it was too
                    expensive.
                    <br />
                    <p className="text-sm ml-8 space-x-8">
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="radio"
                          value="2"
                        />
                        <span className="ml-1">
                          Yes – fill in the information
                        </span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="radio"
                          value="2"
                        />
                        <span className="ml-1">No</span>
                      </label>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
