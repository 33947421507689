import styled from "styled-components";
import {Grid} from "@mui/material";

export const GridContainer = styled(Grid)(() => ({
  '&.MuiGrid-container': {
    marginTop: '0',
    display: 'flex',
    alignItems: 'flex-start'
  }
}))
GridContainer.defaultProps = {
  container: true,
  columnSpacing: 2,
  rowSpacing: 2,
}

export const GridRowItem = styled(Grid)(()=>({}))
GridRowItem.defaultProps = {
  item: true,
  xs: 12,
  md: 2
}