import styled from 'styled-components';
import { colors } from 'styles/colors';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${props => colors.border};
  border-bottom: 1px solid ${props => colors.border};
  border-right: 1px solid ${props => colors.border};

  border-radius: 4px;
  width: fit-content;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderBorderBefore = styled.div`
  border-top: 1px solid ${props => colors.border};
  width: 1em;
  border-top-left-radius: 5px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.25em;
  width: fit-content;
  height: 2em;
  margin: -1em 0.5em 0 0.5em;
  overflow: hidden;

  span {
    color: ${props => colors.black} !important;
  }
`;

export const HeaderBorderAfter = styled.div`
  border-top: 1px solid ${props => colors.border};
  width: 1em;
  flex-grow: 2;
  border-top-right-radius: 5px;
`;