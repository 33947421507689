import React from "react";
import notFoundImage from "assets/404.png";
import { Button } from "@mui/material";
import { ButtonContainer, Container, StyledImage } from "./NotFound.css";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

export const NotFound = () => {
  const navigate = useNavigate();

  const onBackHandler = () => {
    navigate(routes.form);
  };
  return (
    <Container>
      <StyledImage alt="not found page" src={notFoundImage} />

      <ButtonContainer>
        <Button onClick={onBackHandler} fullWidth variant="contained">
          back home
        </Button>
      </ButtonContainer>
    </Container>
  );
};
