import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components";

import { HeaderBar, ToolbarStyled } from "./Header.css";

export const Header = () => {
  const { t } = useTranslation();

  return (
    <HeaderBar>
      <ToolbarStyled>
        <Typography variant="smallTitleBold">{t("header_title")}</Typography>
      </ToolbarStyled>
    </HeaderBar>
  );
};
