import React from 'react';
import {
  FormikInputField,
  FormikRadioButtonField,
  FormRow,
} from "components";
import {yesNoOptions} from './formUtils';
import {GridRowItem, GridContainer} from './IncomeQuestionForm.css';

interface IncomeQuestionRowProps {
  index: number;
}

export const IncomeQuestionRow = ({index}: IncomeQuestionRowProps) => {
  return (
    <FormRow key={index}>
      <GridContainer>
        <GridRowItem>
          <FormikInputField
            disabled
            label={'name'}
            name={`incomeMembers.${index}.name`}
          />
        </GridRowItem>
        <GridRowItem>
          <FormikRadioButtonField
            options={yesNoOptions}
            name={`incomeMembers.${index}.hasIncome`}
          />
        </GridRowItem>
      </GridContainer>
    </FormRow>
  );
};
