import React from "react";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { MenuItem, TextFieldProps } from "@mui/material";
import { TextFieldSelect } from "./Select.css";

interface Option {
  uuid: string;
  name: string;
}

interface SelectFieldProps<T>
  extends Omit<TextFieldProps, "select" | "value" | "onChange"> {
  label: string;
  name: Extract<keyof T, string>;
  formik: FormikProps<T>;
  options: Option[];
  settingKeyName?: keyof Option;
}

export const Select = <T,>({
  label,
  name,
  formik,
  options,
  settingKeyName = "name",
  ...rest
}: SelectFieldProps<T>) => {
  const { t } = useTranslation();
  return (
    <TextFieldSelect
      {...rest}
      select
      defaultValue=""
      size="small"
      fullWidth
      label={t(label)}
      name={name}
      value={formik.values[name] || ""}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && (t(formik.errors[name]) as string)}
      InputProps={{
        style: { minHeight: "unset" },
      }}
      autoComplete="off"
    >
      {options?.map((option) => {
        const value = option[settingKeyName as keyof Option];
        return (
          <MenuItem key={option.uuid} value={value}>
            {t(option.name)}
          </MenuItem>
        );
      })}
    </TextFieldSelect>
  );
};
