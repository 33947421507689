import { gql } from "@apollo/client";

export interface AssisterFragment {
  phoneAssister: string | null;
  organizationName: string | null;
  idAssister: string | null;
  nameApplicant: string | null;
  nameAssister: string | null;
  applicationStartDate: string | null;
}

export const ASSISTER_FRAGMENT = gql`
  fragment AssisterFragment on AssisterType {
    phoneAssister
    organizationName
    idAssister
    nameApplicant
    nameAssister
    applicationStartDate
  }
`;
