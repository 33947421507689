import { gql } from "@apollo/client";

export interface AdjustmentsIncomeFragment {
  type: string;
  yearlyAmount: number | null;
  hasIncomeAdjustment: boolean;
}

export const ADJUSTMENTS_INCOME_FRAGMENT = gql`
  fragment AdjustmentsIncomeFragment on AdjustmentIncomeType {
    type
    yearlyAmount
    hasIncomeAdjustment
  }
`;
