import { gql } from "@apollo/client";

export interface IncomeTypeFragment {
  name: string;
  currentOrPartJob: string;
  hasSeasonalJob: boolean;
  isSelfEmployed: boolean;
  otherIncomeSource: string;
}

export const INCOME_TYPE_FRAGMENT = gql`
  fragment IncomeTypeFragment on IncomeTypeForHouseholdMemberType {
    name
    currentOrPartJob
    hasSeasonalJob
    isSelfEmployed
    otherIncomeSource
  }
`;
