import React from "react";
import { Grid } from "@mui/material";
import { FormikInputField, FormRow } from "components";
import { GridContainer } from "./PAIForm.css";
import { useTranslation } from "react-i18next";

interface PaiMemberRowProps {
  index: number;
}

export const PaiMemberRow = ({ index }: PaiMemberRowProps) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <FormRow key={index}>
      <GridContainer>
        <Grid item xs={12} md={3}>
          <FormikInputField
            disabled
            label="name"
            name={`paiMembers.${index}.name`}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormikInputField
            type="number"
            label={`${t("expected_income")} ${currentYear}`}
            name={`paiMembers.${index}.expectedIncome`}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormikInputField
            type="number"
            label={`${t("adjustments_to_income_for")} ${currentYear}`}
            name={`paiMembers.${index}.adjustmentsIncome`}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormikInputField
            type="number"
            label={`${t("projected_annual_income_for")} ${currentYear}`}
            name={`paiMembers.${index}.projectedAnnualIncome`}
          />
        </Grid>
      </GridContainer>
    </FormRow>
  );
};
