import { gql } from "@apollo/client";

export interface FollowingQuestionsFragment {
  isInJail: boolean;
  whoIsInJail: string | null;
  isDispositionOfCharges: boolean;
  whoIsDispositionOfCharges: string | null;
  isNeedPayingMedical: boolean;
  whoIsNeedPayingMedical: string | null;
  hasHealthConditions: boolean;
  whoHasHealthConditions: string | null;
  isBlindOrDisabled: boolean;
  whoIsBlindOrDisabled: string | null;
  hasDeterminedDisabledSsa: boolean;
  whoHasDeterminedDisabledSsa: string | null;
  isReceivingSsi: boolean;
  whoIsReceivingSsi: string | null;
  isNeedHomeCareHelp: boolean;
  whoIsNeedHomeCareHelp: string | null;
  hasOngoingMedicalBills: boolean;
  whoHasOngoingMedicalBills: string | null;
  isGettingServiceCvt: boolean;
  whoIsGettingServiceCvt: string | null;
  isOutsideOfState: boolean;
  whoIsOutsideOfState: string | null;
  dateLeft: string | null;
  expectedReturnDate: string | null;
  hasReturnedFromMilitaryDutyTour: boolean;
  whoHasReturnedFromMilitaryDutyTour: string | null;
  lastActiveDate: string | null;
}

export const FOLLOWING_QUESTIONS_FRAGMENT = gql`
  fragment FollowingQuestionsFragment on FollowingQuestionsType {
    isInJail
    whoIsInJail
    isDispositionOfCharges
    whoIsDispositionOfCharges
    isNeedPayingMedical
    whoIsNeedPayingMedical
    hasHealthConditions
    whoHasHealthConditions
    isBlindOrDisabled
    whoIsBlindOrDisabled
    hasDeterminedDisabledSsa
    whoHasDeterminedDisabledSsa
    isReceivingSsi
    whoIsReceivingSsi
    isNeedHomeCareHelp
    whoIsNeedHomeCareHelp
    hasOngoingMedicalBills
    whoHasOngoingMedicalBills
    isGettingServiceCvt
    whoIsGettingServiceCvt
    isOutsideOfState
    whoIsOutsideOfState
    dateLeft
    expectedReturnDate
    hasReturnedFromMilitaryDutyTour
    whoHasReturnedFromMilitaryDutyTour
    lastActiveDate
  }
`;
