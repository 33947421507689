import { gql } from "@apollo/client";

export interface HealthCoverageFragment {
  isEnrolledHealthCoverage: boolean;
  healthCoverage: string;
  policyHolderName: string;
  policyDateOfBirth: string | null;
  insuranceCompanyName: string;
  startDate: string | null;
  endDate: string | null;
  groupNumber: number | null;
  insurancePolicyName: string | null;
  policyCoverageList: string | null;
  policyNumber: number | null;
}

export const HEALTH_COVERAGE_FRAGMENT = gql`
  fragment HealthCoverageFragment on HealthCoverageType {
    isEnrolledHealthCoverage
    healthCoverage
    policyHolderName
    policyDateOfBirth
    insuranceCompanyName
    startDate
    endDate
    groupNumber
    insurancePolicyName
    policyCoverageList
    policyNumber
  }
`;
