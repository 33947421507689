import { useState, useEffect } from "react";

const STORAGE_KEY = "data";

export const  useFormWithSessionStorage = <T>(initialData: T | null) => {
  const [data, setData] = useState<T | null>(() => {
    const storedData = sessionStorage.getItem(STORAGE_KEY);
    return storedData ? JSON.parse(storedData) : initialData;
  });

  useEffect(() => {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(data));
  }, [data]);

  return [data, setData] as const;
}
