import React from "react";

interface RadioButton {
  checked: boolean;
  label: string;
}

interface Props {
  items: RadioButton[];
}

export const RadioButtons = ({ items }: Props) => {
  return (
    <p className={`text-xs ml-4 space-x-2`}>
      {items.map(({ checked, label }) => {
        return (
          <label key={label} className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio"
              readOnly
              checked={!!checked}
            />
            <span className="ml-1 text-xs">{label}</span>
          </label>
        );
      })}
    </p>
  );
};

export default RadioButtons;
