import React from "react";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "@mui/material";
import { Header } from "components";
import { Dhs, PdfPages, UploadDocumentsPage, UploadedSuccessPage } from "pages";
import { theme } from "styles/theme";
import { MainContainer } from "App.css";
import { routes } from "routes";
import { DataFormsProvider } from "context/DataFormsContext";

import i18n from "./i18n";
import { runApolloClientConfig } from "graphql/config";

export const App = () => {
  const client = runApolloClientConfig(null);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <DataFormsProvider>
              <Header />
              <MainContainer>
                <Routes>
                  <Route path={routes.form} element={<Dhs />} />
                  <Route
                    path={routes.upload}
                    element={<UploadDocumentsPage />}
                  />
                  <Route
                    path={routes.print}
                    element={<PdfPages openPdfView={false} />}
                  />
                  <Route
                    path={routes.view}
                    element={<PdfPages openPdfView={true} />}
                  />
                  <Route
                    path={routes.uploadSuccess}
                    element={<UploadedSuccessPage />}
                  />
                </Routes>
              </MainContainer>
            </DataFormsProvider>
          </BrowserRouter>
        </I18nextProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};
