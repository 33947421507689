import {colors} from "styles/colors";
import {MenuItem, TextField} from "@mui/material";
import styled from "styled-components";

export const StyledTextField = styled(TextField)`
  & .Mui-disabled .MuiSelect-select {
    background-color: ${colors.disabled} !important;
    color: ${colors.disabledText} !important;
  }
`

export const MenuItemStyled = styled(MenuItem)(()=>({
  whiteSpace: 'normal'
}))