import React from "react";
import { PdfImage } from "components";
import { FileUpload } from "graphql/query/caseById";
import { FileItem, FilesContainer } from "pages/PdfPages/PdfPages.css";
import { formatFromSnakeCase } from "utils/strings";

interface Props {
  uploadedFiles: FileUpload[];
}

export const DocumentsPage = ({ uploadedFiles }: Props) => {
  return (
    <div className="container-fluid mt-16">
      <FilesContainer>
        {uploadedFiles?.map((f, index) => {
          const isPdf = f.fileExtension === ".pdf";
          return (
            <FileItem key={f.downloadUrl}>
              <p className="text-lg m-2 space-x-2">{f.householdMemberName}</p>
              <p className="text-sm m-2 space-x-2">
                {formatFromSnakeCase(f.documentUploadType)}
              </p>
              <p className="text-sm m-2 space-x-2">{f.documentDescription}</p>
              {isPdf ? (
                <PdfImage url={f.downloadUrl} />
              ) : (
                <img src={f.downloadUrl} alt={f.householdMemberName} />
              )}
            </FileItem>
          );
        })}
      </FilesContainer>
    </div>
  );
};
