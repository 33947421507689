import * as yup from 'yup';
import { errorsMessage } from './errorMessages';
import { isValidDate } from 'utils/dates';
import { birthdayMask } from 'constants/birthdayMask';


export const validation = {
  stringRequired: yup
    .string()
    .transform((value, originalValue) => (originalValue === null ? undefined : value))
    .required(errorsMessage.required),
  stringNotRequired: yup.string().required(errorsMessage.required).notRequired(),

  emailRequired: yup.string().email(errorsMessage.email).required(errorsMessage.required),
  emailNotRequired: yup.string().email(errorsMessage.email).nullable(),
  dateRequired: yup
    .string()
    .transform((value, originalValue) => (originalValue === null ? undefined : value))
    .required(errorsMessage.required)
    .test('valid-date', errorsMessage.invalid_date, (value) => {
      if (value === birthdayMask) return true;
      return isValidDate(value);
    }),
  dateNotRequired: yup
    .string()
    .transform((value, originalValue) => (originalValue === null ? undefined : value))
    .test('valid-date', errorsMessage.invalid_date, (value) => {
      if (!value || value === birthdayMask) return true;
      return isValidDate(value);
    }),
  numberRequired: yup
    .number()
    .required(errorsMessage.required),
  numberNotRequired: yup
    .number()
    .nullable(),
  caseNumber: yup
    .string()
    .max(8, errorsMessage.case_number)
    .nullable(),
  booleanNotRequired: yup.boolean().nullable(),
  booleanRequired: yup.boolean().required(errorsMessage.required)
};
