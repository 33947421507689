import React from "react";
import { CaseNumber } from "pages/PdfPages/components";

export const PageSixteen: React.FC<{ caseNumber: string }> = ({
  caseNumber,
}) => {
  return (
    <div className="container-fluid mt-12 pt-12">
      <div className="contentContainer flex flex-col space-y-4">
        <CaseNumber caseNumber={caseNumber} />
        <div className="mt-3">
          <h1 className="font-bold text-base">
            Appendix A: New Coverage Request
          </h1>
          <p className="text-xs pt-2">
            Complete this appendix for each new household member or new
            applicant requesting coverage who does not currently have Minnesota
            Health Care Programs coverage. If there is more than one person you
            are applying for, make a copy of this appendix.{" "}
          </p>
          <table>
            <tbody className="text-xs">
              <tr>
                <td colSpan={parseInt("8")}>
                  <p>HOUSEHOLD MEMBER'S NAME</p>
                  <br />
                  <input type="text" />
                </td>
                <td colSpan={parseInt("4")}>
                  <p>1. What is the person’s gender?</p>
                  <br />
                  <p className="space-x-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="Yes"
                      />
                      <span className="ml-1">Male</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="No"
                      />
                      <span className="ml-1">Female</span>
                    </label>
                  </p>
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("12")}>
                  <p>2. Does the person live at the same address with you?</p>
                  <br />
                  <p className="space-x-2 ml-4">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="Yes"
                      />
                      <span className="ml-1">Yes</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="No"
                      />
                      <span className="ml-1">No - List address</span>
                    </label>
                  </p>
                </td>
              </tr>
              <tr>
                <td className="text-xs" colSpan={parseInt("6")}>
                  STREET ADDRESS
                  <br />
                  <input type="text" readOnly value={""} />
                </td>
                <td className="text-xs" colSpan={parseInt("3")}>
                  CITY
                  <br />
                  <input type="text" readOnly value={""} />
                </td>
                <td className="text-xs" colSpan={parseInt("1")}>
                  STATE
                  <br />
                  <input type="text" readOnly value={""} />
                </td>
                <td className="text-xs" colSpan={parseInt("2")}>
                  ZIP CODE
                  <br />
                  <input type="text" readOnly value={""} />
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("12")}>
                  <p>3. Answer yes or no to the following four questions</p>
                  <ul className="pl-2">
                    <li>
                      <span>
                        a. Did the person move to Minnesota in the last three
                        months?
                      </span>
                      <span className="space-x-2 pl-2">
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="Yes"
                          />
                          <span className="ml-1 flex">
                            Yes - what date?{" "}
                            <div className="w-32 border-b border-black"></div>
                          </span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="No"
                          />
                          <span className="ml-1">No</span>
                        </label>
                      </span>
                    </li>
                    <li>
                      <span>
                        b. Does the person plan to make Minnesota their home?
                      </span>
                      <span className="space-x-2 pl-2">
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="Yes"
                          />
                          <span className="ml-1 flex">Yes</span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="No"
                          />
                          <span className="ml-1">No</span>
                        </label>
                      </span>
                    </li>
                    <li>
                      <span>
                        c. Did the person enter Minnesota with a job commitment
                        or to seek employment?
                      </span>
                      <span className="space-x-2 pl-2">
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="Yes"
                          />
                          <span className="ml-1 flex">Yes</span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="No"
                          />
                          <span className="ml-1">No</span>
                        </label>
                      </span>
                    </li>
                    <li>
                      <span>
                        d. Is the person visiting Minnesota to get medical care
                        or for personal reasons?
                      </span>
                      <span className="space-x-2 pl-2">
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="Yes"
                          />
                          <span className="ml-1 flex">Yes</span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="No"
                          />
                          <span className="ml-1">No</span>
                        </label>
                      </span>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("12")}>
                  <p>
                    4. Does the person want to apply for health care coverage?
                  </p>
                  <span className="flex flex-col space-y-1">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="Yes"
                      />
                      <span className="ml-1">
                        Yes – answer the following questions
                      </span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="No"
                      />
                      <span className="ml-1">
                        No – you do not need to complete the rest of Appendix A,
                        but be sure to include the person on the renewal form.
                      </span>
                    </label>
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("12")}>
                  <p>5. Does the person have a Social Security number (SSN)?</p>
                  <span className="space-y-1 flex flex-col">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="Yes"
                      />
                      <span className="ml-1 flex">
                        Yes – What is the member’s SSN?{" "}
                        <div className="w-32 border-b border-black"></div>
                      </span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="No"
                      />
                      <span className="ml-1">
                        No – Has the member applied for an SSN?
                      </span>
                    </label>
                    <span className="flex space-x-2 pl-8">
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="isWorking"
                          value="Yes"
                        />
                        <span className="ml-1">Yes</span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="isWorking"
                          value="No"
                        />
                        <span className="ml-1">No - Why not?</span>
                      </label>
                    </span>
                    <p className="pl-12">Choose a reason:</p>
                    <span className="flex flex-col pl-16">
                      <label className="items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="isWorking"
                          value="Yes"
                        />
                        <span className="ml-1">Not eligible for an SSN</span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="isWorking"
                          value="No"
                        />
                        <span className="ml-1">
                          Can be issued for non-work reason only
                        </span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="isWorking"
                          value="No"
                        />
                        <span className="ml-1">Religious objections</span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="isWorking"
                          value="No"
                        />
                        <span className="ml-1">Other reason</span>
                      </label>
                    </span>
                    <p>
                      *See the Notice of Privacy Practices and Notice of Rights
                      and Responsibilities (Attachment A) for information about
                      SSNs
                    </p>
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("12")}>
                  <span className="flex space-x-4">
                    <p>6. Is the person a U.S. citizen or U.S. national?</p>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="Yes"
                      />
                      <span className="ml-1">Yes - go to question 9</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="No"
                      />
                      <span className="ml-1">No – go to question 7</span>
                    </label>
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("12")}>
                  <p>7. What is the person’s current immigration status?</p>
                  <span className="pl-4">
                    Choose an immigration status from this list and place your
                    letter choice in the proper question. The immigration
                    statuses with an asterisk (*) are qualified statuses.
                  </span>
                  <ul className="flex space-x-32 pl-4">
                    <span>
                      <li>
                        A. American Indian born in Canada (Immigration and{" "}
                        <br />
                        Nationality Act [INA], section 289)*
                      </li>
                      <li>B. Amerasian noncitizen*</li>
                      <li>C. Asylee*</li>
                      <li>D. Conditional entrant*</li>
                      <li>E. Cuban or Haitian entrant*</li>
                      <li>
                        F. Withholding of removal or deportation being <br />
                        withheld under section 243(h) or 241(b)(3) of the INA*
                      </li>
                    </span>
                    <span>
                      <li>G. Refugee*</li>
                      <li>H. Special Iraqi or Afghani immigrant*</li>
                      <li>I. Victim of severe trafficking (LPR or T Visa)*</li>
                      <li>J. Battered noncitizen*</li>
                      <li>K. Lawful permanent resident (LPR)*</li>
                      <li>L. Paroled for at least one year*</li>
                      <li>M. Temporary nonimmigrant</li>
                      <li>N. Deferred action for childhood arrivals</li>
                      <li>
                        O. Citizen of Marshall Islands, Micronesia or Palau*
                      </li>
                    </span>
                  </ul>
                  <div className="pl-8 space-y-2">
                    <span className="flex">
                      <p>Code or status:</p>{" "}
                      <div className="w-72 border-b border-black"></div>
                    </span>
                    <span className="flex">
                      <p>a. Immigration document type:</p>{" "}
                      <div className="w-72 border-b border-black"></div>
                    </span>
                    <span className="flex">
                      <p>b. Alien I.D. number:</p>{" "}
                      <div className="w-72 border-b border-black"></div>
                    </span>
                    <span className="flex">
                      <p>c. Card number:</p>{" "}
                      <div className="w-72 border-b border-black"></div>
                    </span>
                    <span className="flex">
                      <p>d. Document expiration date (MM/DD/YYYY):</p>{" "}
                      <div className="w-72 border-b border-black"></div>
                    </span>
                    <span className="flex">
                      <p>e. Date of entry (MM/DD/YYYY):</p>{" "}
                      <div className="w-72 border-b border-black"></div>
                    </span>
                    <span className="flex">
                      <p>
                        f. Did the person enter the United States before August
                        22, 1996?
                      </p>{" "}
                      <span className="flex space-x-4 pl-2">
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="Yes"
                          />
                          <span className="ml-1">Yes</span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="No"
                          />
                          <span className="ml-1">No</span>
                        </label>
                      </span>
                    </span>
                    <span className="flex">
                      <p>
                        g. Has the person lived in the United States for five
                        years or more in a qualified status?
                      </p>{" "}
                      <span className="flex space-x-4 pl-2">
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="Yes"
                          />
                          <span className="ml-1">Yes</span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="No"
                          />
                          <span className="ml-1">No</span>
                        </label>
                      </span>
                    </span>
                    <span className="flex">
                      <p>h. Does the person have a sponsor?</p>{" "}
                      <span className="flex space-x-4 pl-2">
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="Yes"
                          />
                          <span className="ml-1">Yes – sponsor's name:</span>
                          <div className="w-72 border-b border-black"></div>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="No"
                          />
                          <span className="ml-1">No</span>
                        </label>
                      </span>
                    </span>
                    <span className="flex">
                      <p>
                        i. Is the person a veteran or active-duty member of the
                        military?
                      </p>{" "}
                      <span className="flex space-x-4 pl-2">
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="Yes"
                          />
                          <span className="ml-1">Yes</span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="No"
                          />
                          <span className="ml-1">No</span>
                        </label>
                      </span>
                    </span>
                    <span className="flex">
                      <p>
                        j. Is the person’s spouse or parent a veteran or
                        active-duty member of the military?
                      </p>{" "}
                      <span className="flex space-x-4 pl-2">
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="Yes"
                          />
                          <span className="ml-1">Yes</span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="No"
                          />
                          <span className="ml-1">No</span>
                        </label>
                      </span>
                    </span>
                    <span className="flex flex-col">
                      <p>
                        k. Does the person want help paying for a medical
                        emergency?
                      </p>{" "}
                      <span className="flex space-x-4 pl-2">
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="Yes"
                          />
                          <span className="ml-1">Yes – answer question l</span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio"
                            name="isWorking"
                            value="No"
                          />
                          <span className="ml-1">No – go to question 8</span>
                        </label>
                      </span>
                    </span>
                    <span className="flex flex-col">
                      <p>
                        l. What is the begin and end date for the medical
                        emergency?{" "}
                      </p>{" "}
                      <div className="flex flex-row space-x-4 pl-2 mt-4">
                        <div className="flex flex-row items-center">
                          <div className="w-40 border-b border-black" />
                          <div>(MM/DD/YYYY) to</div>
                        </div>
                        <div className="flex flex-row items-center">
                          <div className="w-40 border-b border-black" />
                          <div className="ml-1">(MM/DD/YYYY)</div>
                        </div>
                      </div>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("12")}>
                  <p>
                    8. Did the person ever have an immigration status different
                    from his or her current status (example: refugee or asylee)?
                  </p>
                  <span className="flex space-x-8 pl-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="Yes"
                      />
                      <span className="ml-1">Yes - answer a and b</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="No"
                      />
                      <span className="ml-1">No – go to question 9</span>
                    </label>
                  </span>
                  <span className="flex pl-2">
                    <p>
                      a. What is the household member’s previous immigration
                      status?
                    </p>{" "}
                    <div className="w-72 border-b border-black"></div>
                  </span>
                  <span className="flex pl-2">
                    <p>b. Original date of entry:</p>{" "}
                    <div className="w-32 border-b border-black"></div>
                    <p> (MM/DD/YYYY)</p>
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("12")}>
                  <p>
                    9. Does the person want help from Medical Assistance (MA) to
                    pay medical bills from the past three months?
                  </p>
                  <p>
                    (MA can start up to three months before the application date
                    if the household member has medical bills from that time and
                    meets the MA requirements.)
                  </p>
                  <span className="flex space-x-8 pl-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="Yes"
                      />
                      <span className="ml-1">Yes - answer a and b</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="No"
                      />
                      <span className="ml-1">No – go to question 10</span>
                    </label>
                  </span>
                  <span className="flex flex-col pl-2">
                    <p>
                      a. Which months before this month does the person want
                      help for? (Check all that apply.)
                    </p>{" "}
                    <span className="flex space-x-8 pl-2">
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          className="form-radio"
                          name="isWorking"
                          value="Yes"
                        />
                        <span className="ml-1">One month ago</span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          className="form-radio"
                          name="isWorking"
                          value="No"
                        />
                        <span className="ml-1">Two months ago</span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          className="form-radio"
                          name="isWorking"
                          value="No"
                        />
                        <span className="ml-1">Three months ago</span>
                      </label>
                    </span>
                  </span>
                  <span className="flex pl-2">
                    <p>
                      b. Is everything you told us on this renewal the same for
                      the selected month(s)? (For example, <br /> income,
                      pregnancy and family size.)
                    </p>{" "}
                    <span className="flex space-x-8 pl-2">
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="isWorking"
                          value="Yes"
                        />
                        <span className="ml-1">Yes</span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="isWorking"
                          value="No"
                        />
                        <span className="ml-1">No</span>
                      </label>
                    </span>
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("12")}>
                  <span className="flex space-x-8 pl-2">
                    <p>
                      10. If the person is under age 26, were they in foster
                      care in any state?
                    </p>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="Yes"
                      />
                      <span className="ml-1">Yes - answer a-c</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="No"
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </span>
                  <span className="flex flex-col pl-2">
                    <span className="flex pl-2">
                      <p>a. In what state was the person in foster care?</p>{" "}
                      <div className="w-32 border-b border-black"></div>
                    </span>
                    <span className="flex space-x-8 pl-2">
                      <p>
                        b. Did the person's foster care stop when they were age
                        18 or older?
                      </p>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="isWorking"
                          value="Yes"
                        />
                        <span className="ml-1">Yes</span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="isWorking"
                          value="No"
                        />
                        <span className="ml-1">No</span>
                      </label>
                    </span>
                  </span>
                  <span className="flex flex-col pl-4">
                    <p>
                      c. Was the person on Medical Assistance or another
                      Medicaid program at the time foster care ended?
                    </p>{" "}
                    <span className="flex space-x-8 pl-2">
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="isWorking"
                          value="Yes"
                        />
                        <span className="ml-1">Yes</span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="isWorking"
                          value="No"
                        />
                        <span className="ml-1">No</span>
                      </label>
                    </span>
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("12")}>
                  <span className="flex space-x-8 pl-2">
                    <p>
                      11. Is the person an American Indian or Alaskan Native?
                    </p>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="Yes"
                      />
                      <span className="ml-1">Yes - answer a and b</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="isWorking"
                        value="No"
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </span>
                  <span className="flex pl-2">
                    <p>b. List the Tribe name</p>{" "}
                    <div className="w-32 border-b border-black"></div>
                    <p>and Tribal ID number</p>
                    <div className="w-32 border-b border-black"></div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
