import { gql } from "@apollo/client";
import {
  ApplicantModel,
  FollowingQuestionsModel,
  HouseholdMemberServerModel,
} from "models";

interface SignModelServer {
  renewalTaxReturnConsent: boolean;
  renewalTaxReturnConsentYears: number;
}

export interface CREATE_ENROLLMENT_vars {
  enrollmentData: {
    applicant: ApplicantModel;
    householdMembers: HouseholdMemberServerModel[];
    followingQuestions: FollowingQuestionsModel;
    sign: SignModelServer;
  };
}

export interface CREATE_ENROLLMENT_data {
  createEnrollment: {
    success: boolean;
    message: string;
    caseAccessId: string;
  };
}

export const CREATE_ENROLLMENT = gql`
  mutation createEnrollment($enrollmentData: EnrollmentInput!) {
    createEnrollment(enrollmentData: $enrollmentData) {
      success
      message
      caseAccessId
    }
  }
`;
