import React from "react";
import { FieldArray, Formik } from "formik";
import { TaxFillingModel } from "models";
import { FormActionButtons } from "components";
import { validationSchema } from "./formUtils";
import { TaxFillingRow } from "./TaxFillingRow";
import { Form, FormContent, MemberRows } from "../Forms.css";

interface TaxFillingFormProps {
  onBack: () => void;
  defaultValues: TaxFillingModel[];
  onSubmit: (values: TaxFillingFormType) => void;
}

export interface TaxFillingFormType {
  taxFillingMembers: TaxFillingModel[];
}

export const TaxFillingForm = ({
  onBack,
  defaultValues,
  onSubmit,
}: TaxFillingFormProps) => {
  return (
    <Formik
      initialValues={{ taxFillingMembers: defaultValues }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <FieldArray name="taxFillingMembers">
            {() => {
              return (
                <FormContent>
                  <MemberRows>
                    {values?.taxFillingMembers?.map((member, index: number) => (
                      <TaxFillingRow key={member.id} index={index} />
                    ))}
                  </MemberRows>
                </FormContent>
              );
            }}
          </FieldArray>
          <FormActionButtons onBack={onBack} />
        </Form>
      )}
    </Formik>
  );
};
