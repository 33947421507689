import React from "react";
import { CaseNumber } from "pages/PdfPages/components";
import RadioButtons from "pages/PdfPages/components/RadioButtons/RadioButtons";
import { FollowingQuestionsFragment } from "graphql/fragments/followingQuestionsFragment";

interface Props {
  caseNumber: string;
  agreementDuration: number;
  followingQuestions: FollowingQuestionsFragment;
}

export const PageThirteen = ({
  caseNumber,
  followingQuestions,
  agreementDuration,
}: Props) => {
  const {
    isInJail,
    whoIsInJail,
    hasReturnedFromMilitaryDutyTour,
    whoHasReturnedFromMilitaryDutyTour,
    expectedReturnDate,
    hasHealthConditions,
    whoHasHealthConditions,
    whoHasOngoingMedicalBills,
    hasOngoingMedicalBills,
    hasDeterminedDisabledSsa,
    whoHasDeterminedDisabledSsa,
    whoIsBlindOrDisabled,
    isBlindOrDisabled,
    dateLeft,
    isGettingServiceCvt,
    whoIsGettingServiceCvt,
    whoIsOutsideOfState,
    isOutsideOfState,
    whoIsNeedPayingMedical,
    isNeedPayingMedical,
    whoIsNeedHomeCareHelp,
    isNeedHomeCareHelp,
    isDispositionOfCharges,
    whoIsReceivingSsi,
    isReceivingSsi,
    lastActiveDate,
  } = followingQuestions;

  return (
    <div className="container-fluid pt-16">
      <div className="contentContainer flex flex-col space-y-4 mt-12">
        <CaseNumber caseNumber={caseNumber} />
        <div className="array_container border border-gray-700 bg-[#66666631] p-2">
          <h2 className="font-bold text-xs mb-2">
            15. Answer the following questions for all household members
          </h2>
          <table className="table mb-4">
            <tbody className="text-xs">
              <tr>
                <td className="border border-gray-700" colSpan={parseInt("3")}>
                  Is anyone in jail or prison?
                </td>
                <td className="border border-gray-700" colSpan={parseInt("1")}>
                  <p className="text-sm space-y-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name={`isInJail`}
                        value="2"
                        defaultChecked={isInJail}
                      />
                      <span className="ml-1">Yes</span>
                    </label>
                    <br />
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="isInJail"
                        value="2"
                        defaultChecked={!isInJail}
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </p>
                </td>
                <td className="border border-gray-700" colSpan={parseInt("3")}>
                  If yes, who? <br />
                  <input type="text" readOnly value={whoIsInJail ?? ""} />
                </td>
                <td className="border border-gray-700" colSpan={parseInt("5")}>
                  If in jail, is the person awaiting disposition of charges?
                  <br />
                  <RadioButtons
                    items={[
                      {
                        checked: isInJail && isDispositionOfCharges,
                        label: "Yes",
                      },
                      {
                        checked: isInJail && !isDispositionOfCharges,
                        label: "No",
                      },
                    ]}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("8")}>
                  Does anyone need help paying for medical bills from the past
                  three months?
                </td>
                <td colSpan={parseInt("1")}>
                  <p className="text-sm space-y-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="isNeedPayingMedical"
                        value="2"
                        defaultChecked={isNeedPayingMedical}
                      />
                      <span className="ml-1">Yes</span>
                    </label>
                    <br />
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="isNeedPayingMedical"
                        value="2"
                        defaultChecked={!isNeedPayingMedical}
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </p>
                </td>
                <td colSpan={parseInt("3")}>
                  If yes, who? <br />
                  <input
                    type="text"
                    readOnly
                    value={whoIsNeedPayingMedical ?? ""}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("8")}>
                  Does anyone have a physical, mental or emotional health
                  condition that limits their activities (bathing, dressing, or
                  daily chores)?
                </td>
                <td colSpan={parseInt("1")}>
                  <p className="text-sm space-y-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="hasHealthConditions"
                        value="Yes"
                        defaultChecked={hasHealthConditions}
                      />
                      <span className="ml-1">Yes</span>
                    </label>
                    <br />
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="hasHealthConditions"
                        value="No"
                        defaultChecked={!hasHealthConditions}
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </p>
                </td>
                <td colSpan={parseInt("3")}>
                  If yes, who? <br />
                  <input
                    type="text"
                    readOnly
                    value={whoHasHealthConditions ?? ""}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("8")}>Is anyone blind or disabled?</td>
                <td colSpan={parseInt("1")}>
                  <p className="text-sm space-y-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="isBlindOrDisabled"
                        value="2"
                        defaultChecked={isBlindOrDisabled}
                      />
                      <span className="ml-1">Yes</span>
                    </label>
                    <br />
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="isBlindOrDisabled"
                        value="2"
                        defaultChecked={!isBlindOrDisabled}
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </p>
                </td>
                <td colSpan={parseInt("3")}>
                  If yes, who? <br />
                  <input
                    type="text"
                    readOnly
                    value={whoIsBlindOrDisabled ?? ""}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("8")}>
                  Has anyone been determined disabled by the Social Security
                  Administration (SSA) or the State Medical Review Team (SMRT)?
                </td>
                <td colSpan={parseInt("1")}>
                  <p className="text-sm space-y-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="hasDeterminedDisabledSsa"
                        value="Yes"
                        defaultChecked={hasDeterminedDisabledSsa}
                      />
                      <span className="ml-1">Yes</span>
                    </label>
                    <br />
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="hasDeterminedDisabledSsa"
                        value="No"
                        defaultChecked={!hasDeterminedDisabledSsa}
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </p>
                </td>
                <td colSpan={parseInt("3")}>
                  If yes, who? <br />
                  <input
                    type="text"
                    readOnly
                    value={whoHasDeterminedDisabledSsa ?? ""}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("8")}>
                  Is anyone receiving Supplemental Security Income (SSI)?
                </td>
                <td colSpan={parseInt("1")}>
                  <p className="text-sm space-y-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="isReceivingSsi"
                        value="Yes"
                        defaultChecked={isReceivingSsi}
                      />
                      <span className="ml-1">Yes</span>
                    </label>
                    <br />
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="isReceivingSsi"
                        value="No"
                        defaultChecked={isReceivingSsi}
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </p>
                </td>
                <td colSpan={parseInt("3")}>
                  If yes, who? <br />
                  <input type="text" readOnly value={whoIsReceivingSsi ?? ""} />
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("8")}>
                  Does anyone need help staying in their home or help paying for
                  care in a long term care facility, such as a skilled nursing
                  facility?
                </td>
                <td colSpan={parseInt("1")}>
                  <p className="text-sm space-y-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="isNeedHomeCareHelp"
                        value="Yes"
                        defaultChecked={isNeedHomeCareHelp}
                      />
                      <span className="ml-1">Yes</span>
                    </label>
                    <br />
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="isNeedHomeCareHelp"
                        value="No"
                        defaultChecked={!isNeedHomeCareHelp}
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </p>
                </td>
                <td colSpan={parseInt("3")}>
                  If yes, who? <br />
                  <input
                    type="text"
                    readOnly
                    value={whoIsNeedHomeCareHelp ?? ""}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("8")}>
                  Does anyone have ongoing medical bills?
                </td>
                <td colSpan={parseInt("1")}>
                  <p className="text-sm space-y-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="hasOngoingMedicalBills"
                        value="Yes"
                        defaultChecked={hasOngoingMedicalBills}
                      />
                      <span className="ml-1">Yes</span>
                    </label>
                    <br />
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="hasOngoingMedicalBills"
                        value="No"
                        defaultChecked={!hasOngoingMedicalBills}
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </p>
                </td>
                <td colSpan={parseInt("3")}>
                  If yes, who? <br />
                  <input
                    type="text"
                    readOnly
                    value={whoHasOngoingMedicalBills ?? ""}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("8")}>
                  Is anyone getting services from Center for Victims of Torture?{" "}
                </td>
                <td colSpan={parseInt("1")}>
                  <p className="text-sm space-y-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="isGettingServiceCvt"
                        value="Yes"
                        defaultChecked={isGettingServiceCvt}
                      />
                      <span className="ml-1">Yes</span>
                    </label>
                    <br />
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="isGettingServiceCvt"
                        value="No"
                        defaultChecked={!isGettingServiceCvt}
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </p>
                </td>
                <td colSpan={parseInt("3")}>
                  If yes, who? <br />
                  <input
                    type="text"
                    readOnly
                    value={whoIsGettingServiceCvt ?? ""}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("3")}>
                  Is anyone temporarily outside of Minnesota for more than 30
                  days?{" "}
                </td>
                <td colSpan={parseInt("1")}>
                  <p className="text-sm space-y-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="isOutsideOfState"
                        value="Yes"
                        defaultChecked={isOutsideOfState}
                      />
                      <span className="ml-1">Yes</span>
                    </label>
                    <br />
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="isOutsideOfState"
                        value="No"
                        defaultChecked={!isOutsideOfState}
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </p>
                </td>
                <td colSpan={parseInt("3")}>
                  If yes, who? <br />
                  <input
                    type="text"
                    readOnly
                    value={whoIsOutsideOfState ?? ""}
                  />
                </td>
                <td colSpan={parseInt("1")}>
                  DATE LEFT
                  <br />
                  <input type="text" readOnly value={dateLeft ?? ""} />
                </td>
                <td colSpan={parseInt("5")}>
                  EXPECTED RETURN DATE
                  <br />
                  <input
                    type="text"
                    readOnly
                    value={expectedReturnDate ?? ""}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={parseInt("3")}>
                  Has anyone returned from a tour of active military duty in the
                  last 24 months?
                </td>
                <td colSpan={parseInt("1")}>
                  <p className="text-sm space-y-2">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="hasReturnedFromMilitaryDutyTour"
                        value="Yes"
                        defaultChecked={hasReturnedFromMilitaryDutyTour}
                      />
                      <span className="ml-1">Yes</span>
                    </label>
                    <br />
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        readOnly
                        className="form-radio"
                        name="hasReturnedFromMilitaryDutyTour"
                        value="No"
                        defaultChecked={!hasReturnedFromMilitaryDutyTour}
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </p>
                </td>
                <td colSpan={parseInt("3")}>
                  If yes, who? <br />
                  <input
                    type="text"
                    readOnly
                    value={whoHasReturnedFromMilitaryDutyTour ?? ""}
                  />
                </td>
                <td colSpan={parseInt("5")}>
                  DATE LAST ACTIVE TOUR OF DUTY ENDED
                  <br />
                  <input type="text" readOnly value={lastActiveDate ?? ""} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h2 className="text-base font-bold">
            Renewing Coverage in the Future
          </h2>
          <p className="text-xs">
            Each year, MNsure and DHS match data to verify and renew eligibility
            for help paying for health coverage. We need consent to use
            information from tax returns to verify and renew your financial
            assistance for coverage. If you do not give consent to use this
            information, your financial assistance cannot be verified during the
            year and renewed. You can change your consent at any time. If you do
            not check a box,{" "}
            <span className="font-bold">
              you are agreeing to the use of your information for 5 years.{" "}
            </span>
          </p>
          <p className="my-3 text-xs">
            I agree to the use of tax return information to verify and renew my
            eligibility for help paying for health coverage for:
          </p>
          <p className="text-xs space-x-8">
            <label className="inline-flex items-center">
              <input
                type="radio"
                readOnly
                className="form-radio"
                name="agreementDuration"
                value="5-years"
                defaultChecked={agreementDuration === 5}
              />
              <span className="ml-1">5 years</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                readOnly
                className="form-radio"
                value="4-years"
                name="agreementDuration"
                defaultChecked={agreementDuration === 4}
              />
              <span className="ml-1">4 years</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                readOnly
                className="form-radio"
                name="agreementDuration"
                value="3-years"
                defaultChecked={agreementDuration === 3}
              />
              <span className="ml-1"> 3 years</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                readOnly
                className="form-radio"
                name="agreementDuration"
                value="2-years"
                defaultChecked={agreementDuration === 2}
              />
              <span className="ml-1">2 years</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                readOnly
                className="form-radio"
                name="agreementDuration"
                value="1-Year"
                defaultChecked={agreementDuration === 1}
              />
              <span className="ml-1">1 year</span>
            </label>
          </p>
          <label className="inline-flex items-center text-xs">
            <input
              type="radio"
              readOnly
              className="form-radio"
              name="agreementDuration"
              value="notUse"
              defaultChecked={agreementDuration === 0}
            />
            <span>
              Do not use information from tax returns to renew my eligibility
              for help paying for health coverage.
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};
