import { gql } from "@apollo/client";

export const CONFIRM_FILE_UPLOAD = gql`
  mutation confirmFileUpload(
    $fileUploadId: UUID!
    $fileUploadResult: FileUploadResultInput
  ) {
    confirmFileUpload(
      fileUploadId: $fileUploadId
      fileUploadResult: $fileUploadResult
    ) {
      id
      status
    }
  }
`;
