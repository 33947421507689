import {useTranslation} from "react-i18next";
import React from 'react';
import {Typography} from "components";
import {FormTitleSection, FormActionsContainer} from './FormActions.css';

interface FormActionsProps {
  title: string;
  form: React.ReactNode;
  subtitle?: string
}

export const FormActions = ({title, form, subtitle}: FormActionsProps) => {
  const {t} = useTranslation()
  return (
    <FormActionsContainer>
      <FormTitleSection>
        <Typography variant={'titleBold'}>{t(title)}</Typography>

        {subtitle && <Typography variant={'body'}>{t(subtitle)}</Typography>}
      </FormTitleSection>
      {form}
    </FormActionsContainer>
  );
};
