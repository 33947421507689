import styled from "styled-components";
import { Paper } from "@mui/material";
import { colors } from "styles/colors";
import { GridContainer } from "components/pages/DhsPage/Forms/Forms.css";

export const ApplicantInfo = styled(Paper)(() => ({
  "&.MuiPaper-root": {
    backgroundColor: colors.primaryBg,
    padding: "12px 12px 24px",
    margin: "16px 0",
    borderBottom: `2px solid ${colors.primary}`,
  },
}));

export const GridContainerFirstRow = styled(GridContainer)`
  display: flex;
  justify-content: space-between;
`;
