import styled from "styled-components";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 64px);
`;

export const ButtonContainer = styled("div")`
  padding: 20px;
  width: 100%;
`;

export const StyledImage = styled.img`
  max-height: calc(100% - 104px);
  max-width: 100%;
  width: auto;
  height: auto;
  margin: auto;
`;
