import { gql } from "@apollo/client";

export interface TaxFilingFragment {
  isMarriedFilingJointly: boolean;
  claimantOfDependent: string;
  taxFilingStatus: string | null;
  outsideHouseholdTaxDependent: boolean;
  isClaimedByNoncustodialParent: boolean;
}

export const TAX_FILING_FRAGMENT = gql`
  fragment TaxFilingFragment on TaxFilingType {
    isMarriedFilingJointly
    claimantOfDependent
    taxFilingStatus
    outsideHouseholdTaxDependent
    isClaimedByNoncustodialParent
  }
`;
