import styled from "styled-components";
import {Grid} from "@mui/material";

export const GridRowItem = styled(Grid)(()=>({}))
GridRowItem.defaultProps = {
  item: true,
  xs: 12,
  md: 3
}

export const GridRowBabyCount = styled(GridRowItem)(()=>({}))
GridRowBabyCount.defaultProps = {
  sm: 12,
}