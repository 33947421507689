export enum RelationshipEnum {
  SELF = "self",
  SPOUSE = "spouse",
  CHILD = "child",
  STEP_CHILD = "step_child",
  UNCLE = "uncle",
  AUNT = "aunt",
  GRANDPARENT = "grandparent",
  NOT_RELATED = "not_related",
}
