import React from "react";

export const CaseNumber = ({ caseNumber }: { caseNumber: any }) => {
  return (
    <div className="flex justify-end items-center">
      <p className="text-base">
        Case Number: {<span className="underline">{caseNumber ?? ""}</span>}
      </p>
    </div>
  );
};
