import React from 'react';
import {FieldArray, Formik} from "formik";
import {FormActionButtons} from "components";
import {IncomeTypeModel} from "models";
import {IncomeTypeMemberRow} from './incomeTypeMemberRow'
import {validationSchema} from './formUtils'
import {hasAnyoneDetailedTypeJob} from '../utils'
import {Form, FormContent, MemberRows} from '../Forms.css'

export interface IncomeTypeFormType {
  incomeTypeMembers: IncomeTypeModel[]
}

interface IncomeQuestionFormProps {
  onSubmit: (values: IncomeTypeFormType, nextStep: number) => void;
  onBack: () => void;
  defaultValues: IncomeTypeModel[];
}

export const IncomeTypeForm = ({onBack, defaultValues, onSubmit}: IncomeQuestionFormProps) => {
  return (
    <Formik
      initialValues={{incomeTypeMembers: defaultValues}}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={(values) => {
        const isAnyoneHasIncome = hasAnyoneDetailedTypeJob(values.incomeTypeMembers)
        onSubmit(values, isAnyoneHasIncome ? 1 : 4)
      }}
    >
      {({values, handleSubmit}) => (
        <Form onSubmit={handleSubmit}  autoComplete="off">
          <FieldArray name="incomeTypeMembers">
            {() => {
              return (
                <FormContent>
                  <MemberRows>
                    {values?.incomeTypeMembers?.map((_, index: number) => (
                      <IncomeTypeMemberRow index={index}/>
                    ))}
                  </MemberRows>
                </FormContent>
              );
            }}
          </FieldArray>
          <FormActionButtons onBack={onBack}/>
        </Form>
      )}
    </Formik>
  );
};

