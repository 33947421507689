import styled from "styled-components";
import { Grid } from "@mui/material";

export const GridContainer = styled(Grid)(() => ({}));
GridContainer.defaultProps = {
  container: true,
  columnSpacing: 2,
  rowSpacing: 2,
};

export const PaiFormContent = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
}));
