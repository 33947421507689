import logo from 'assets/logo.png';
import React from 'react';

export const PageTwenty: React.FC = () => {
  return (
    <div className='container-fluid'>
      <div className='contentContainer flex flex-col'>
        <div className='flex justify-end mb-4 mt-28'>
          <img src={logo} alt='logo' className='w-1/3 hidden' />
          <p className='text-2xs'>CB3 (HC-Medical) 10-22</p>
        </div>
        <div className='text-content-container'>
          <h2 className='font-bold text-lg'>Your Civil Rights</h2>
          <article>
            <p className='text-xs'>
              Discrimination is against the law. The Minnesota Department of
              Human Services (DHS) does not discriminate on the basis of any of
              the following: race, color, national origin, creed, religion,
              public assistance status, marital status, age, disability, sex
              (including sexual orientation and gender identity) or political
              beliefs.
            </p>
            <h2 className='font-bold text-base mt-6'>Free Services</h2>
            <h2 className='font-bold text-sm'>Auxiliary aids</h2>
            <p className='text-xs'>
              If you have a disability and need aids and services to have an
              equal opportunity to participate in our health care programs,
              MNsure and DHS will provide them timely and free of charge. These
              aids and services include qualified interpreters and information
              in accessible formats.
            </p>
            <h2 className='font-bold text-sm'>Language assistance</h2>
            <p className='text-xs mb-2'>
              If you have difficulty understanding English and need language
              help to access information and services, DHS will provide language
              assistance services timely and free of charge. These services
              include translated documents and interpreting spoken language.
            </p>
            <p className='text-xs'>
              <strong>To request these free services from DHS,</strong> call DHS
              Health Care Consumer Support at 651-297-3862 or 800-657-3672. Or
              use your preferred relay service.
            </p>
            <h2 className='font-bold text-lg mt-8'>Civil Rights Complaints</h2>
            <p className='text-xs mb-2'>
              You have the right to file a discrimination complaint if you
              believe you were treated in a discriminatory way by a human
              services agency.
            </p>
            <p className='text-xs'>
              You may contact any of the following three agencies directly to
              file a discrimination complaint.
            </p>
            <h2 className='text-sm'>
              U.S. Department of Health and Human Services' Office for Civil
              Rights (OCR)
            </h2>
            <p className='text-xs'>
              You have a right to file a complaint with the OCR, a federal
              agency, if you believe you have been discriminated against because
              of any of the following: race, color, national origin, age,
              disability, or sex (including sexual orientation and gender
              identity).
            </p>
            <ul className='text-xs'>
              <p className='my-2'>
                Contact the OCR directly to file a complaint:
              </p>
              <article className='pl-2'>
                <p>Centralized Case Management Operations</p>
                <p>U.S. Department of Health and Human Services</p>
                <p>200 Independence Avenue SW</p>
                <p>Room 509F, HHH Building</p>
                <p>Washington, DC 20201</p>
                <p>800-368-1019 (voice), 800-537-7697 (TDD)</p>
                <p>202-619-3818 (fax)</p>
                <p>OCRComplaint@hhs.gov (email)</p>
                <p><a href='https://ocrportal.hhs.gov/'>https://ocrportal.hhs.gov/</a></p>
              </article>
            </ul>
          </article>
        </div>
      </div>
    </div>
  );
};