import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components";
import { CurrentJobEnum } from "enums";

import { SeasonalJobsForm } from "./SeasonalJobsForm";
import { OtherIncomeSourceForm } from "./OtherIncomeSourceForm";
import { RenderMemberFormsByIncomeType } from "../formUtils";
import { CurrentJobsForm } from "./CurrentJobsForm";
import { SelfEmploymentForm } from "./SelfEmploymentForm";

interface DetailMemberJobRowProps {
  index: number;
  name: string;
  renderMemberFormsByIncomeType: RenderMemberFormsByIncomeType;
}

export const DetailMemberJobRow = ({
  index,
  name,
  renderMemberFormsByIncomeType,
}: DetailMemberJobRowProps) => {
  const { t } = useTranslation();

  const { currentJob, hasSeasonalJob, isSelfEmployed, otherIncomeSource } =
    renderMemberFormsByIncomeType;

  const hasOtherIncomeSource = t("no") !== otherIncomeSource;
  const hasCurrentPartJob = currentJob !== CurrentJobEnum.NO;

  return (
    <React.Fragment key={index}>
      <Typography variant="smallTitleBold">{name}</Typography>
      {hasCurrentPartJob && <CurrentJobsForm index={index} />}
      {hasSeasonalJob && <SeasonalJobsForm index={index} />}
      {isSelfEmployed && <SelfEmploymentForm index={index} />}
      {hasOtherIncomeSource && <OtherIncomeSourceForm index={index} />}
    </React.Fragment>
  );
};
