import {useEffect, useState} from 'react';
import {LanguageEnum} from "enums";

const STORAGE_KEY = 'lang'

export const usePersistentLang = () => {
  const defaultLanguage = sessionStorage.getItem(STORAGE_KEY) || LanguageEnum.EN;

  const [currentLang, setCurrentLang] = useState<string>(defaultLanguage);

  useEffect(() => {
    sessionStorage.setItem(STORAGE_KEY, String(currentLang));
  }, [currentLang]);


  return  [currentLang, setCurrentLang] as const;
};
