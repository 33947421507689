import React from 'react';
import {FormRowWrapper} from './FormRow.css'

interface FormRowProps {
  children: React.ReactNode
}
export const FormRow = ({children}:FormRowProps) => {
  return (
    <FormRowWrapper>
      {children}
    </FormRowWrapper>
  );
};

