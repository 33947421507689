import React from "react";
import {Checkbox as MuiCheckbox, FormControlLabel} from '@mui/material';
import {useTranslation} from "react-i18next";


interface ManualCheckboxProps {
  value: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  label: string;
  name: string;
}

export const ManualCheckbox = ({value, onChange, label, name}:ManualCheckboxProps) => {
  const { t } = useTranslation()
  return (
    <FormControlLabel
      label={t(label)}
      control={
        <MuiCheckbox
          checked={value}
          onChange={onChange}
          name={name}
        />
      }
    />
  );
};