import { useRef } from "react";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { TextField } from "@mui/material";
import { FormikValues } from "formik";
import { formatToISODate, isoDateToFormattedString } from "utils/dates";

interface FormikBirthdayPickerProps {
  formik: FormikValues;
  label: string;
  name: string;
}

export const FormikBirthdayPicker = ({
  formik,
  label,
  name,
}: FormikBirthdayPickerProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <InputMask
      mask="99/99/9999"
      name={name}
      value={isoDateToFormattedString(formik.values[name] ?? "")}
      onChange={(e) => {
        const parsedValue = formatToISODate(e.target.value);
        formik.setFieldValue(name, parsedValue);
        e.target.value = parsedValue;
      }}
      onFocus={(event) => {
        event.target.select();
        inputRef?.current?.focus();
      }}
    >
      {
        // library issue which required ts-ignoring
        // @ts-ignore
        () => {
          return (
            <TextField
              label={t(label)}
              size="small"
              fullWidth
              name={name}
              autoComplete="off"
              error={formik.touched[name] && Boolean(formik.errors[name])}
              helperText={formik.touched[name] && t(formik.errors[name])}
              {...(formik.values[name] && { shrink: true })}
            />
          );
        }
      }
    </InputMask>
  );
};
