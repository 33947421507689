import {TextField} from "@mui/material";
import styled from "styled-components";
import {colors} from "styles/colors";

export const StyledTextField = styled(TextField)`
  input[disabled] {
    background-color: ${colors.disabled} !important;
    color: ${colors.disabledText} !important;
  }
`
StyledTextField.defaultProps = {
  size: 'small',
  fullWidth: true
}