import React, { createContext, ReactNode } from "react";
import { useFormWithSessionStorage } from "hooks/usePersistentData";
import { GeneralForm } from "components/pages/DhsPage/Forms/types";

interface MyContextProviderProps {
  children: ReactNode;
}

export interface StoreContextProps {
  data: GeneralForm | null;
  updateData: (
    newData: (prevData: GeneralForm | null) => GeneralForm | null,
  ) => void;
  resetData: () => void;
}

export const DataFormsContext = createContext<StoreContextProps>({
  data: null,
  updateData: () => {},
  resetData: () => {},
});

export const DataFormsProvider: React.FC<MyContextProviderProps> = ({
  children,
}) => {
  const [data, setData] = useFormWithSessionStorage<GeneralForm | null>(null);

  const updateData = (
    newData: (prevData: GeneralForm | null) => GeneralForm | null,
  ) => {
    setData(newData);
  };

  const resetData = () => {
    setData(null);
  };

  return (
    <DataFormsContext.Provider value={{ data, updateData, resetData }}>
      {children}
    </DataFormsContext.Provider>
  );
};
