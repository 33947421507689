import { gql } from "@apollo/client";

export interface SeasonalJobFragment {
  employerName: string;
  employerEin: string;
  employerStreetAddress: string;
  employerCity: string;
  employerZipCode: string;
  employerState: string;
  seasonalIncome: number | null;
  expectedUnemployment: number | null;
}

export const SEASONAL_JOB_FRAGMENT = gql`
  fragment SeasonalJobFragment on SeasonalJobType {
    employerName
    employerEin
    employerStreetAddress
    employerCity
    employerZipCode
    employerState
    seasonalIncome
    expectedUnemployment
  }
`;
