import { colors } from "styles/colors";
import styled from "styled-components";

export const FormTitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: ${colors.primary};
  color: ${colors.white};
  padding: 16px;
  text-align: center;
  margin-bottom: 16px;
  border-radius: 4px;
`;
export const FormActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
