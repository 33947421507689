import React from "react";
import { Typography as TypographyMui, TypographyProps } from "@mui/material";
import { useTranslation } from "react-i18next";

interface TextProps extends Omit<TypographyProps, "variant"> {
  variant:
    | "title"
    | "titleBold"
    | "smallTitle"
    | "subtitle"
    | "subtitleBold"
    | "smallTitleBold"
    | "tiny"
    | "sectionTitle"
    | "body"
    | "bodyBold"
    | "label"
    | "error";
  children: React.ReactNode;
}

type ComponentType = "h1" | "span";

const variantStyles: Record<
  TextProps["variant"],
  {
    fontSize: string;
    lineHeight: string;
    component?: ComponentType;
    fontWeight?: number;
  }
> = {
  title: {
    fontSize: "26px",
    lineHeight: "24px",
    fontWeight: 500,
    component: "h1",
  },
  titleBold: { fontSize: "26px", lineHeight: "24px", fontWeight: 500 },
  smallTitleBold: { fontSize: "18px", lineHeight: "24px", fontWeight: 500 },
  body: { fontSize: "16px", lineHeight: "24px" },
  bodyBold: { fontSize: "16px", lineHeight: "24px", fontWeight: 600 },
  subtitle: { fontSize: "14px", lineHeight: "24px", fontWeight: 300 },
  subtitleBold: { fontSize: "14px", lineHeight: "24px", fontWeight: 500 },
  smallTitle: { fontSize: "18px", lineHeight: "24px" },
  tiny: { fontSize: "10px", lineHeight: "24px" },
  sectionTitle: { fontSize: "18px", lineHeight: "21px", fontWeight: 300 },
  label: { fontSize: "14px", lineHeight: "1.4375em", fontWeight: 400 },
  error: { fontSize: "12px", lineHeight: "1.66rem", fontWeight: 200 },
};

export const Typography = ({ variant, children, ...rest }: TextProps) => {
  const { t } = useTranslation();
  const typographyStyle = variantStyles[variant];
  const component: ComponentType = typographyStyle?.component || "span";

  return (
    <TypographyMui {...rest} component={component} {...typographyStyle}>
      {t(children)}
    </TypographyMui>
  );
};
