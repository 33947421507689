import React from "react";
import { FieldArray, Formik } from "formik";
import { HealthCoverageModel } from "models";
import { FormActionButtons } from "components";
import { parseStringToBoolean } from "utils/parse";

import { HealthCoverageMemberRow } from "./HealthCoverageMemberRow";
import { validationSchema } from "./formUtils";
import { Form, FormContent, MemberRows } from "../Forms.css";

export interface HealthCoverageFormType {
  healthCoverageMembers: HealthCoverageModel[];
}

interface HealthCoverageFormProps {
  onSubmit: (values: HealthCoverageFormType) => void;
  onBack: () => void;
  defaultValues: HealthCoverageModel[];
}

export const HealthCoverageForm = ({
  onBack,
  defaultValues,
  onSubmit,
}: HealthCoverageFormProps) => {
  return (
    <Formik
      initialValues={{ healthCoverageMembers: defaultValues }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <FieldArray name="healthCoverageMembers">
            {() => {
              return (
                <FormContent>
                  <MemberRows>
                    {values?.healthCoverageMembers?.map((member, index) => {
                      const isEnrolledHealthCoverage = parseStringToBoolean(
                        values.healthCoverageMembers[index]
                          .isEnrolledHealthCoverage,
                      );
                      return (
                        <HealthCoverageMemberRow
                          key={member.id}
                          index={index}
                          isEnrolledHealthCoverage={isEnrolledHealthCoverage}
                        />
                      );
                    })}
                  </MemberRows>
                </FormContent>
              );
            }}
          </FieldArray>
          <FormActionButtons onBack={onBack} />
        </Form>
      )}
    </Formik>
  );
};
