import styled from "styled-components";
import { Grid } from "@mui/material";

export const GridRowItem = styled(Grid)(() => ({}));
GridRowItem.defaultProps = {
  item: true,
  xs: 12,
  md: 2,
};

export const MembersCountSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 50%;
`;
