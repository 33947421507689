import * as yup from "yup";
import {validation} from "formUtils/validation";

export const renewingCoverageOptions = [
  {uuid: 'do_not_use_information_tax', name: 'do_not_use_information_tax'},
  {uuid: '1_year', name: '1_year'},
  {uuid: '2_years', name: '2_years'},
  {uuid: '3_years', name: '3_years'},
  {uuid: '4_years', name: '4_years'},
  {uuid: '5_years', name: '5_years'},
]

export const validationSchema = yup.object().shape({
  agreementDuration: validation.stringRequired,
});