import React, { FC } from 'react';
import attention from 'assets/attention.png';
import logo2 from 'assets/logo2.png';

export const PageTwo: FC = () => {
  return (
    <div className='container-fluid mb-56 pt-8'>
      <div className='contentContainer w-full h-auto'>
        <div className='flex flex-col items-center relative'>
          <div className='top-box w-8/12 h-8 bg-gray-300' />
          <div className='w-full border-2 border-gray-300'>
            <h1 className='text-sm font-bold text-center mt-4 mb-2'>
              651-297-3862 or 800-657-3672
            </h1>
            <div className='content-body flex justify-center items-center'>
              <img src={attention} alt='logo' />
            </div>
          </div>
          <div className='top-box w-8/12 h-12 bg-gray-300' />
          <div className='w-6/12 border-2 bg-white border-gray-500 absolute -bottom-24 p-4 flex space-x-4'>
            <img src={logo2} width={50} height={30} alt='logo ada advisor' />
            <div className='text-content'>
              <p className='text-xs'>
                For accessible formats of this information or assistance with
                additional equal access to human services, write to
                DHS.info@state.mn.us, call 651-297-3862 / 800-657-3672, or use
                your preferred relay service. <span className='text-2xs'>ADA1 (2-18)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
