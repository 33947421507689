import React from "react";
import {
  FormikInputField,
  FormikRadioButtonField,
  FormikSelectField,
  FormRow,
} from "components";
import { AdjustmentIncomeModel } from "models";
import { Grid } from "@mui/material";
import { parseStringToBoolean } from "utils/parse";
import { GridContainer, GridRowItem } from "./AdjustmentIncomeForm.css";
import { yearlyAmountOptions, yesNoOptions } from "./formUtils";

interface AdjustmentIncomeMemberRowProps {
  index: number;
  adjustmentIncomeMembers: AdjustmentIncomeModel[];
}

export const AdjustmentIncomeMemberRow = ({
  index,
  adjustmentIncomeMembers,
}: AdjustmentIncomeMemberRowProps) => {
  const hasIncomeAdjustment = parseStringToBoolean(
    adjustmentIncomeMembers[index].hasIncomeAdjustment,
  );

  return (
    <FormRow key={index}>
      <GridContainer>
        <GridRowItem>
          <FormikInputField
            disabled
            label="name"
            name={`adjustmentIncomeMembers.${index}.name`}
          />
        </GridRowItem>
        <Grid item xs={12} md={8}>
          <FormikRadioButtonField
            options={yesNoOptions}
            name={`adjustmentIncomeMembers.${index}.hasIncomeAdjustment`}
          />
        </Grid>
        {hasIncomeAdjustment && (
          <>
            <GridRowItem>
              <FormikSelectField
                label="adjustment_type"
                options={yearlyAmountOptions}
                name={`adjustmentIncomeMembers.${index}.type`}
              />
            </GridRowItem>
            <GridRowItem>
              <FormikInputField
                type="number"
                label="yearly_amount"
                name={`adjustmentIncomeMembers.${index}.yearlyAmount`}
              />
            </GridRowItem>
          </>
        )}
      </GridContainer>
    </FormRow>
  );
};
