import React, { ChangeEvent } from "react";
import { Button } from "@mui/material";
import {
  ErrorLabel,
  FormikInputField,
  FormikSelectField,
  FormRow,
} from "components";
import { DocumentItem } from "models";
import { HouseholdMemberShort } from "graphql/query/caseByIdShort";
import { getIn } from "formik";

import { documentTypeOptions } from "./formUtils";
import {
  ButtonContainer,
  GridContainer,
  GridRowEnd,
  GridRowItem,
  RemoveButton,
} from "./UploadDocuments.css";
import { FormikErrors } from "formik/dist/types";
import { UploadFormValues } from "./UploadDocuments";

interface Props {
  index: number;
  errors: FormikErrors<UploadFormValues>;
  member: HouseholdMemberShort;
  setFieldValue: (
    field: string,
    value: File | undefined,
    shouldValidate?: boolean,
  ) => void;
  addDocument: (doc: DocumentItem) => void;
  removeDocument: (docIndex: number) => void;
}

export const UploadDocumentRow = ({
  index,
  errors,
  member,
  addDocument,
  removeDocument,
  setFieldValue,
}: Props) => {
  const onAddDocument = () => {
    addDocument({
      documentDescription: "",
      documentUploadType: "",
      file: undefined,
    });
  };

  const onRemoveDocument = (documentIndex: number) => {
    removeDocument(documentIndex);
  };

  return (
    <React.Fragment key={index}>
      <FormRow>
        <input type="hidden" name="Content-Disposition" value="inline" />
        <GridContainer>
          <GridRowItem>
            <FormikInputField
              disabled
              fullWidth
              label="client_name"
              name={`uploadDocumentsMembers.${index}.name`}
            />
          </GridRowItem>
          {member.documentsList.map(
            (_: DocumentItem, documentIndex: number) => {
              const inputError = getIn(
                errors,
                `uploadDocumentsMembers[${index}].documentsList[${documentIndex}].file`,
              );
              return (
                <React.Fragment key={documentIndex}>
                  <GridRowItem>
                    <FormikSelectField
                      options={documentTypeOptions}
                      label="document_type"
                      setUntranslatedValue={true}
                      name={`uploadDocumentsMembers.${index}.documentsList.${documentIndex}.documentUploadType`}
                    />
                  </GridRowItem>
                  <GridRowItem>
                    <FormikInputField
                      label="document_description"
                      name={`uploadDocumentsMembers.${index}.documentsList.${documentIndex}.documentDescription`}
                    />
                  </GridRowItem>
                  <GridRowItem>
                    <>
                      <input
                        name={`uploadDocumentsMembers.${index}.documentsList.${documentIndex}.file`}
                        type="file"
                        accept={"image/*, .pdf"}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            setFieldValue(
                              `uploadDocumentsMembers.${index}.documentsList.${documentIndex}.file`,
                              file,
                            );
                          }
                        }}
                      />
                      {inputError && (
                        <div>
                          <ErrorLabel message={inputError} />
                        </div>
                      )}
                    </>
                  </GridRowItem>
                  <GridRowEnd>
                    <RemoveButton
                      variant="contained"
                      type="button"
                      onClick={() => onRemoveDocument(documentIndex)}
                    >
                      Remove
                    </RemoveButton>
                  </GridRowEnd>
                </React.Fragment>
              );
            },
          )}
        </GridContainer>
      </FormRow>
      <ButtonContainer>
        <Button
          fullWidth
          variant={"contained"}
          type="button"
          onClick={onAddDocument}
        >
          Add Document
        </Button>
      </ButtonContainer>
    </React.Fragment>
  );
};
