import * as Yup from "yup";
import {object} from "yup";
import {validation} from "formUtils/validation";
import {PregnancyModel} from "models";

export interface PregnancyFormType {
  pregnancy: PregnancyModel[]
}

export const pregnancyMemberShape = {
  id: validation.stringRequired,
  name: validation.stringRequired,
  isPregnant: validation.booleanNotRequired,
  expectedBabiesCount: Yup.string().when('isPregnant', {
    is: true,
    then: () => validation.numberRequired,
    otherwise: () => validation.numberNotRequired,
  }),
  birthday: Yup.string().when('isPregnant', {
    is: true,
    then: () => validation.dateRequired,
    otherwise: () => validation.dateNotRequired,
  }),
};

export const validationSchema = Yup.object().shape({
  pregnancy: Yup.array().of(object().shape(pregnancyMemberShape))
});

export const pregnantQuestionOptions = [
  {label: 'pregnancy_yes_fill_information', value: true},
  {label: 'no', value: false},
]
