import React from "react";
import { FieldArray, Formik } from "formik";
import { FormActionButtons } from "components";
import { IncomeTypeModel, DetailJobMemberModel } from "models";
import { DetailMemberJobRow } from "./DetailMemberJobRow/DetailMemberJobRow";
import {
  DetailsJobFormType,
  RenderMemberFormsByIncomeType,
  validationSchema,
} from "./formUtils";
import { Form, FormContent, MemberRows } from "../Forms.css";

interface IncomeQuestionFormProps {
  onSubmit: (values: DetailsJobFormType) => void;
  onBack: () => void;
  defaultValues: DetailJobMemberModel[];
  incomeTypeMembers: IncomeTypeModel[];
}

export const DetailsJobForm = ({
  onBack,
  defaultValues,
  onSubmit,
  incomeTypeMembers,
}: IncomeQuestionFormProps) => {
  const defineRenderMemberForm = (
    id: string,
  ): RenderMemberFormsByIncomeType => {
    const currentMember = incomeTypeMembers.find(
      (m) => m.id === id,
    ) as IncomeTypeModel;
    return {
      currentJob: currentMember?.currentOrPartJob,
      hasSeasonalJob: currentMember?.hasSeasonalJob,
      isSelfEmployed: currentMember?.isSelfEmployed,
      otherIncomeSource: currentMember?.otherIncomeSource,
    };
  };
  return (
    <Formik
      initialValues={{ detailJobMembers: defaultValues }}
      validateOnBlur={false}
      enableReinitialize={true}
      validationSchema={validationSchema(incomeTypeMembers)}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ values, handleSubmit, errors }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <FieldArray name="detailJobMembers">
            {() => {
              return (
                <FormContent>
                  <MemberRows>
                    {values?.detailJobMembers?.map((member, index: number) => (
                      <DetailMemberJobRow
                        key={member.id}
                        index={index}
                        name={member.name}
                        renderMemberFormsByIncomeType={defineRenderMemberForm(
                          member.id,
                        )}
                      />
                    ))}
                  </MemberRows>
                </FormContent>
              );
            }}
          </FieldArray>
          <FormActionButtons onBack={onBack} />
        </Form>
      )}
    </Formik>
  );
};
