import { gql } from "@apollo/client";
import {
  APPLICANT_FRAGMENT,
  ApplicantFragment,
} from "graphql/fragments/applicantFragment";
import {
  HOUSEHOLD_MEMBERS_FRAGMENT,
  HouseholdMembersFragment,
} from "graphql/fragments/householdMembersFragment";
import {
  FOLLOWING_QUESTIONS_FRAGMENT,
  FollowingQuestionsFragment,
} from "graphql/fragments/followingQuestionsFragment";
import { SIGN_FRAGMENT, SignFragment } from "graphql/fragments/signFragment";
import {
  ASSISTER_FRAGMENT,
  AssisterFragment,
} from "graphql/fragments/assisterFragment";

export interface CASE_BY_ID_vars {
  caseAccessId: string;
}

export interface FileUpload {
  documentUploadType: string;
  documentDescription: string;
  householdMemberName: string;
  downloadUrl: string;
  fileExtension: string;
}

export interface CASE_BY_ID_data {
  caseById: {
    id: string;
    fileUploads: FileUpload[];
    mnsureEnrollment: {
      id: string;
      sameAnnualIncome: boolean;
      applicant: ApplicantFragment;
      householdMembers: HouseholdMembersFragment[];
      followingQuestions: FollowingQuestionsFragment;
      sign: SignFragment;
      assister: AssisterFragment;
    };
  };
}

export const CASE_BY_ID = gql`
  query caseById($caseAccessId: UUID!) {
    caseById(caseAccessId: $caseAccessId) {
      id
      fileUploads {
        documentUploadType
        documentDescription
        householdMemberName
        downloadUrl
        fileExtension
      }
      mnsureEnrollment {
        id
        sameAnnualIncome
        applicant {
          ...ApplicantFragment
        }
        householdMembers {
          ...HouseholdMembersFragment
        }
        followingQuestions {
          ...FollowingQuestionsFragment
        }
        sign {
          ...SignFragment
        }
        assister {
          ...AssisterFragment
        }
      }
    }
  }
  ${APPLICANT_FRAGMENT}
  ${HOUSEHOLD_MEMBERS_FRAGMENT}
  ${FOLLOWING_QUESTIONS_FRAGMENT}
  ${SIGN_FRAGMENT}
  ${ASSISTER_FRAGMENT}
`;
