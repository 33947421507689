import React from 'react';
import {Grid} from "@mui/material";
import {
  FormikBirthdayPickerField,
  FormikInputField,
  FormikRadioButtonField,
  FormikSelectField,
  FormRow,
} from "components";
import {GridContainer} from './HealthCoverageForm.css';
import {healthCareOptions, yesNoOptions} from './formUtils'

interface HealthCoverageMemberRowProps {
  index: number;
  isEnrolledHealthCoverage: boolean;
}

export const HealthCoverageMemberRow = ({index, isEnrolledHealthCoverage}: HealthCoverageMemberRowProps) => {
  return (
    <FormRow key={index}>
      <GridContainer>
        <Grid item xs={12} md={12}>
          <FormikRadioButtonField
            options={yesNoOptions}
            name={`healthCoverageMembers.${index}.isEnrolledHealthCoverage`}
          />
        </Grid>
        {isEnrolledHealthCoverage &&
          <>
            <Grid item xs={12} md={6}>
              <FormikInputField
                disabled
                label='name'
                name={`healthCoverageMembers.${index}.name`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikSelectField
                label='health_coverage'
                options={healthCareOptions}
                name={`healthCoverageMembers.${index}.healthCoverage`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormikBirthdayPickerField
                label='policy_date_of_birth'
                name={`healthCoverageMembers.${index}.policyDateOfBirth`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormikInputField
                label='insurance_company_name'
                name={`healthCoverageMembers.${index}.insuranceCompanyName`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormikBirthdayPickerField
                label='start_date'
                name={`healthCoverageMembers.${index}.startDate`}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormikBirthdayPickerField
                label='end_date'
                name={`healthCoverageMembers.${index}.endDate`}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormikInputField
                label='policy_holder_name'
                name={`healthCoverageMembers.${index}.policyHolderName`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormikInputField
                type='number'
                label='group_number'
                name={`healthCoverageMembers.${index}.groupNumber`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormikInputField
                label='insurance_policy_name'
                name={`healthCoverageMembers.${index}.insurancePolicyName`}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <FormikInputField
                label='policy_coverage_list'
                name={`healthCoverageMembers.${index}.policyCoverageList`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormikInputField
                type='number'
                label='policy_number'
                name={`healthCoverageMembers.${index}.policyNumber`}
              />
            </Grid>
          </>
        }
      </GridContainer>
    </FormRow>
  );
};
