import logo from 'assets/logo.png'
import React from 'react';

export const PageNineteen: React.FC = () => {
  return (
    <div className='container-fluid'>
      <div className='contentContainer flex flex-col pt-32'>
        <h2 className='font-bold text-lg text-center'>Attachment A</h2>
        <div className='flex justify-between mb-4'>
          <img src={logo} alt='logo' className='w-1/3' />
          <p className='text-2xs'>DHS-4839K-ENG 11-22</p>
        </div>
        <div>
          <p>MINNESOTA DEPARTMENT OF HUMAN SERVICES AND MNSURE </p>
          <h2 className='text-lg font-bold'>
            Notice of Privacy Practices and Notice of Rights and
            Responsibilities{' '}
          </h2>
          <p className='text-2xs mb-2'>(Effective Date: November 2022)</p>
          <p className='text-xs'>
            This notice informs you of the privacy practices of the Minnesota
            Department of Human Services and MNsure, and your rights and
            responsibilities when applying for and enrolling in health insurance
            coverage through these agencies. When you apply for help paying for
            coverage, you may be found eligible for a public program like
            Medical Assistance and MinnesotaCare or a qualified health plan on
            the individual market for which you may receive tax credits and
            cost-sharing reductions. At the time that you apply, you may not
            know which program you qualify for, and in some cases, a single
            household may be covered by different programs. Therefore, please
            review the privacy practices and rights and responsibilities for
            each program for which you or your household members may qualify.
          </p>
          <p className='text-xs'>
            MNsure manages eligibility and enrollment in individual market
            qualified health plans (with or without advanced premium tax
            credits), with coordination through the health insurance carrier
            that you select. The Minnesota Department of Human Services and
            Minnesota county and tribal agencies manage eligibility and
            enrollment in Medical Assistance and MinnesotaCare.
          </p>
          <h2 className='text-center font-bold text-lg my-4'>
            Notice of Privacy Practices
          </h2>
          <div className='text_wrapper flex space-x-8'>
            <div className='left_text w-1/2'>
              <h2 className='font-bold text-md'>
                Privacy Practices for All Programs
              </h2>
              <p className='text-xs font-bold'>
                This part of the notice describes how private or confidential
                information about you and your family may be used and disclosed.
              </p>
              <h2 className='font-bold text-md mt-4'>
                Why do we ask for this information?
              </h2>
              <ul className='pl-4 text-xs'>
                <li>
                  • To tell you apart from other people with the same or similar
                  name
                </li>
                <li>• To decide what you are eligible for</li>
                <li>
                  • To help you get medical and mental health services and
                  decide whether you can pay for some services
                </li>
                <li>
                  • To decide whether you need protective services (for Medical
                  Assistance and MinnesotaCare only)
                </li>
                <li>
                  • To decide about out-of-home care and in-home care for you
                  (for Medical Assistance and MinnesotaCare only)
                </li>
                <li>
                  • To make reports, do research, do audits, and evaluate our
                  programs
                </li>
                <li>
                  • To investigate reports of people that may lie about the help
                  they need or to get assistance they may not be entitled to
                  receive
                </li>
                <li>
                  • To collect money from other agencies, like insurance
                  companies, if they should pay for your care
                </li>
                <li>
                  • To collect money from the state or federal government for
                  help we give you
                </li>
              </ul>
              <h2 className='font-bold text-md mt-4'>
                Why do we ask for your Social Security number?{' '}
              </h2>
              <p className='text-xs'>
                We need a Social Security number (SSN) for every person applying
                for health care coverage, if they have one. (See 42 CFR §
                435.910; 45 CFR § 155.310.)
              </p>
              <p className='mt-2 text-xs'>
                You do not have to give us the SSN for people in your home that
                are not applying for coverage, but providing an SSN may help
                speed up the application process.
              </p>
              <h2 className='font-bold text-md mt-28 pt-12'>
                With whom may we share information?
              </h2>
              <p className='text-xs'>
                We will share information about you only as needed and as
                allowed or required by law. For all programs, we may share your
                information with the following agencies or people that need the
                information to do their jobs:
              </p>
              <ul className='pl-4 text-xs'>
                <li>
                  • Employees or volunteers with other state, county, local,
                  federal, and partner nonprofit and private agencies
                </li>
                <li>
                  • Researchers, auditors, investigators, and others that do
                  quality-of-care reviews and studies or begin prosecutions or
                  legal actions related to managing the human services programs
                </li>
                <li>
                  • Court officials, county attorneys, attorneys general, other
                  law enforcement officials, fraud investigators, and fraud
                  prevention investigators
                </li>
                <li>
                  • Health care insurers, health care agencies, managed care
                  organizations and others that pay for your care
                </li>
                <li>
                  • Guardians, conservators or people with power of attorney who
                  are authorized representatives
                </li>
                <li>
                  • Certified application counselors, in-person assisters, and
                  navigators and anyone else the law says we must or can give
                  the information to
                </li>
              </ul>
              <p className='text-xs'>
                Additionally, for Medical Assistance and MinnesotaCare only, we
                may share your information with the following agencies or people
                that need the information to do their jobs:
              </p>
              <ul className='pl-4 text-xs'>
                <li>
                  • Human services offices, including child support enforcement
                  offices
                </li>
                <li>• Child protection investigators</li>
                <li>
                  • Governmental agencies in other states administering public
                  benefits programs
                </li>
                <li>
                  • Health care providers, including mental health agencies and
                  drug and alcohol treatment facilities
                </li>
                <li>
                  • Coroners and medical investigators if you die and they
                  investigate your death
                </li>
                <li>
                  • Credit bureaus, creditors or collection agencies if you do
                  not pay fees you owe to us for services, in limited situations
                </li>
              </ul>
              <h2 className='font-bold text-md mt-4'>
                What are our responsibilities?{' '}
              </h2>
              <ul className='pl-4 text-xs'>
                <li>
                  • We must protect the privacy of your personal, health care
                  and other private information according to the terms of this
                  notice.
                </li>
                <li>
                  • We may not use your information for reasons other than the
                  reasons listed on this form or share your information with
                  individuals and agencies other than those listed on this form
                  unless you tell us in writing that we can.
                </li>
                <li>
                  • We will not sell any data collected, created or maintained
                  as part of this application.
                </li>
                <li>
                  • We must follow the terms of this notice and give you a copy
                  of it, but we may change our privacy policy. Those changes
                  will apply to all information we have about you. The new
                  notice will be available on request, and we will put changes
                  to it on our website at https://edocs.dhs.state.mn.us/
                  lfserver/Public/DHS-4839K-ENG and www.mnsure.org.
                </li>
                <li>
                  • The law requires us to keep your private information private
                  and secure.
                </li>
                <li>
                  • As the law requires, if something happens that causes your
                  private information to no longer be private and secure, we
                  will let you know.
                </li>
              </ul>
              <h2 className='font-bold text-md mt-28 pt-12'>Genetic Information</h2>
              <p className='text-xs'>
                MNsure does not collect, maintain or use genetic information.
              </p>
              <h2 className='font-bold text-md mt-4'>Record Retention</h2>
              <p className='text-xs'>
                Information provided in an application for coverage through
                MNsure is subject to the False Claims Act and will be kept for
                up to 10 years. MNsure follows a records retention schedule and
                maintains data according to state and federal law. After the
                appropriate time period, MNsure shreds paper files and
                permanently removes electronic data to prevent recovery.
              </p>
              <h2 className='font-bold text-lg mt-4'>
                Privacy Practices for Medical Assistance and MinnesotaCare Only
              </h2>
              <p className='text-sm font-bold'>
                This part of the notice describes how medical information about
                you may be used and disclosed and how you can get access to this
                information.
              </p>
              <h2 className='font-bold text-lg mt-4'>
                We can use and share your health care information to
              </h2>
              <h3 className='font-bold'>
                • Help manage the health care treatment you receive
              </h3>
              <ol className='pl-4 text-xs'>
                <li>
                  ◦
                  <i>
                    We can use your health information and share it with
                    professionals who are treating you. Example: A doctor sends
                    us information about your diagnosis and treatment plan so we
                    can arrange additional services.
                  </i>
                </li>
                <li>
                  ◦
                  <i>
                    We can also share your information with guardians,
                    conservators or people with power of attorney who are
                    authorized representatives.
                  </i>
                </li>
              </ol>
              <h3 className='font-bold'>• Run our organization</h3>
              <ol className='pl-4 text-xs'>
                <li>
                  ◦We can use and share your information to run our organization
                  and contact you when necessary. This includes sharing your
                  information with employees or volunteers with other state,
                  county, local, federal, and partner nonprofit and private
                  agencies, including child support offices.
                </li>
                <li>
                  ◦We can share your information with these people and groups:
                </li>
                <article className='ml-2'>
                  <li>
                    • Auditors, investigators, and others that do quality-ofcare
                    reviews and studies
                  </li>
                  <li>
                    • Credit bureaus, creditors or collection agencies if you do
                    not pay fees you owe to us for services, in limited
                    situations
                  </li>
                  <li>
                    • Certified application counselors, in-person assisters, and
                    navigators and anyone else the law says we must or can give
                    the information to
                  </li>
                </article>
                <li>
                  ◦We are not allowed to use genetic information to decide
                  whether we will give you coverage and the price of that
                  coverage. This does not apply to long-term-care plans.
                  <i>
                    Example: We use health information about you to develop
                    better services for you.
                  </i>
                </li>
              </ol>
              <h2 className='font-bold text-lg mt-28 pt-12'>What are your choices?</h2>
              <p className='text-xs'>
                For certain health information, you can tell us your choices
                about what we share.
              </p>
              <p className='text-xs'>You have both the right and choice to tell us to:</p>
              <ul className='pl-4 mb-3 text-xs'>
                <li>
                  • Share health information with your family, close friends, or
                  others involved in payment for your care
                </li>
                <li>• Share information in a disaster relief situation</li>
              </ul>
              <p className='text-xs'>
                Tell us what you want us to do, and we will follow your
                instructions. If you are not able to tell us your preference,
                for example, if you are unconscious, we may go ahead and share
                your information if we believe it is in your best interest. We
                may also share your information when needed to lessen a serious
                and imminent threat to health or safety.
              </p>
              <h2 className='font-bold text-lg mt-4'>
                What privacy rights do children have?
              </h2>
              <p className='text-xs'>
                If you are under 18, when parental consent for medical treatment
                is not required, information will be provided to parents only
                when the medical provider believes that your health is at risk
                if the information is not shared. Parents may see other
                information about you and let others see this information,
                unless you have asked that this information not be shared with
                your parents. You must ask for this in writing and say what
                information you do not want to share and why. If the agency
                agrees that sharing the information is not in your best
                interest, the information will not be shared with your parents.
                If the agency does not agree, the information may be shared with
                your parents if they ask for it.
              </p>
            </div>

            {/* ================================================== */}
            {/* *****************Right container****************** */}
            {/* ================================================== */}

            <div className='right_text w-1/2'>
              <p className='text-xs'>
                We use SSNs to verify identity and prevent duplication of state
                and federal benefits. Additionally, SSNs are used to conduct
                computer data matches with federal and local agencies to verify
                income, resources and other information that may affect your
                eligibility or benefits. We will keep all the information you
                provide private and secure, as required by law. We will use
                personal information only to check if you're eligible for health
                coverage.
              </p>
              <p className='mt-2 text-xs'>
                If someone who is applying does not have an SSN, he or she may
                be required to apply for one to get Medical Assistance. There
                are exceptions to this for people who:
              </p>
              <ul className='pl-4 text-xs'>
                <li>• are not eligible for a Social Security number,</li>
                <li>
                  • can only get a Social Security number for a valid non-work
                  reason, or
                </li>
                <li>
                  • refuse to get a Social Security number due to a
                  wellestablished religious objection.
                </li>
              </ul>
              <p className='mt-2 text-xs'>
                If you want help getting an SSN, visit socialsecurity.gov, or
                call 800-772-1213. TTY users should call 800-325-0778.
              </p>
              <h2 className='font-bold text-md mt-4'>
                Why do we ask for your income information?{' '}
              </h2>
              <p className='text-xs'>
                We ask for income information and check state and federal
                sources to confirm your income and family size. We will use this
                information only for the purposes authorized by law, such as
                verifying eligibility or determining eligibility for the
                advanced premium tax credit and cost-sharing reductions, and the
                amount of the credit or reduction. We will not share this
                information with any other person or entity.
              </p>
              <h2 className='font-bold text-md mt-4'>
                Do you have to answer the questions we ask?
              </h2>
              <p className='text-xs'>
                You do not have to give us your personal information. Without
                the information, we may not be able to help you. If you give us
                wrong information on purpose, you can be investigated and
                charged with fraud.
                <strong className='text-2xs'>
                  This part of the notice describes how medical or other
                  information about you may be used and disclosed and how you
                  can get access to this information. Please review it
                  carefully.
                </strong>
              </p>
              <h2 className='font-bold text-md mt-28 pt-12'>
                What are your rights regarding the information we have about
                you?
              </h2>
              <ul className='pl-4 text-xs'>
                <li>
                  • You and people you have given permission to may see and copy
                  private information we have about you, such as health and
                  claims records. You may have to pay for the copies.
                </li>
                <li>
                  • You can choose someone to act for you with a medical power
                  of attorney or as a legal guardian. That person can exercise
                  your rights and make choices about your information.
                </li>
              </ul>
              <h2 className='font-bold text-md mt-4'>
                Ask us to correct health or other records about you
              </h2>
              <p className='text-xs'>
                You may question whether the information we have about you is
                correct. Send your concerns in writing. Tell us why the
                information is wrong or not complete. Send your own explanation
                of the information you do not agree with. We will attach your
                explanation anytime information is shared.
              </p>
              <h2 className='font-bold text-md mt-4'>
                Request confidential communications
              </h2>
              <ul className='pl-4 text-xs'>
                <li>
                  • You have the right to ask us in writing to share health
                  information with you in a certain way or in a certain place.
                </li>
                <li>
                  • We will consider all reasonable requests. We must say yes if
                  you tell us you would be in danger if we did not. For example,
                  you may ask us to send health information to your work address
                  instead of your home address. If we find that your request is
                  reasonable, we will grant it.
                </li>
              </ul>
              <h2 className='font-bold text-md mt-4'>
                Ask us to limit what we use or share
              </h2>
              <p className='text-xs'>
                You can ask us not to use or share certain health information
                for treatment, payment, or our operations. We are not required
                to agree to your request, and we may say no if it would affect
                your care
              </p>
              <h2 className='font-bold text-md mt-4'>
                Get a list of those with whom we've shared information
              </h2>
              <ul className='pl-4 text-xs'>
                <li>
                  • This list will not include disclosures for treatment,
                  payment, and health care operations. It will also not include
                  certain other disclosures, such as any you asked us to make.
                </li>
                <li>
                  • We will provide one list a year for free but will charge a
                  reasonable, cost-based fee if you ask for another one within
                  12 months.
                </li>
              </ul>
              <h2 className='font-bold text-md mt-4'>
                Get a copy of this privacy notice
              </h2>
              <ul className='pl-4 text-xs'>
                <li>
                  • You can ask for a paper copy of this notice at any time,
                  even if you have agreed to receive the notice electronically.
                  We will provide you with a paper copy promptly.
                </li>
                <li>
                  • If you do not understand the information, ask your worker to
                  explain it to you. You may ask the Minnesota Department of
                  Human Services or MNsure for another copy of this notice.
                </li>
              </ul>
              <h2 className='font-bold text-md mt-4'>
                • Pay for your health services
              </h2>
              <p className='ml-3 text-xs'>
                ◦ We can use and share your health information as we pay for
                your health services. Example: We share information about you
                with your dental plan to coordinate payment for your dental
                work.
              </p>
              <h2 className='font-bold text-md mt-28 pt-12'>
                • Help with public health and safety issues
              </h2>
              <p className='ml-3 text-xs'>
                ◦ We can share health information about you for purposes like
                these:
              </p>
              <article className='ml-4 text-xs'>
                <p>• Preventing disease</p>
                <p>• Helping with product recalls</p>
                <p>• Reporting adverse reactions to medications</p>
                <p>
                  • Reporting suspected abuse, neglect, or domestic violence
                </p>
                <p>
                  • Preventing or reducing a serious threat to anyone's health
                  or safety
                </p>
              </article>
              <h2 className='font-bold text-md mt-4'>• Do research</h2>
              <p className='ml-3 text-xs'>
                ◦ We can use or share your information for health research.
              </p>
              <h2 className='font-bold text-md mt-4'>• Comply with the law</h2>
              <p className='ml-3 text-xs'>
                ◦ We will share information about you if state or federal laws
                require it. This includes sharing information with the
                Department of Health and Human Services if it wants to see that
                we're complying with federal privacy law.
              </p>
              <h2 className='font-bold text-md mt-4'>
                • Respond to organ and tissue donation requests and work with a
                medical examiner or funeral director
              </h2>
              <p className='ml-3 text-xs'>
                ◦ We can share health information about you with organ
                procurement organizations.
              </p>
              <p className='text-xs'>
                ◦ We can share health information with a coroner, medical
                examiner, or funeral director when a person dies
              </p>
              <h2 className='font-bold text-md mt-4'>
                • Address workers' compensation, law enforcement, and other
                government requests
              </h2>
              <p className='ml-3 text-xs'>◦ For workers' compensation claims</p>
              <p className='text-xs'>
                ◦ For law enforcement purposes or with a law enforcement
                official
              </p>
              <p className='text-xs'>
                ◦ With health oversight agencies for activities authorized by
                law
              </p>
              <p className='text-xs'>
                ◦ With governmental agencies in other states administering
                public benefits programs
              </p>
              <p className='text-xs'>
                ◦ For special government functions, such as military, national
                security, and presidential protective services
              </p>
              <h2 className='font-bold text-md mt-4'>
                • Respond to lawsuits and legal actions
              </h2>
              <p className='text-xs'>
                ◦ We can share health information about you in response to a
                court order. We may share the information with court officials,
                county attorneys, attorneys general, other law enforcement
                officials, child support officials, child protection and fraud
                investigators, and fraud prevention investigators
              </p>
              <h2 className='font-bold text-md mt-44 pt-12'>
                What if you believe your privacy rights have been violated?
              </h2>
              <p className='text-xs'>
                You may complain if you believe your privacy rights have been
                violated. You cannot be denied service or treated badly because
                you have made a complaint. If you believe that your medical
                privacy was violated by your doctor or clinic, a health insurer,
                a health plan, or a pharmacy, you may send a written complaint
                to either the county agency, the organization or the federal
                civil rights office at:
              </p>
              <article className='pl-4 text-xs'>
                <p>U.S. Department of Health and Human Services</p>
                <p>Office for Civil Rights, Region V</p>
                <p>233 N. Michigan Avenue, Suite 240</p>
                <p>Chicago, IL 60601</p>
                <p>312-886-2359 (voice)</p>
                <p>800-368-1019 (toll free)</p>
                <p>800-537-7697 (TTY)</p>
                <p>312-886-1807 (fax)</p>
              </article>
              <p className='text-xs'>
                If you believe the Minnesota Department of Human Services
                violated your privacy rights, you may also contact:
              </p>
              <article className='pl-4 text-xs'>
                <p>Minnesota Department of Human Services</p>
                <p>Attn: Data Complaint</p>
                <p>PO Box 64998</p>
                <p>St. Paul, MN 55164-0998</p>
              </article>
              <p className='text-xs'>
                If you believe MNsure has violated your privacy rights, you may
                also contact:
              </p>
              <article className='pl-4 text-xs'>
                <p>MNsure Privacy Manager</p>
                <p>355 Randolph Ave., Suite 100</p>
                <p>St. Paul, MN 55102</p>
              </article>
              <h2 className='font-bold text-md mt-4'>
                Whom do you contact if you need more information about privacy
                practices?
              </h2>
              <p className='text-xs'>
                If you need more information about privacy practices, call the
                Health Care Consumer Support at 800-657-3739 or 651-431-2670.
              </p>
            </div>
          </div>
          <h2 className='font-bold text-lg mt-44 pt-12 text-center'>
            Notice of Rights and Responsibilities
          </h2>
          <div className='sec_text_content_wrapper flex space-x-8'>
            <div className='left_content w-1/2'>
              <h2 className='font-bold text-md mt-4'>
                Rights and Responsibilities for All Programs
                <span className='text-sm'>
                  <p>Changes</p>
                </span>
              </h2>
              <p className='text-xs'>
                If you have Medical Assistance (MA), you must report a change
                within 10 days of the change happening. Call your county or
                tribal agency to report the change. If you have MinnesotaCare,
                you must report a change within 30 days of the change happening.
                If everyone in your household receives MinnesotaCare, call
                MinnesotaCare Operations at 800-657-3672 or 651-297-3862 to
                report the change. If anyone in your household has MA, call your
                county or tribal agency to report the change.
              </p>
              <p className='mt-2 text-xs'>
                If you are enrolled in a qualified health plan (QHP), have
                advanced premium tax credits (APTC) applied to your coverage, or
                receive cost-sharing reductions (CSR), you must report a change
                within 30 days of the change happening. Call MNsure at
                855-366-7873 to report any changes.
              </p>
              <h2 className='font-bold text-md mt-4'>
                Life changes in your household when someone
              </h2>
              <ul className='pl-4 text-xs'>
                <li>• Becomes pregnant or has a baby</li>
                <li>• Moves in or out of your home</li>
                <li>• Dies, gets married or divorced</li>
                <li>• Starts or stops other health insurance or Medicare</li>
                <li>• Becomes disabled</li>
                <li>• Goes into or gets out of jail</li>
              </ul>
              <h2 className='font-bold text-md mt-4'>Tax Filing</h2>
              <p className='mb-2 text-xs'>
                If you purchased a QHP through MNsure and are receiving APTC or
                wish to claim the Premium Tax Credit (PTC), you must file taxes
                with the Internal Revenue Service (IRS). If you are married at
                the end of the year, you must file a joint income tax return
                with your spouse
              </p>
              <p className='text-xs'>
                When you file your federal income tax return, the IRS will
                compare the income on your tax return with the income on your
                application. If the income on your tax return is lower than the
                income on your application, you may be eligible to get an
                additional tax credit amount. On the other hand, if the income
                on your tax return is higher than the income on your
                application, you may owe additional federal income tax. At the
                end of the tax year, MNsure will issue a 1095A form for you to
                use in reporting health insurance coverage to the IRS. You can
                find more information about tax filing on the MNsure website:
                www.mnsure.org/individual-family/ cost/1095-A.jsp
              </p>
              <h2 className='font-bold text-md mt-12 pt-12'>
                You Have the Right to Ask for a Hearing
              </h2>
              <p className='mb-2 text-xs'>
                If you feel your health care eligibility or benefits are wrong
                or your application was not processed correctly, you may ask for
                an appeal hearing. By requesting an appeal hearing, you are
                requesting a fair review of your case. You can represent
                yourself or use an attorney, advocate, authorized
                representative, relative, friend or other person. You will find
                specific appeal instructions on all eligibility notices that you
                receive. Learn more about the appeals process and how to ask for
                a hearing at the MNsure appeals website at
                www.mnsure.org/help/appeals or at the DHS website at
                www.dhs.state.mn.us/appeals/faqs.
              </p>
              <p className='text-xs'>
                You can complete and submit an appeal request online at
                https://edocs.dhs.state.mn.us/lfserver/Public/DHS-0033-ENG.
              </p>
              <p className='mt-2 text-xs'>
                You can also print the form available at the address above and
                submit the completed form by fax to 651-431-7523 or by mail to
                this address:
              </p>
              <ol className='pl-4 text-xs'>
                <li>Minnesota Department of Human Services</li>
                <li>Appeals Division</li>
                <li>PO Box 64941</li>
                <li>St. Paul, MN 55164-0941</li>
              </ol>
              <h2 className='font-bold text-md mt-4'>Immigration</h2>
              <p className='text-xs'>
                Immigration information you give to us is private. We use it to
                see whether you can get coverage. We share it only when the law
                allows it or requires it, such as to verify identity. In most
                cases, applying will not affect your immigration status unless
                you are applying for payment of long-term-care services.
              </p>
              <h2 className='font-bold text-md mt-4'>Other Health Care</h2>
              <p className='mb-2 text-xs'>
                You and your household members enrolled in MA or MinnesotaCare
                must tell us about any other health insurance that you have or
                that is available to you, including employer-sponsored coverage,
                private health insurance, long-term-care insurance, and any
                limited health coverage, such as dental or accident coverage.
                You must tell us whether your employer offers insurance and
                whether you accepted it.
              </p>
              <p className='text-xs'>
                You must also tell us when you have become eligible for
                Medicare. MA pays for the Medicare premiums of some lowincome
                people.
              </p>
              <h2 className='font-bold text-md mt-4'>MA Medical Support</h2>
              <p className='mb-2 text-xs'>
                If you are applying for yourself and your children and you do
                not live with the other parent, the law says you may have to
                give information to child support staff if both you and your
                child are eligible for MA. This includes helping the state prove
                who the father of your children is and helping the state to get
                the other parent to help pay the children's medical expenses. If
                you do not help child support staff, your children will still
                get coverage, but your coverage will end, unless you are
                pregnant.
              </p>
              <p className='text-xs'>
                You may ask for a waiver from helping if it is against the best
                interests of your child or children, or against your best
                interests because of fear of physical or emotional harm. The
                agency will review your proof and tell you whether you still
                must give information to child support staff.
              </p>
              <h2 className='font-bold text-md mt-28 pt-12'>
                Assignment of Medical Payments
              </h2>
              <p className='mb-2 text-xs'>
                By accepting MA, you give your rights to all medical payments
                for yourself, and anyone else you apply for and for whom you can
                legally assign rights, to the State of Minnesota. These include
                medical payments from all other people or companies, including
                medical support payments from an absent parent. This assignment
                of medical payments begins as soon as health care coverage
                starts.
              </p>
              <p className='text-xs'>
                You also agree to help the state get paid back for medical
                expenses that should have been paid by others. You may not have
                to help the state if you have a good reason for not helping and
                the state approves the reason.
              </p>
              <h2 className='font-bold text-md mt-4'>
                MA Estate Claims and Liens
              </h2>
              <p className='text-xs'>
                In certain circumstances, federal and state law require the
                Minnesota Department of Human Services and local agencies to
                recover costs that the MA program paid for its members' health
                care services. This recovery process is done through Minnesota's
                MA estate recovery and lien program.
              </p>
            </div>

            {/* ================================================== */}
            {/* *****************Right container****************** */}
            {/* ================================================== */}

            <div className='right_content w-1/2'>
              <p className='mt-4 text-xs'>
                If you do not report changes, you may have to pay money back to
                the state or federal government for benefits that you received
                but were not eligible for. If you are not sure whether to report
                a change, call and explain what is happening. Examples of
                changes you need to report include the following:
              </p>
              <h2 className='font-bold text-md mt-4'>
                Income changes when you
              </h2>
              <article className='pl-4 text-xs'>
                <p>• Start a new job, change jobs or stop a job</p>
                <p>
                  • Start to get new income or stop getting income, like Social
                  Security or unemployment
                </p>
                <p>
                  • Have changes in the amount of income you get from your
                  business, from farming or other types of self-employment
                </p>
              </article>
              <h2 className='font-bold text-md mt-4'>
                Residence changes when you
              </h2>
              <article className='pl-4 mb-2 text-xs'>
                <p>• Move to a new address</p>
                <p>• Are temporarily out of Minnesota for more than 30 days</p>
              </article>
              <p text-xs>
                You do not have to give us your immigration information if you
                are a pregnant woman living in the United States without the
                knowledge or approval of the United States Citizenship and
                Immigration Services (USCIS). You also do not have to give us
                your immigration information if you are:
              </p>
              <article className='pl-4 text-xs'>
                <p>• Applying for emergency medical care only</p>
                <p>• Helping someone else apply</p>
                <p>• Not applying for yourself</p>
              </article>
              <h2 className='font-bold text-lg mt-4'>
                Rights and Responsibilities for Medical Assistance and
                MinnesotaCare Only
                <span className='text-md'>
                  <p>Reviews</p>
                </span>
              </h2>
              <p className='text-xs'>
                The state or federal agency's health care program auditors may
                look at your case. They will review the information you gave us
                and check to make sure we processed your case correctly. They
                will let you know if they need to ask you questions.
              </p>
              <h2 className='font-bold text-md mt-12 pt-12'>
                Consent for Sharing of Medical Information
              </h2>
              <p className='text-xs'>
                In your application for Minnesota Health Care Program coverage,
                you have given your written and signed consent to the following
                agencies and people to share between them medical information
                about you only for the limited purposes indicated:
              </p>
              <article className='pl-4 text-xs'>
                <p>
                  • Health providers, including health plans, insurance
                  agencies, MA or MinnesotaCare, county advocates, school
                  districts, your county or state case workers, and their
                  contractors and subcontractors, for these purposes:
                </p>
                <ol className='pl-2'>
                  <li>◦To determine who should pay for your health care</li>
                  <li>
                    ◦To provide, manage and coordinate health care services
                  </li>
                </ol>
                <p>
                  {' '}
                  All other agencies or people listed on this Notice of Privacy
                  Practices and Notice of Rights and Responsibilities, for this
                  purpose:
                </p>
                <ol className='pl-2'>
                  <li>
                    ◦To administer Minnesota Health Care Programs, pay for
                    services, and conduct research and investigations
                  </li>
                </ol>
                <p className='mt-2'>
                  This consent applies to medical information about your minor
                  children you applied for on this application.
                </p>
                <p className='mt-2'>
                  You can stop this consent at any time by asking in writing for
                  it to end. The written notice to stop this consent will not
                  affect information the agency has already given to others.
                  This consent is good while you are enrolled in MA or
                  MinnesotaCare, up to one year or longer if the law permits
                </p>
                <p className='mt-2'>
                  However, it does not end after one year for records given to
                  consulting providers or for payment of your bills, fraud
                  investigations or quality-of-care review and studies.
                </p>
                <p className='mt-2'>
                  An agency or person who gets your information through this
                  consent could give the information to others.
                </p>
                <p className='mt-2'>
                  If you end this consent, you cannot enroll or stay enrolled in
                  Minnesota Health Care Programs.
                </p>
              </article>
              <p className='mt-4 text-xs'>
                If you are enrolled in MA when you are 55 years old or older,
                after you die, Minnesota must try to recover certain payments
                the MA program made for your health care, including:
              </p>
              <article className='pl-2 text-xs'>
                <p>• Nursing home services</p>
                <p>• Home and community-based services</p>
                <p>• Related hospital and prescription drug costs</p>
                <p>
                  • Managed Care premiums (capitations) for coverage of these
                  services.
                </p>
              </article>
              <p className='my-2 text-xs'>
                Home and community-based services include home health and
                skilled nursing services, personal care attendant costs, and
                medical supplies and equipment. They also include physical
                therapy, occupational therapy and speech therapy, when the
                therapy is provided by a home health or home rehabilitation
                agency.
              </p>
              <p className='mb-2 text-xs'>
                If you permanently live in a medical institution, Minnesota must
                also try to recover the costs of all MA services you received
                while living in a medical institution. If you are permanently
                living in a medical institution and you do not have a spouse or
                disabled child living on your homesteaded real property, the
                state may file an MA lien against your real property to recover
                MA costs before your death.
              </p>
              <p className='text-xs mt-28 pt-12'>
                After you die, the state also may file a notice of potential
                claim, which is a form of lien, against real property to recover
                MA costs. Liens to recover MA costs may be filed against the
                following:
              </p>
              <article className='pl-2 mb-2 text-xs'>
                <p>
                  • Your life estate or joint tenancy interest in real property
                </p>
                <p>• Your real property that you own solely</p>
                <p>• Your real property that you own with someone else</p>
              </article>
              <p className='mb-2 text-xs'>
                Minnesota cannot start recovery of these costs while your spouse
                is still living or if you have a child under 21 years old or a
                child who is permanently disabled. Once your spouse dies,
                Minnesota must try to recover your MA costs from your spouse's
                estate. However, recovery is further delayed if you still have a
                child who is under 21 or permanently disabled. Your children do
                not have to use their assets to reimburse the state for any MA
                services you received.
              </p>
              <p className='text-xs'>
                You have the right to speak with a legal-aid group or a private
                attorney if you have specific questions about how MA estate
                recovery and liens may affect your circumstance and estate
                planning. The Minnesota Department of Human Services cannot
                provide you with legal advice. For more information, go to
                http://mn.gov/dhs/ma-estate-recovery/.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
