import React from 'react';

interface GenericWrapperProps {
  children: React.ReactNode;
}

export const PrintablePageWrapper: React.FC<GenericWrapperProps> = ({ children, ...props }) => {
  const wrappedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...child.props, ...props });
    }
    return child;
  });

  return <div className='page'>{wrappedChildren}</div>;
};

