import { gql } from "@apollo/client";

export interface PaiFragment {
  name: string;
  expectedIncome: number | null;
  adjustmentsIncome: number | null;
  projectedAnnualIncome: number | null;
}

export const PAI_FRAGMENT = gql`
  fragment PaiFragment on PaiType {
    name
    expectedIncome
    adjustmentsIncome
    projectedAnnualIncome
  }
`;
