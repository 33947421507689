import React, { useRef } from "react";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { FastField } from "formik";
import { useTranslation } from "react-i18next";
import { FieldProps } from "formik/dist/Field";
import { ExtraLabel } from "components";
import { StyledTextField } from "./FormikInputField.css";

interface FormikInputFieldProps {
  name: string;
  label: string;
  extraLabel?: string;
}

export const FormikInputField = ({
  name,
  label,
  extraLabel,
  ...rest
}: FormikInputFieldProps & TextFieldProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      {extraLabel && <ExtraLabel label={extraLabel} />}
      <FastField name={name}>
        {({ field, meta, form }: FieldProps<string>) => {
          return (
            <StyledTextField
              {...field}
              {...rest}
              fullWidth
              value={field.value}
              onFocus={(event) => {
                event.target.select();
                inputRef?.current?.focus();
              }}
              size="small"
              label={t(label)}
              variant="outlined"
              error={!!form.submitCount && Boolean(meta?.error)}
              helperText={Boolean(form.submitCount) && t(meta?.error)}
              autoComplete="off"
            />
          );
        }}
      </FastField>
    </>
  );
};
