import React from 'react';
import {ExtraLabelText} from './ExtraLabel.css'
import {useTranslation} from "react-i18next";

interface ExtraLabelProps {
  label?: string
}

export const ExtraLabel = ({label}: ExtraLabelProps) => {
  const { t } = useTranslation()
  if(!label) return null;
  return <ExtraLabelText>{t(label)}</ExtraLabelText>
};

