import { validation } from "formUtils/validation";
import * as Yup from "yup";

export const yesNoOptions = [
  { label: "yes", value: true },
  { label: "no", value: false },
];

export const validationSchema = Yup.object().shape({
  anyoneEnrolledInHealthCoverage: validation.booleanRequired,
  anyoneOfferedButNotEnrolledThroughAJob: validation.booleanRequired,
  dateLeft: validation.stringNotRequired,
  expectedReturnDate: validation.stringNotRequired,
  hasDeterminedDisabledSsa: validation.booleanRequired,
  hasHealthConditions: validation.booleanRequired,
  hasOngoingMedicalBills: validation.booleanRequired,
  hasReturnedFromMilitaryDutyTour: validation.booleanRequired,
  isBlindOrDisabled: validation.booleanRequired,
  isDispositionOfCharges: validation.booleanNotRequired,
  isGettingServiceCvt: validation.booleanRequired,
  isInJail: validation.booleanRequired,
  isNeedHomeCareHelp: validation.booleanRequired,
  isNeedPayingMedical: validation.booleanRequired,
  isOutsideOfState: validation.booleanRequired,
  isReceivingSsi: validation.booleanRequired,
  lastActiveDate: validation.stringNotRequired,
  whoHasDeterminedDisabledSsa: validation.stringNotRequired,
  whoHasHealthConditions: validation.stringNotRequired,
  whoHasOngoingMedicalBills: validation.stringNotRequired,
  whoHasReturnedFromMilitaryDutyTour: validation.stringNotRequired,
  whoIsBlindOrDisabled: validation.stringNotRequired,
  whoIsDispositionOfCharges: validation.stringNotRequired,
  whoIsGettingServiceCvt: validation.stringNotRequired,
  whoIsInJail: validation.stringNotRequired,
  whoIsNeedHomeCareHelp: validation.stringNotRequired,
  whoIsNeedPayingMedical: validation.stringNotRequired,
  whoIsOutsideOfState: validation.stringNotRequired,
  whoIsReceivingSsi: validation.stringNotRequired,
});
