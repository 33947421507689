import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {LanguageEnum} from "enums";
import en from './locales/en.json'
import es from './locales/es.json'
import so from './locales/so.json'

const defaultLanguage = sessionStorage.getItem('lang') || LanguageEnum.EN;

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      },
      es: {
        translation: es
      },
      so: {
        translation: so
      }
    },
    lng: defaultLanguage,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
