import { gql } from "@apollo/client";
import { CURRENT_JOB_FRAGMENT, CurrentJobFragment } from "./currentJobFragment";
import {
  HEALTH_COVERAGE_FRAGMENT,
  HealthCoverageFragment,
} from "./healthCoverageFragment";
import {
  INCOME_QUESTION_FRAGMENT,
  IncomeQuestionFragment,
} from "./incomeQuestionFragment";
import { INCOME_TYPE_FRAGMENT, IncomeTypeFragment } from "./incomeTypeFragment";
import { PAI_FRAGMENT, PaiFragment } from "./paiFragment";
import { PREGNANCY_FRAGMENT, PregnancyFragment } from "./pregnancyFragment";
import {
  SEASONAL_JOB_FRAGMENT,
  SeasonalJobFragment,
} from "./seasonalJobFragment";
import {
  SELF_EMPLOYMENT_FRAGMENT,
  SelfEmploymentFragment,
} from "./selfEmploymentFragment";
import { TAX_FILING_FRAGMENT, TaxFilingFragment } from "./taxFilingFragment";
import {
  OTHER_INCOME_SOURCE_FRAGMENT,
  OtherIncomeSourceFragment,
} from "graphql/fragments/otherIncomeSourceFragment";
import {
  AdjustmentsIncomeFragment,
  ADJUSTMENTS_INCOME_FRAGMENT,
} from "graphql/fragments/adjustmentsIncomeFragment";

export interface HouseholdMembersFragment {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  birthday: string;
  maritalStatus: string;
  relationship: string;
  pregnancy: PregnancyFragment;
  taxFiling: TaxFilingFragment;
  incomeQuestions: IncomeQuestionFragment;
  incomeType: IncomeTypeFragment;
  currentJobs: CurrentJobFragment[];
  selfEmployment: SelfEmploymentFragment;
  seasonalJobs: SeasonalJobFragment[];
  otherIncomeSource: OtherIncomeSourceFragment;
  adjustmentIncome: AdjustmentsIncomeFragment;
  healthCoverage: HealthCoverageFragment;
  pai: PaiFragment;
}

export const HOUSEHOLD_MEMBERS_FRAGMENT = gql`
  fragment HouseholdMembersFragment on HouseholdMemberType {
    id
    firstName
    middleName
    lastName
    birthday
    maritalStatus
    relationship
    pregnancy {
      ...PregnancyFragment
    }
    taxFiling {
      ...TaxFilingFragment
    }
    incomeQuestions {
      ...IncomeQuestionFragment
    }
    incomeType {
      ...IncomeTypeFragment
    }
    currentJobs {
      ...CurrentJobFragment
    }
    selfEmployment {
      ...SelfEmploymentFragment
    }
    seasonalJobs {
      ...SeasonalJobFragment
    }
    otherIncomeSource {
      ...OtherIncomeSourceFragment
    }
    adjustmentIncome {
      ...AdjustmentsIncomeFragment
    }
    pai {
      ...PaiFragment
    }
    healthCoverage {
      ...HealthCoverageFragment
    }
  }
  ${PREGNANCY_FRAGMENT}
  ${TAX_FILING_FRAGMENT}
  ${INCOME_QUESTION_FRAGMENT}
  ${INCOME_TYPE_FRAGMENT}
  ${CURRENT_JOB_FRAGMENT}
  ${SELF_EMPLOYMENT_FRAGMENT}
  ${SEASONAL_JOB_FRAGMENT}
  ${OTHER_INCOME_SOURCE_FRAGMENT}
  ${ADJUSTMENTS_INCOME_FRAGMENT}
  ${PAI_FRAGMENT}
  ${HEALTH_COVERAGE_FRAGMENT}
`;
