import { gql } from "@apollo/client";

export interface SignFragment {
  name: string;
  signDate: string;
  signature: string | null;
  renewalTaxReturnConsent: boolean;
  renewalTaxReturnConsentYears: number;
}

export const SIGN_FRAGMENT = gql`
  fragment SignFragment on SignType {
    name
    signDate
    signature
    renewalTaxReturnConsent
    renewalTaxReturnConsentYears
  }
`;
