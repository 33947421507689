import {RelationshipEnum} from "enums";

export const relationshipOptions = [
  {uuid: RelationshipEnum.SPOUSE, name: RelationshipEnum.SPOUSE},
  {uuid: RelationshipEnum.CHILD, name: RelationshipEnum.CHILD},
  {uuid: RelationshipEnum.STEP_CHILD, name: RelationshipEnum.STEP_CHILD},
  {uuid: RelationshipEnum.UNCLE, name: RelationshipEnum.UNCLE},
  {uuid: RelationshipEnum.AUNT, name: RelationshipEnum.AUNT},
  {uuid: RelationshipEnum.GRANDPARENT, name: RelationshipEnum.GRANDPARENT},
  {uuid: RelationshipEnum.NOT_RELATED, name: RelationshipEnum.NOT_RELATED},
]
