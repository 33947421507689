import React from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { RenewingCoverageModel } from "models";
import { FormActionButtons, Select, Typography } from "components";

import { renewingCoverageOptions, validationSchema } from "./formUtils";
import { Form, FormContent, GridContainer } from "../Forms.css";

interface RenewingCoverageFutureFormProps {
  onSubmit: (values: RenewingCoverageModel) => void;
  onBack: () => void;
  defaultValues: RenewingCoverageModel;
}

export const RenewingCoverageFutureForm = ({
  onSubmit,
  onBack,
  defaultValues,
}: RenewingCoverageFutureFormProps) => {
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: defaultValues,
    onSubmit: (values: RenewingCoverageModel) => {
      onSubmit(values);
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit} autoComplete="off">
      <FormContent>
        <GridContainer>
          <Grid item xs={12} md={12}>
            <Typography variant="body">
              {t("renewing_coverage_agreement_label")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label=""
              name="agreementDuration"
              formik={formik}
              options={renewingCoverageOptions}
            />
          </Grid>
        </GridContainer>
      </FormContent>
      <FormActionButtons onBack={onBack} />
    </Form>
  );
};
