import React, { useRef } from "react";
import { FormikValues } from "formik";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface InputProps<T> {
  label: string;
  name: keyof T;
  formik: FormikValues;
}

export const Input = <T,>({
  label,
  name,
  formik,
  ...rest
}: InputProps<T> & TextFieldProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <TextField
      {...rest}
      inputRef={inputRef}
      id={name as string}
      name={name as string}
      label={t(label)}
      fullWidth
      size="small"
      value={formik.values[name]}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      onFocus={(event) => {
        event.target.select();
        inputRef?.current?.focus();
      }}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && t(formik.errors[name])}
      {...(formik.values[name] && { shrink: true })}
    />
  );
};
