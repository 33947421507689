import React from "react";
import { Grid } from "@mui/material";
import {
  FormActions,
  FormikBirthdayPickerField,
  FormikInputField,
  FormRow,
  Typography,
} from "components";
import { GridContainer } from "../DetailMemberJobRow.css";

interface SelfEmploymentFormProps {
  index: number;
}

export const SelfEmploymentForm = ({ index }: SelfEmploymentFormProps) => {
  const renderFormRows = (
    <FormRow>
      <GridContainer>
        <Grid item md={4} xs={12}>
          <FormikInputField
            label="self_employed_person_name"
            name={`detailJobMembers.${index}.selfEmployment.selfEmployedName`}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikInputField
            label="type_of_business"
            name={`detailJobMembers.${index}.selfEmployment.typeOfBusiness`}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikBirthdayPickerField
            label="business_start_date"
            name={`detailJobMembers.${index}.selfEmployment.businessStartDate`}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography variant={"subtitle"}>
            total_income_or_loss_expect_in_12_month
          </Typography>
        </Grid>
        <Grid item md={3} xs={12}>
          <FormikInputField
            type="number"
            label="income_amount"
            name={`detailJobMembers.${index}.selfEmployment.incomeAmount`}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormikInputField
            type="number"
            label="loss_amount"
            name={`detailJobMembers.${index}.selfEmployment.lossAmount`}
          />
        </Grid>
      </GridContainer>
    </FormRow>
  );

  return (
    <FormActions title="self_employment_form_title" form={renderFormRows} />
  );
};
