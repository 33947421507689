import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

export const runApolloClientConfig = (token: string | null) => {
  const link = createHttpLink({
    uri: process.env.REACT_APP_BASE_URL_GRAPHQL,
    credentials: "include",
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(link),
    ...(token && { headers: { authorization: token } }),
  });
};
