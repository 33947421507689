import { gql } from "@apollo/client";

export interface CREATE_FILE_UPLOAD_vars {
  caseAccessId: string;
  fileExtension: string;
  documentDescription: string;
  householdMemberId: string;
  documentUploadType: string;
}

export interface CREATE_FILE_UPLOAD_data {
  createFileUpload: {
    id: string;
    url: string;
    fields: string;
  };
}

export const CREATE_FILE_UPLOAD = gql`
  mutation createFileUpload(
    $caseAccessId: UUID!
    $uploadAccessId: String
    $documentDescription: String
    $householdMemberId: UUID
    $fileExtension: String!
    $documentUploadType: DocumentUploadType!
  ) {
    createFileUpload(
      caseAccessId: $caseAccessId
      uploadAccessId: $uploadAccessId
      documentDescription: $documentDescription
      householdMemberId: $householdMemberId
      fileExtension: $fileExtension
      documentUploadType: $documentUploadType
    ) {
      id
      url
      fields
    }
  }
`;
