import styled, { css } from "styled-components";
import { Button, Grid } from "@mui/material";
import { colors } from "styles/colors";

export const GridContainer = styled(Grid)(() => ({}));
GridContainer.defaultProps = {
  container: true,
  columnSpacing: 2,
  rowSpacing: 2,
};

export const ContainerCurrentJobs = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const RemoveFormButton = styled(Button)(
  () => css`
    display: flex !important;
    background: ${colors.error} !important;
    margin: 12px 0 0 auto !important;
    color: ${colors.white};
    width: max-content;
  `,
);

export const AddFormButton = styled(Button)(
  () => css`
    width: max-content;
    margin: 0 0 0 auto !important;
  `,
);
AddFormButton.defaultProps = {
  variant: "outlined",
};
