import { gql } from "@apollo/client";

export interface ApplicantFragment {
  caseNumber: string | null;
  firstName: string;
  middleName: string | null;
  lastName: string;
  birthday: string;
  maritalStatus: string;
  phoneType: string;
  otherPhoneType: string | null;
  phoneNumber: string;
  otherPhoneNumber: string | null;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
  alternativeStreetAddress: string | null;
  alternativeCity: string | null;
  alternativeState: string | null;
  alternativeZipCode: string | null;
  alternativeCounty: string | null;
  contactViaEmail: boolean;
  email: string | null;
  allowTextMessage: boolean;
}

export const APPLICANT_FRAGMENT = gql`
  fragment ApplicantFragment on ApplicantType {
    caseNumber
    firstName
    middleName
    lastName
    birthday
    maritalStatus
    phoneType
    otherPhoneType
    phoneNumber
    otherPhoneNumber
    streetAddress
    city
    state
    zipCode
    county
    alternativeStreetAddress
    alternativeCity
    alternativeState
    alternativeZipCode
    alternativeCounty
    contactViaEmail
    email
    allowTextMessage
  }
`;
