import { gql } from "@apollo/client";

export interface IncomeQuestionFragment {
  hasIncome: boolean;
}

export const INCOME_QUESTION_FRAGMENT = gql`
  fragment IncomeQuestionFragment on IncomeQuestionType {
    hasIncome
  }
`;
