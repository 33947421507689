import { gql } from "@apollo/client";

interface Income {
  type: string;
  amount: number;
  frequency: string;
}
export interface OtherIncomeSourceFragment {
  typeOfIncome: string;
  howOftenReceived: string;
  amount: number | null;
  notTaxableInterestIncome: number | null;
  income: Income[];
}

export const OTHER_INCOME_SOURCE_FRAGMENT = gql`
  fragment OtherIncomeSourceFragment on OtherIncomeSourceType {
    typeOfIncome
    howOftenReceived
    amount
    notTaxableInterestIncome
    income {
      type
      amount
      frequency
    }
  }
`;
