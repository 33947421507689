import { MaritalStatusEnum } from "enums";

export const maritalStatusOptions = [
  { uuid: MaritalStatusEnum.MARRIED, name: MaritalStatusEnum.MARRIED },
  {
    uuid: MaritalStatusEnum.LEGALLY_SEPARATED,
    name: MaritalStatusEnum.LEGALLY_SEPARATED,
  },
  { uuid: MaritalStatusEnum.DIVORCED, name: MaritalStatusEnum.DIVORCED },
  {
    uuid: MaritalStatusEnum.NEVER_MARRIED,
    name: MaritalStatusEnum.NEVER_MARRIED,
  },
  { uuid: MaritalStatusEnum.WIDOWED, name: MaritalStatusEnum.WIDOWED },
];
