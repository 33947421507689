import React from 'react';
import {FieldArray, Formik} from "formik";
import {FormActionButtons} from "components";
import {IncomeQuestionModel} from "models";
import {IncomeQuestionRow} from './IncomeQuestionRow'
import {validationSchema} from './formUtils';
import {Form, FormContent, MemberRows} from '../Forms.css'

export interface IncomeQuestionFormType {
  incomeMembers: IncomeQuestionModel[]
}

interface IncomeQuestionFormProps {
  onSubmit: (values: IncomeQuestionFormType, nextStep: number) => void;
  onBack: () => void;
  defaultValues: IncomeQuestionModel[]
}

export const IncomeQuestionForm = ({onBack, defaultValues, onSubmit}: IncomeQuestionFormProps) => {

  return (
    <Formik
      initialValues={{incomeMembers: defaultValues}}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={(values) => {
        const hasAnyoneIncome = values.incomeMembers.some(m => JSON.parse(String(m.hasIncome)))
        onSubmit(values, hasAnyoneIncome ? 1 : 5)
      }}
    >
      {({values, handleSubmit}) => (
        <Form onSubmit={handleSubmit}  autoComplete="off">
          <FieldArray name="incomeMembers">
            {() => {
              return (
                <FormContent>
                  <MemberRows>
                    {values?.incomeMembers?.map((member, index: number) => (
                      <IncomeQuestionRow key={member.id} index={index}/>
                    ))}
                  </MemberRows>
                </FormContent>
              );
            }}
          </FieldArray>
          <FormActionButtons onBack={onBack}/>
        </Form>
      )}
    </Formik>
  );
};

