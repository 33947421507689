import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import uploadedSuccessfully from "assets/application_submitted.jpg";
import { Button } from "@mui/material";
import { FormActions } from "components";
import { routes } from "routes";
import { Container, Content, ImageSuccess } from "./UploadedSuccessPage.css";
import { DataFormsContext } from "context/DataFormsContext";
import { usePersistentCurrentStep } from "hooks/usePersistentCurrentStep";

export const UploadedSuccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { resetData } = useContext(DataFormsContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCurrentStep] = usePersistentCurrentStep(1);

  const onBackRedirect = async () => {
    await resetData();
    await setCurrentStep(1);
    navigate(routes.form);
  };

  return (
    <Container>
      <FormActions
        title="upload_received_title"
        form={
          <>
            <Content>
              <ImageSuccess
                src={uploadedSuccessfully}
                alt="uploaded successfully"
              />
              <span>{t("upload_received_subtitle")}</span>
              <span>{t("upload_received_text")}</span>
            </Content>
            <Button onClick={onBackRedirect} fullWidth variant="contained">
              File New application
            </Button>
          </>
        }
      />
    </Container>
  );
};
