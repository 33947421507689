import React, { useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { Button } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { PrintablePageWrapper } from "pages/PdfPages/PrintablePageWrapper";
import {
  CASE_BY_ID,
  CASE_BY_ID_data,
  CASE_BY_ID_vars,
} from "graphql/query/caseById";
import {
  DocumentsPage,
  PageEight,
  PageEighteen,
  PageEleven,
  PageFive,
  PageFour,
  PageNine,
  PageNineteen,
  PageOne,
  PageSeven,
  PageSeventeen,
  PageSix,
  PageSixteen,
  PageTen,
  PageThirteen,
  PageThree,
  PageTwelve,
  PageTwenty,
  PageTwentyOne,
  PageTwentyTwo,
  PageTwo,
} from "./pages";
import { WrapperButton, WrapperPages } from "./PdfPages.css";
import "./pdf.css";
import { Loader, NotFound } from "components";
import { useParams } from "react-router-dom";

interface Props {
  openPdfView: boolean;
}
export const PdfPages = ({ openPdfView }: Props) => {
  const { id } = useParams();

  const {
    data: enrollmentData,
    loading,
    error,
  } = useQuery<CASE_BY_ID_data, CASE_BY_ID_vars>(CASE_BY_ID, {
    variables: {
      caseAccessId: id ?? "",
    },
  });
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current as HTMLDivElement | null,
  });

  useEffect(() => {
    if (componentRef.current && openPdfView) handlePrint();
  }, [handlePrint, openPdfView]);

  if (!loading && !enrollmentData && error) {
    return <NotFound />;
  }

  if (loading) {
    return <Loader />;
  }

  const {
    applicant,
    sign,
    followingQuestions,
    householdMembers,
    sameAnnualIncome,
  } = enrollmentData!.caseById?.mnsureEnrollment;

  const allUploadedFiles = enrollmentData?.caseById.fileUploads;
  const caseNumber = applicant?.caseNumber ?? "";
  const agreementDuration = sign.renewalTaxReturnConsentYears;

  const uploadedFiles = allUploadedFiles?.filter(
    (f) => f.documentUploadType !== "signature_page",
  );

  return (
    <WrapperPages>
      <WrapperButton>
        <Button variant={"contained"} onClick={handlePrint}>
          PRINT
        </Button>
      </WrapperButton>

      <div ref={componentRef}>
        <PrintablePageWrapper children={<PageOne />} />
        <PrintablePageWrapper children={<PageTwo />} />
        <PrintablePageWrapper children={<PageThree data={applicant} />} />
        <PrintablePageWrapper
          children={
            <PageFour
              caseNumber={caseNumber}
              householdMembers={householdMembers}
            />
          }
        />
        <PrintablePageWrapper
          children={
            <PageFive
              caseNumber={caseNumber}
              householdMembers={householdMembers}
            />
          }
        />
        <PrintablePageWrapper
          children={
            <PageSix
              caseNumber={caseNumber}
              householdMembers={householdMembers}
            />
          }
        />
        <PrintablePageWrapper
          children={
            <PageSeven
              caseNumber={caseNumber}
              householdMembers={householdMembers}
            />
          }
        />
        <PrintablePageWrapper
          children={
            <PageEight
              caseNumber={caseNumber}
              householdMembers={householdMembers}
            />
          }
        />
        <PrintablePageWrapper
          children={
            <PageNine
              caseNumber={caseNumber}
              householdMembers={householdMembers}
            />
          }
        />

        <PrintablePageWrapper
          children={
            <PageTen
              sameAnnualIncome={sameAnnualIncome}
              caseNumber={caseNumber}
              householdMembers={householdMembers}
            />
          }
        />
        <PrintablePageWrapper
          children={
            <PageEleven
              caseNumber={caseNumber}
              householdMembers={householdMembers}
            />
          }
        />
        <PrintablePageWrapper
          children={<PageTwelve caseNumber={caseNumber} />}
        />
        <PrintablePageWrapper
          children={
            <PageThirteen
              caseNumber={caseNumber}
              agreementDuration={agreementDuration}
              followingQuestions={followingQuestions}
            />
          }
        />
        {uploadedFiles?.length && (
          <DocumentsPage uploadedFiles={uploadedFiles ?? []} />
        )}

        <PrintablePageWrapper
          children={<PageSixteen caseNumber={caseNumber} />}
        />
        <PrintablePageWrapper
          children={<PageSeventeen caseNumber={caseNumber} />}
        />
        <PrintablePageWrapper
          children={<PageEighteen caseNumber={caseNumber} />}
        />
        <PrintablePageWrapper children={<PageNineteen />} />
        <PrintablePageWrapper children={<PageTwenty />} />
        <PrintablePageWrapper children={<PageTwentyOne />} />
        <PrintablePageWrapper children={<PageTwentyTwo />} />
      </div>
    </WrapperPages>
  );
};
