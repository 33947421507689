import React from 'react';

export const PageTwentyOne: React.FC = () => {
  return (
    <div className='container-fluid'>
      <div className='contentContainer flex flex-col'>
        <div className='text-content-container mt-40'>
          <h2 className='font-bold text-sm'>
            Minnesota Department of Human Rights (MDHR)
          </h2>
          <div>
            <p className='text-xs'>
              In Minnesota, you have the right to file a complaint with the MDHR
              if you believe you have been discriminated against because of any
              of the following: race, color, national origin, religion, creed,
              sex, sexual orientation, marital status, public assistance status,
              or disability
            </p>
            <p className='text-xs mt-2'>
              Contact the <strong>MDHR</strong> directly to file a complaint:
            </p>
            <ul className='text-xs pl-2'>
              <li>Minnesota Department of Human Rights</li>
              <li>540 Fairview Avenue North, Suite 201</li>
              <li>St. Paul, MN 55104</li>
              <li>651-539-1100 (voice) or 800-657-3704 (toll free)</li>
              <li>711 or 800-627-3529 (MN Relay)</li>
              <li>651-296-9042 (fax)</li>
              <li>Info.MDHR@state.mn.us (email)</li>
              <li>https://mn.gov/mdhr/intake/consultationinquiryform/</li>
            </ul>
            <h2 className='font-bold text-sm mt-6'>DHS</h2>
            <article className='text-xs mb-2 flex flex-col space-y-2'>
              <p>
                You have a right to file a complaint with DHS if you believe you
                have been discriminated against in our health care programs
                because of any of the following: race, color, national origin,
                creed, religion, public assistance status, marital status, age,
                disability, sex (including sexual orientation and gender
                identity), or political beliefs.
              </p>
              <p>
                Complaints must be in writing and filed within 180 days of the
                date you discovered the alleged discrimination. The complaint
                must contain your name and address and describe the
                discrimination you are complaining about. After we get your
                complaint, we will review it and notify you in writing about
                whether we have authority to investigate. If we do, we will
                investigate the complaint
              </p>
              <p>
                DHS will notify you in writing of the investigation's outcome.
                You have the right to appeal the outcome if you disagree with
                the decision. To appeal, you must send a written request to have
                DHS review the investigation outcome. Be brief and state why you
                disagree with the decision. Include additional information you
                think is important.
              </p>
              <p>
                If you file a complaint in this way, the people who work for the
                agency named in the complaint cannot retaliate against you. This
                means they cannot punish you in any way for filing a complaint.
                Filing a complaint in this way does not stop you from seeking
                out other legal or administrative actions.
              </p>
              <ul>
                <p>
                  Contact <strong>DHS</strong> directly to file a discrimination
                  complaint:
                </p>
                <div className='pl-2'>
                  <p>Civil Rights Coordinator</p>
                  <p>Minnesota Department of Human Services</p>
                  <p>Equal Opportunity and Access Division</p>
                  <p>PO Box 64997</p>
                  <p>St. Paul, MN 55164-0997</p>
                  <p>651-431-3040 (voice) or use your preferred relay service.</p>
                </div>
              </ul>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};
