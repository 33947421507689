import React from "react";
import {
  FormikInputField,
  FormikRadioButtonField,
  FormikSelectField,
  FormRow,
} from "components";
import {
  GridContainer,
  GridRowItem,
  GridRowRadioItem,
} from "./IncomeTypeForm.css";
import {
  currentJobOptions,
  otherIncomeSourceOptions,
  yesNoOptions,
} from "./formUtils";

interface IncomeTypeMemberRowProps {
  index: number;
}

export const IncomeTypeMemberRow = ({ index }: IncomeTypeMemberRowProps) => {
  return (
    <FormRow key={index}>
      <GridContainer>
        <GridRowItem>
          <FormikInputField
            disabled
            label="name"
            name={`incomeTypeMembers.${index}.name`}
          />
        </GridRowItem>

        <GridRowItem>
          <FormikSelectField
            label="has_current_or_part_time_job"
            name={`incomeTypeMembers.${index}.currentOrPartJob`}
            options={currentJobOptions}
            setUntranslatedValue={true}
          />
        </GridRowItem>

        <GridRowRadioItem>
          <FormikRadioButtonField
            extraLabel="has_seasonal_job"
            name={`incomeTypeMembers.${index}.hasSeasonalJob`}
            options={yesNoOptions}
          />
        </GridRowRadioItem>
        <GridRowRadioItem>
          <FormikRadioButtonField
            extraLabel="is_self_employed"
            name={`incomeTypeMembers.${index}.isSelfEmployed`}
            options={yesNoOptions}
          />
        </GridRowRadioItem>

        <GridRowItem>
          <FormikSelectField
            extraLabel="other_source_income_label"
            label=""
            name={`incomeTypeMembers.${index}.otherIncomeSource`}
            options={otherIncomeSourceOptions}
          />
        </GridRowItem>
      </GridContainer>
    </FormRow>
  );
};
