import React from "react";
import { CircularProgress } from "@mui/material";
import { Container } from "./Loader.css";

export const Loader = () => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};
