import {AppBar, Toolbar} from "@mui/material";
import styled from "styled-components";

export const HeaderBar = styled(AppBar)(() => ({
  '&.MuiAppBar-root': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  padding: '0 20px'
}))
HeaderBar.defaultProps = {
  position: 'static'
}

export const ToolbarStyled = styled(Toolbar)(()=>({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  '&.MuiToolbar-root': {
    padding: '0'
  },
}))