import { gql } from "@apollo/client";

export interface CurrentJobFragment {
  id: string;
  employerName: string;
  employerEin: string | null;
  employerStreetAddress: string | null;
  employerCity: string | null;
  employerZipCode: string | null;
  employerState: string | null;
  wage: string;
  hoursPerWeek: number | null;
  amount: number | null;
}

export const CURRENT_JOB_FRAGMENT = gql`
  fragment CurrentJobFragment on CurrentJobType {
    id
    employerName
    employerEin
    employerStreetAddress
    employerCity
    employerZipCode
    employerState
    wage
    hoursPerWeek
    amount
  }
`;
