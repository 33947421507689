type RouteMap = {
  [key: string]: string;
};
export const routes: RouteMap = {
  form: "/",
  upload: "/upload/:id",
  view: "/view/:id",
  print: "/print/:id",
  uploadSuccess: "/upload-success",
};
