import {validation} from "formUtils/validation";
import * as Yup from "yup";

export const yesNoOptions = [
  {label: 'yes', value: true},
  {label: 'no', value: false},
]


export const incomeMemberShape = {
  id: validation.stringRequired,
  name: validation.stringRequired,
  hasIncome: validation.booleanRequired,
};


export const validationSchema = Yup.object().shape({
  incomeMembers: Yup.array().of(Yup.object().shape(incomeMemberShape))
});
