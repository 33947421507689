export const parseStringToBoolean = (
  value: string | boolean | undefined,
): boolean => {
  if (!value) return false;
  if (typeof value === "boolean") return value;
  return JSON.parse(String(value));
};

export const parseErrorMessageByFieldName = (
  errors: any,
  fieldName: string,
): string => {
  const parts = fieldName.split(".");
  let currentObj = errors;
  for (const part of parts) {
    if (!currentObj || typeof currentObj !== "object") {
      return "";
    }
    currentObj = currentObj[part];
  }
  return currentObj ?? "";
};
