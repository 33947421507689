import React from "react";
import { FieldArray, useFormikContext } from "formik";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { Alert } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FormActions } from "components";

import {
  currentJobPlaceholder,
  DetailsJobFormType,
  FORM_LIMIT_COUNT,
} from "../../formUtils";
import { AddFormButton } from "../DetailMemberJobRow.css";
import { CurrentJobForm } from "./CurrentJobForm";

interface Props {
  index: number;
}
export const CurrentJobsForm = ({ index }: Props) => {
  const { values } = useFormikContext<DetailsJobFormType>();

  const notificationAlert = (
    <Alert icon={<InfoOutlinedIcon fontSize="inherit" />} severity="info">
      {`Please contact a Navigator if you have more than ${FORM_LIMIT_COUNT} jobs`}
    </Alert>
  );

  return (
    <FieldArray name={`detailJobMembers.${index}.currentJobs`}>
      {({ push, remove }) => {
        const displayAddFormButton =
          values.detailJobMembers[index].currentJobs?.length < FORM_LIMIT_COUNT;
        return (
          <>
            {values.detailJobMembers[index].currentJobs?.map(
              (_, currentJobIndex) => (
                <FormActions
                  title="current_part_job_form_title"
                  form={
                    <CurrentJobForm
                      key={currentJobIndex}
                      index={index}
                      currentJobIndex={currentJobIndex}
                      remove={() => remove(currentJobIndex)}
                    />
                  }
                />
              ),
            )}
            {displayAddFormButton ? (
              <AddFormButton
                startIcon={<AddCircleOutlineRoundedIcon />}
                variant="outlined"
                onClick={() => push(currentJobPlaceholder)}
              >
                Add Current Job
              </AddFormButton>
            ) : (
              notificationAlert
            )}
          </>
        );
      }}
    </FieldArray>
  );
};
