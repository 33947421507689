import React, { useRef } from "react";
import { FastField } from "formik";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { ExtraLabel } from "components";
import { StyledTextField } from "./FormikBirthdayPickerField.css";
import { formatToISODate, isoDateToFormattedString } from "utils/dates";

interface FormikInputFieldProps {
  name: string;
  label: string;
  disabled?: boolean;
  extraLabel?: string;
}

export const FormikBirthdayPickerField = ({
  name,
  label,
  disabled,
  extraLabel,
}: FormikInputFieldProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      {extraLabel && <ExtraLabel label={extraLabel} />}
      <FastField name={name}>
        {({ field, meta, form }: any) => {
          const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const parsedValue = formatToISODate(e.target.value);
            form.setFieldValue(name, parsedValue);
            e.target.value = parsedValue;
          };
          return (
            <InputMask
              {...field}
              mask="99/99/9999"
              value={isoDateToFormattedString(field.value ?? "")}
              onChange={handleChange}
              disabled={disabled}
              autoComplete="off"
              onFocus={(event) => {
                event.target.select();
                inputRef?.current?.focus();
              }}
            >
              {() => (
                <StyledTextField
                  label={t(label)}
                  disabled={disabled}
                  size="small"
                  fullWidth
                  name={name}
                  error={!!form?.submitCount && Boolean(meta?.error)}
                  helperText={Boolean(form?.submitCount) && t(meta?.error)}
                  autoComplete="off"
                />
              )}
            </InputMask>
          );
        }}
      </FastField>
    </>
  );
};
