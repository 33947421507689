import {validation} from "formUtils/validation";
import * as Yup from "yup";

export const yesNoOptions = [
  {label: 'yes', value: true},
  {label: 'no', value: false},
]

export const healthCareOptions = [
  {uuid: 'employer', name: 'employer'},
  {uuid: 'tricare', name: 'tricare'},
  {uuid: 'private_other_insurance', name: 'private_other_insurance'},
  {uuid: 'dental', name: 'dental'},
  {uuid: 'vision', name: 'vision'},
  {uuid: 'medicare', name: 'medicare'},
  {uuid: 'peace_corps', name: 'peace_corps'},
  {uuid: 'cobra', name: 'cobra'},
  {uuid: 'prescription_drug_coverage', name: 'prescription_drug_coverage'},
  {uuid: 'long_term_care', name: 'long_term_care'},
  {uuid: 'va_health_care_programs', name: 'va_health_care_programs'},
]

export const healthCoverageShape = {
  id: validation.stringRequired,
  name: validation.stringRequired,
  isEnrolledHealthCoverage: validation.booleanRequired,
  healthCoverage: Yup.string().when('isEnrolledHealthCoverage', {
    is: true,
    then: () => validation.stringRequired,
    otherwise: () => validation.stringNotRequired
  }),
  policyHolderName: Yup.string().when('isEnrolledHealthCoverage', {
    is: true,
    then: () => validation.stringRequired,
    otherwise: () => validation.stringNotRequired
  }),
  policyDateOfBirth: Yup.string().when('isEnrolledHealthCoverage', {
    is: true,
    then: () => validation.dateRequired,
    otherwise: () => validation.dateNotRequired
  }),
  insuranceCompanyName: Yup.string().when('isEnrolledHealthCoverage', {
    is: true,
    then: () => validation.stringRequired,
    otherwise: () => validation.stringNotRequired
  }),
  startDate: Yup.string().when('isEnrolledHealthCoverage', {
    is: true,
    then: () => validation.dateRequired,
    otherwise: () => validation.dateNotRequired
  }),
  endDate: Yup.string().when('isEnrolledHealthCoverage', {
    is: true,
    then: () => validation.dateRequired,
    otherwise: () => validation.dateNotRequired
  }),
  groupNumber: Yup.string().when('isEnrolledHealthCoverage', {
    is: true,
    then: () => validation.numberRequired,
    otherwise: () => validation.numberNotRequired
  }),
  insurancePolicyName: Yup.string().when('isEnrolledHealthCoverage', {
    is: true,
    then: () => validation.stringRequired,
    otherwise: () => validation.stringNotRequired
  }),
  policyCoverageList: Yup.string().when('isEnrolledHealthCoverage', {
    is: true,
    then: () => validation.stringRequired,
    otherwise: () => validation.stringNotRequired
  }),
  policyNumber: Yup.string().when('isEnrolledHealthCoverage', {
    is: true,
    then: () => validation.numberRequired,
    otherwise: () => validation.numberNotRequired
  }),
 };

export const validationSchema = Yup.object().shape({
  healthCoverageMembers: Yup.array().of(Yup.object().shape(healthCoverageShape)),
});
