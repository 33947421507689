import { gql } from "@apollo/client";

export interface SelfEmploymentFragment {
  selfEmployedName: string;
  typeOfBusiness: string;
  businessStartDate: string;
  incomeAmount: number | null;
  lossAmount: number | null;
}

export const SELF_EMPLOYMENT_FRAGMENT = gql`
  fragment SelfEmploymentFragment on SelfEmploymentType {
    selfEmployedName
    typeOfBusiness
    businessStartDate
    incomeAmount
    lossAmount
  }
`;
