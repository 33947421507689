import { useState, useEffect } from "react";

const STORAGE_KEY = "step";

export const usePersistentCurrentStep = (defaultStep: number = 1) => {
  const [currentStep, setCurrentStep] = useState(() => {
    const storedStep = sessionStorage.getItem(STORAGE_KEY);
    return storedStep ? parseInt(storedStep, 10) : defaultStep;
  });

  useEffect(() => {
    sessionStorage.setItem(STORAGE_KEY, currentStep.toString());
  }, [currentStep]);

  return [currentStep, setCurrentStep] as const;
}