import * as Yup from "yup";
import { object } from "yup";
import { TaxFillingStatusEnum } from "enums";
import { validation } from "formUtils/validation";

export const taxFillingMemberShape = {
  id: validation.stringRequired,
  name: validation.stringRequired,
  taxFilingStatus: validation.stringRequired,
  isMarriedFilingJointly: Yup.boolean().when("taxFilingStatus", {
    is: (v: string) => v === "filer",
    then: () => validation.booleanRequired,
    otherwise: () => validation.booleanNotRequired,
  }),
  claimantOfDependent: Yup.string().when("taxFilingStatus", {
    is: (v: string) => v === "dependent",
    then: () => validation.stringRequired,
    otherwise: () => validation.stringNotRequired,
  }),
  outsideHouseholdTaxDependent: validation.booleanRequired,
  isClaimedByNoncustodialParent: validation.booleanRequired,
};

export const validationSchema = Yup.object().shape({
  taxFillingMembers: Yup.array().of(object().shape(taxFillingMemberShape)),
});

export const taxFillingStatusOptions = [
  { uuid: TaxFillingStatusEnum.FILER, name: TaxFillingStatusEnum.FILER },
  {
    uuid: TaxFillingStatusEnum.DEPENDENT,
    name: TaxFillingStatusEnum.DEPENDENT,
  },
  {
    uuid: TaxFillingStatusEnum.NON_FILER,
    name: TaxFillingStatusEnum.NON_FILER,
  },
];

export const yesNoOptions = [
  { label: "yes", value: true },
  { label: "no", value: false },
];
