import React from "react";
import { FormikValues } from "formik";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ErrorLabel, ExtraLabel } from "components";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { parseErrorMessageByFieldName } from "utils/parse";

interface MultipleRadioGroupProps {
  formik: FormikValues;
  fieldName: string;
  options: { value: any; label: string }[];
  extraLabel?: string;
  isBooleanValues?: boolean;
  onChange?: () => void;
}

export const MultipleRadioGroup: React.FC<MultipleRadioGroupProps> = ({
  formik,
  fieldName,
  options,
  extraLabel,
  isBooleanValues = true,
  onChange,
}) => {
  const { t } = useTranslation();
  const errorMessage = parseErrorMessageByFieldName(formik.errors, fieldName);
  const displayError = formik.submitCount && errorMessage;

  const handleChange = (value: any) => {
    onChange?.();
    formik.setFieldValue(fieldName, value);
  };

  if (isBooleanValues) {
    return (
      <>
        {extraLabel && <ExtraLabel label={extraLabel} />}
        <FormGroup row>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checkedIcon={<RadioButtonCheckedIcon />}
                  icon={<RadioButtonUncheckedIcon />}
                  defaultChecked={false}
                  checked={formik.values[fieldName] === option.value}
                  onChange={() => handleChange(option.value)}
                  color="primary"
                />
              }
              label={t(option.label)}
            />
          ))}
        </FormGroup>
        {!!displayError && <ErrorLabel message={errorMessage} />}
      </>
    );
  }

  return (
    <>
      {extraLabel && <ExtraLabel label={extraLabel} />}
      <RadioGroup
        row
        value={formik.values[fieldName] || ""}
        onChange={(event) => {
          onChange?.();
          formik.setFieldValue(fieldName, event.target.value);
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={t(option.label)}
          />
        ))}
      </RadioGroup>
      {!!displayError && <ErrorLabel message={errorMessage} />}
    </>
  );
};
