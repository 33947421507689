import React from "react";
import { Grid } from "@mui/material";
import {
  FormActions,
  FormikInputField,
  FormikSelectField,
  FormRow,
  Typography,
} from "components/index";
import { GridContainer } from "components/pages/DhsPage/Forms/DetailsJobForm/DetailMemberJobRow/DetailMemberJobRow.css";
import { wageOptions } from "components/pages/DhsPage/Forms/DetailsJobForm/formUtils";

interface OtherIncomeSourceFormProps {
  index: number;
}

export const OtherIncomeSourceForm = ({
  index,
}: OtherIncomeSourceFormProps) => {
  const renderFormRows = (
    <FormRow>
      <GridContainer>
        <Grid item md={6} xs={12}>
          <FormikInputField
            disabled
            label="type_of_income"
            name={`detailJobMembers.${index}.otherIncomeSource.typeOfIncome`}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikSelectField
            label="how_often_received"
            name={`detailJobMembers.${index}.otherIncomeSource.howOftenReceived`}
            options={wageOptions.slice(1)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikInputField
            type="number"
            label="amount"
            name={`detailJobMembers.${index}.otherIncomeSource.amount`}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <FormikInputField
            type="number"
            label="not_taxable_interest_income"
            name={`detailJobMembers.${index}.otherIncomeSource.notTaxableInterestIncome`}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography variant="subtitle">
            other_expected_income_in_12_months_description
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikInputField
            label="type"
            name={`detailJobMembers.${index}.otherIncomeSource.incomes.0.type`}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikInputField
            type="number"
            label="amount"
            name={`detailJobMembers.${index}.otherIncomeSource.incomes.0.amount`}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikSelectField
            label="how_often_received"
            name={`detailJobMembers.${index}.otherIncomeSource.incomes.0.frequency`}
            options={wageOptions.slice(1)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikInputField
            label="type"
            name={`detailJobMembers.${index}.otherIncomeSource.incomes.1.type`}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikInputField
            type="number"
            label="amount"
            name={`detailJobMembers.${index}.otherIncomeSource.incomes.1.amount`}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <FormikSelectField
            label="how_often_received"
            name={`detailJobMembers.${index}.otherIncomeSource.incomes.1.frequency`}
            options={wageOptions.slice(1)}
          />
        </Grid>
      </GridContainer>
    </FormRow>
  );
  return <FormActions title="other_income_form_title" form={renderFormRows} />;
};
