import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import applicationSubmitted from "assets/application_submitted.jpg";
import { DataFormsContext } from "context/DataFormsContext";

import {
  Container,
  ImageSuccess,
  ButtonsBlock,
} from "./ApplicationSubmitted.css";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

interface ApplicationSubmittedProps {
  caseAccessId: string | undefined;
  setCurrentStep: (step: number) => void;
  resetData: () => void;
}

export const ApplicationSubmitted = ({
  setCurrentStep,
  resetData,
  caseAccessId,
}: ApplicationSubmittedProps) => {
  const { t } = useTranslation();
  const { updateData } = useContext(DataFormsContext);
  const navigate = useNavigate();

  const onHomeRedirect = () => {
    updateData((_) => null);
    setCurrentStep(1);
  };

  const onUploadRedirect = () => {
    setCurrentStep(1);
    resetData();
    navigate(`${routes.upload.replace(":id", caseAccessId ?? "")}`);
  };

  return (
    <Container>
      <div>
        <ImageSuccess
          src={applicationSubmitted}
          alt={"successfully submitted"}
        />
        <span>{t("application_received_text")}</span>
        <span>{t("application_review_text")}</span>
      </div>
      <ButtonsBlock>
        <Button onClick={onHomeRedirect} variant="outlined" size="large">
          Fill new application
        </Button>
        <Button onClick={onUploadRedirect} variant="contained" size="large">
          Upload Documents
        </Button>
      </ButtonsBlock>
    </Container>
  );
};
