import * as Yup from "yup";
import {validation} from "formUtils/validation";

export const yearlyAmountOptions = [
  {uuid: 'education_expenses', name: 'education_expenses'},
  {uuid: 'business_expenses_of_reservists', name: 'business_expenses_of_reservists'},
  {uuid: 'health_savings_deduction', name: 'health_savings_deduction'},
  {uuid: 'moving_expenses_military', name: 'moving_expenses_military'},
  {uuid: 'deductible_self_employment_tax', name: 'deductible_self_employment_tax'},
  {uuid: 'sep_qualified_plans', name: 'sep_qualified_plans'},
  {uuid: 'self_employed_health_insurance_deduction', name: 'self_employed_health_insurance_deduction'},
  {uuid: 'early_withdrawal_penalty', name: 'early_withdrawal_penalty'},
  {uuid: 'alimony_paid', name: 'alimony_paid'},
  {uuid: 'ira_deduction', name: 'ira_deduction'},
  {uuid: 'student_loan_interest', name: 'student_loan_interest'},
]

export const yesNoOptions = [
  {label: 'yes', value: true},
  {label: 'no', value: false},
]

export const incomeAdjustmentShape = {
  id: validation.stringRequired,
  name: validation.stringRequired,
  type: Yup.string().when('hasIncomeAdjustment', {
    is: true,
    then: () => validation.stringRequired,
    otherwise: () => validation.numberNotRequired,
  }),
  yearlyAmount: Yup.string().when('hasIncomeAdjustment', {
    is: true,
    then: () => validation.numberRequired,
    otherwise: () => validation.numberNotRequired
  }),
  hasIncomeAdjustment: validation.booleanRequired
};

export const validationSchema = Yup.object().shape({
  adjustmentIncomeMembers: Yup.array().of(Yup.object().shape(incomeAdjustmentShape)),
});

