import React, { useMemo } from "react";
import { AdjustmentIncomeModel } from "models";
import { CaseNumber } from "pages/PdfPages/components";
import RadioButtons from "pages/PdfPages/components/RadioButtons/RadioButtons";
import { HouseholdMembersFragment } from "graphql/fragments/householdMembersFragment";

const MIN_ROW_LENGTH = 4;

interface Props {
  caseNumber: string;
  householdMembers: HouseholdMembersFragment[];
}

export const PageNine = ({ caseNumber, householdMembers }: Props) => {
  const hasAnyoneAdjustment = householdMembers.some(
    (a) => a.adjustmentIncome?.hasIncomeAdjustment,
  );

  const adjustmentIncomeMembers = householdMembers.map((a) => {
    return {
      id: a.id,
      name: `${a.firstName} ${a.lastName}`,
      type: a.adjustmentIncome?.type,
      yearlyAmount: a.adjustmentIncome?.yearlyAmount,
      hasIncomeAdjustment: a.adjustmentIncome?.hasIncomeAdjustment,
    };
  });

  const membersWithAdjustmentPlaceholders = useMemo(() => {
    // need to display min 4 rows for adjustmentIncomeMembers, with current members and fill with empty placeholders
    const membersCount = adjustmentIncomeMembers.length ?? 0;
    if (membersCount < MIN_ROW_LENGTH) {
      const existingMembers = adjustmentIncomeMembers || [];
      const adjustmentPlaceholder = {
        id: "",
        name: "",
        type: "",
        yearlyAmount: "",
        hasIncomeAdjustment: "",
      };

      return Array.from(
        { length: MIN_ROW_LENGTH },
        (_, index) => existingMembers[index] || adjustmentPlaceholder,
      );
    }
    return adjustmentIncomeMembers;
  }, [adjustmentIncomeMembers]);

  const renderAdjustmentRows = (item: AdjustmentIncomeModel | undefined) => {
    return (
      <tr key={item?.id ?? 1}>
        <td className="w-2/5">{item?.name ?? ""}</td>
        <td className="w-2/5">{item?.type ?? ""}</td>
        <td className="border border-gray-700" colSpan={parseInt("4")}>
          {" "}
          <p>$ {item?.yearlyAmount ?? ""}</p>
        </td>
      </tr>
    );
  };

  return (
    <div className="container-fluid">
      <div className="contentContainer flex flex-col space-y-4 mt-24">
        <CaseNumber caseNumber={caseNumber} />
        <div className="array_container border border-gray-700 bg-[#66666631] p-2">
          <div className="">
            <h2 className="font-bold text-base">
              11. Does anyone have adjustments to income?
            </h2>
            <RadioButtons
              items={[
                {
                  checked: hasAnyoneAdjustment,
                  label: "Yes – fill in the information.",
                },
                { checked: !hasAnyoneAdjustment, label: "No" },
              ]}
            />
            <p className="ml-4 text-xs">
              If you pay for certain things that can be subtracted from gross
              income on a federal income tax return, telling us about them could
              lower the cost of your health coverage. For more information about
              these adjustments, see the instructions for Schedule 1 of the IRS
              1040 federal tax return.
            </p>
            <div className="ml-4 mt-4 text-xs">
              <p>Include:</p>
              <ul className="flex space-x-8 mb-4">
                <span>
                  <li>• Educators expenses (up to $250)</li>
                  <li>
                    • Certain business expenses of reservists, performing arts,
                    and fee-basis government officials
                  </li>
                  <li>• Health savings account deduction</li>
                  <li>• Moving expenses for active duty military members</li>
                  <li>• Deductible part of self-employment tax</li>
                </span>
                <span>
                  <li>• Self-employed SEP, SIMPLE and qualified plans</li>
                  <li>• Self-employed health insurance deduction</li>
                  <li>• Penalty on early withdrawal of savings</li>
                  <li>
                    • Alimony paid (Do not list alimony payments if the payments
                    are based on a divorce or separation agreement dated after
                    2018.)
                  </li>
                  <li>• IRA deduction</li>
                  <li>• Student loan interest</li>
                </span>
              </ul>
            </div>
          </div>

          <table className="table bg-white">
            <thead>
              <tr>
                <th
                  className="border border-gray-700 text-xs"
                  colSpan={parseInt("")}
                >
                  Name
                </th>
                <th
                  className="border border-gray-700 text-xs"
                  colSpan={parseInt("")}
                >
                  Type of adjustment
                </th>
                <th
                  className="border border-gray-700 text-xs"
                  colSpan={parseInt("")}
                >
                  Yearly amount
                </th>
              </tr>
            </thead>
            <tbody className="text-xs">
              {hasAnyoneAdjustment
                ? membersWithAdjustmentPlaceholders?.map((item) => {
                    return renderAdjustmentRows(item);
                  })
                : Array.from({ length: MIN_ROW_LENGTH }, (_, index) =>
                    renderAdjustmentRows(undefined),
                  )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
