import React from "react";
import { Grid } from "@mui/material";
import {
  FormikInputField,
  FormikSelectField,
  Typography,
} from "components/index";
import { CurrentJobModel } from "models";
import { GridContainer } from "components/pages/DhsPage/Forms/DetailsJobForm/DetailMemberJobRow/DetailMemberJobRow.css";
import { wageOptions } from "components/pages/DhsPage/Forms/DetailsJobForm/formUtils";

interface CurrentJobFieldsProps
  extends Omit<CurrentJobModel, "hoursPerWeek" | "amount"> {
  hoursPerWeek: string;
  amount: string;
}

export const CurrentJobFields = ({
  employerName,
  employerEin,
  employerStreetAddress,
  employerCity,
  employerZipCode,
  employerState,
  wage,
  hoursPerWeek,
  amount,
}: CurrentJobFieldsProps) => {
  return (
    <GridContainer>
      <Grid item md={12} xs={12}>
        <Typography variant="subtitle">employer</Typography>
      </Grid>
      <Grid item md={8} xs={12}>
        <FormikInputField label="employer_name" name={employerName} />
      </Grid>
      <Grid item md={4} xs={12}>
        <FormikInputField label="employer_EIN" name={employerEin ?? ""} />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormikInputField
          label="employer_street_address"
          name={employerStreetAddress ?? ""}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <FormikInputField label="city" name={employerCity ?? ""} />
      </Grid>
      <Grid item md={1.5} xs={12}>
        <FormikInputField label="state" name={employerState ?? ""} />
      </Grid>
      <Grid item md={1.5} xs={12}>
        <FormikInputField label="zip_code" name={employerZipCode ?? ""} />
      </Grid>
      <Grid item md={2} xs={12}>
        <FormikSelectField name={wage} label="wage" options={wageOptions} />
      </Grid>
      <Grid item md={2} xs={12}>
        <FormikInputField
          type="number"
          label="hours_per_week"
          name={hoursPerWeek}
        />
      </Grid>
      <Grid item md={2} xs={12}>
        <FormikInputField type="number" label="amount" name={amount} />
      </Grid>
    </GridContainer>
  );
};
