import styled from "styled-components";
import { Grid } from "@mui/material";
import { GridContainer } from "components/pages/DhsPage/Forms/Forms.css";
import { colors } from "styles/colors";

export const GridContainerQuestions = styled(GridContainer)`
  &:nth-child(2n) {
    background: ${colors.primaryBg};
  }
`;

export const GridItem = styled(Grid)(() => ({}));
GridItem.defaultProps = {
  xs: 12,
  item: true,
};

export const GridItemCentered = styled(GridItem)<{ isPrimary?: boolean }>`
  display: flex !important;
  flex-direction: column !important;
  padding: ${({ isPrimary }) =>
    isPrimary ? "32px 0 32px 16px" : "16px 0 0 16px"} !important;
`;
