import React, { useState } from "react";
import { FieldArray, Formik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { FormActionButtons, ManualSelect } from "components";
import { HouseholdMemberModel } from "models";
import {
  generateHouseHoldMembersOptions,
  HouseholdMembersForm,
  initialMember,
  validationSchema,
} from "./formUtils";
import { HouseholdMemberRow } from "./HouseholdMemberRow";
import { Form, FormContent, MemberRows } from "../Forms.css";
import { MembersCountSection } from "./HouseHoldMemberListForm.css";

interface HouseholdMemberListFormProps {
  defaultValues: HouseholdMemberModel[];
  disableBackButton: boolean;
  onSubmit: (values: HouseholdMembersForm) => void;
  onBack: () => void;
}

export const HouseholdMemberListForm = ({
  defaultValues,
  disableBackButton,
  onSubmit,
  onBack,
}: HouseholdMemberListFormProps) => {
  const [memberCount, setMemberCount] = useState<number>(defaultValues?.length);
  const householdMembersCountOptions = generateHouseHoldMembersOptions();

  const initialFormValues = {
    members: defaultValues as HouseholdMemberModel[],
  };
  const handleMemberCountChange = (
    newMemberCount: number,
    values: HouseholdMembersForm,
    setFieldValue: (
      field: string,
      value: HouseholdMemberModel[],
      shouldValidate?: boolean,
    ) => void,
  ) => {
    const currentMemberCount = values.members.length;
    const newFieldsCount = newMemberCount - currentMemberCount;
    setMemberCount(newMemberCount);

    newFieldsCount > 0
      ? setFieldValue(
          "members",
          values.members.concat(Array(newFieldsCount).fill(initialMember)),
        )
      : setFieldValue("members", values.members.slice(0, newMemberCount));
  };
  const submitMembersWithId = (values: HouseholdMembersForm) => {
    return {
      members:
        values?.members?.map((m) => ({ ...m, id: m.id ?? uuidv4() })) ?? [],
    };
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={(values) => {
        onSubmit(submitMembersWithId(values));
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <FieldArray name="members">
            {() => {
              return (
                <FormContent>
                  <MembersCountSection>
                    <ManualSelect
                      fullWidth
                      label="household_member_count_question"
                      name="membersCount"
                      value={String(memberCount)}
                      onChange={(event: { target: { value: string } }) => {
                        const value = event.target.value;
                        handleMemberCountChange(+value, values, setFieldValue);
                      }}
                      options={householdMembersCountOptions}
                    />
                  </MembersCountSection>
                  <MemberRows>
                    {values?.members?.map((member, index) => (
                      <HouseholdMemberRow
                        key={member.id}
                        index={index}
                        disabled={index === 0}
                      />
                    ))}
                  </MemberRows>
                </FormContent>
              );
            }}
          </FieldArray>
          <FormActionButtons
            disableBackButton={disableBackButton}
            onBack={onBack}
          />
        </Form>
      )}
    </Formik>
  );
};
