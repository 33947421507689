import styled from "styled-components";
import {breakpoints} from "styles/breakpoints";

export const ButtonsSection = styled.div`
  display: flex;
  right: 20px;
  gap: 24px;
  margin-top: 24px;
  justify-content: flex-end;

  @media (max-width: ${breakpoints.md}) {  
   button {
     width: 100%;
   }
  }
`