import {createTheme} from "@mui/material";
import {colors} from "styles/colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.white
    },
  },
});
