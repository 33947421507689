import React from "react";
import { CaseNumber } from "pages/PdfPages/components";
import RadioButtons from "pages/PdfPages/components/RadioButtons/RadioButtons";
import { HouseholdMembersFragment } from "graphql/fragments/householdMembersFragment";
import { SeasonalJobFragment } from "graphql/fragments/seasonalJobFragment";
import { DetailJobMemberModel } from "models";

interface Props {
  caseNumber: string;
  householdMembers: HouseholdMembersFragment[];
}

interface SeasonalJobsPrintPage extends SeasonalJobFragment {
  id: string;
  name: string;
}

export const PageSeven = ({ caseNumber, householdMembers }: Props) => {
  const hasSeasonJobs = householdMembers.some((h) => h?.seasonalJobs?.length);
  const hasIsSelfEmployed = householdMembers.some((h) => h?.selfEmployment);

  const detailedCurrentJobMembers = householdMembers.map((h) => {
    return {
      id: h.id,
      name: `${h.firstName} ${h.lastName}`,
      seasonalJobs: h.seasonalJobs,
      selfEmployment: h.selfEmployment,
    };
  });
  const renderSeasonalJobHeader = () => {
    return (
      <div className="p-2">
        <h2 className="font-bold text-base">
          8. Does anyone have seasonal income?
        </h2>
        <RadioButtons
          items={[
            {
              checked: hasSeasonJobs,
              label: "Yes – fill in the information",
            },
            {
              checked: !hasSeasonJobs,
              label: "No",
            },
          ]}
        />
      </div>
    );
  };

  const renderSeasonalJob = (item: SeasonalJobsPrintPage | undefined) => {
    return (
      <table key={item?.id ?? ""} className="table bg-white">
        <thead>
          <tr>
            <th
              className="text-left font-bold text-sm"
              colSpan={parseInt("12")}
            >
              Seasonal Job
            </th>
          </tr>
        </thead>
        <tr>
          <td className="text-xs" colSpan={parseInt("12")}>
            <span className="font-bold">
              NAME <br />{" "}
              <input type="text" readOnly value={item?.name ?? ""} />
            </span>
          </td>
        </tr>
        <tbody>
          <tr>
            <td className="text-xs" colSpan={parseInt("6")}>
              <span className="font-bold">
                EMPLOYER NAME: Write the employer name that appears on your
                paycheck. <br />{" "}
              </span>
              <input type="text" readOnly value={item?.employerName} />
            </td>
            <td className="text-xs" colSpan={parseInt("6")}>
              <span className="font-bold">
                EMPLOYER IDENTIFICATION NUMBER (EIN) <br />{" "}
              </span>
              <input type="text" readOnly value={item?.employerEin} />
            </td>
          </tr>
          <tr>
            <td className="text-xs" colSpan={parseInt("6")}>
              <span className="font-bold">
                EMPLOYER STREET ADDRESS <br />{" "}
              </span>
              <input type="text" readOnly value={item?.employerStreetAddress} />
            </td>
            <td className="text-xs">
              <span className="font-bold">
                CITY <br />{" "}
              </span>
              <input type="text" readOnly value={item?.employerCity} />
            </td>
            <td className="text-xs">
              <span className="font-bold">
                STATE <br />{" "}
              </span>
              <input type="text" readOnly value={item?.employerState} />
            </td>
            <td className="text-xs">
              <span className="font-bold">
                ZIP CODE <br />{" "}
              </span>
              <input type="text" readOnly value={item?.employerZipCode} />
            </td>
          </tr>
          <tr>
            <td className="text-xs" colSpan={parseInt("6")}>
              <span className="font-bold">
                TOTAL EXPECTED SEASONAL INCOME FOR THE NEXT 12 MONTHS <br />{" "}
              </span>
              <input type="text" readOnly value={item?.seasonalIncome ?? ""} />
            </td>
            <td className="text-xs" colSpan={parseInt("6")}>
              <span className="font-bold">
                TOTAL EXPECTED UNEMPLOYMENT FOR THE NEXT 12 MONTHS <br />{" "}
              </span>
              <input
                type="text"
                readOnly
                value={item?.expectedUnemployment ?? ""}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderSelfEmploymentHeader = () => {
    return (
      <div className="p-2">
        <h2 className="font-bold text-base">9. Is anyone self-employed?</h2>
        <RadioButtons
          items={[
            {
              checked: hasIsSelfEmployed,
              label: "Yes – fill in the information",
            },
            {
              checked: !hasIsSelfEmployed,
              label: "No",
            },
          ]}
        />
      </div>
    );
  };

  const renderSelfEmployment = (
    job: Partial<DetailJobMemberModel> | undefined,
  ) => {
    return (
      <table key={job?.id} className="table bg-white">
        <thead>
          <tr>
            <th
              className="text-left font-bold text-sm"
              colSpan={parseInt("12")}
            >
              Self-employment
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-xs" colSpan={parseInt("5")}>
              <span className="font-bold">
                NAME OF SELF-EMPLOYED PERSON <br />{" "}
              </span>
              <input
                type="text"
                readOnly
                value={job?.selfEmployment?.selfEmployedName ?? ""}
              />
            </td>
            <td className="text-xs" colSpan={parseInt("5")}>
              <span className="font-bold">
                TYPE OF BUSINESS <br />{" "}
              </span>
              <input
                type="text"
                readOnly
                value={job?.selfEmployment?.typeOfBusiness ?? ""}
              />
            </td>
            <td className="text-xs" colSpan={parseInt("2")}>
              <span className="font-bold">
                BUSINESS START DATE <br />{" "}
              </span>
              <input
                type="text"
                readOnly
                value={job?.selfEmployment?.businessStartDate ?? ""}
              />
            </td>
          </tr>
          <tr>
            <td className="text-xs" colSpan={parseInt("12")}>
              <span className="font-bold">
                How much income or loss do you expect from self-employment for
                the next 12 months? <br />
              </span>
              <span className="inline-flex space-x-2 text-xs">
                <span className="inline-flex space-x-2">
                  <p>Income amount</p>
                  <p>$</p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={job?.selfEmployment?.incomeAmount ?? ""}
                    />
                  </p>
                </span>
                <span className="inline-flex items-end space-x-2">
                  <p>or loss amount </p>
                  <p>$</p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={job?.selfEmployment?.lossAmount ?? ""}
                    />
                  </p>
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div className="container-fluid  pt-12">
      <div className="contentContainer flex flex-col space-y-4 mt-10">
        <CaseNumber caseNumber={caseNumber} />
        <div className="array_container border border-gray-700 bg-[#66666631] p-2">
          {renderSeasonalJobHeader()}{" "}
          {hasSeasonJobs
            ? detailedCurrentJobMembers?.map((item) => {
                if (!item?.seasonalJobs.length) return <></>;
                return item.seasonalJobs.map((j) =>
                  renderSeasonalJob({ ...j, name: item.name, id: item.id }),
                );
              })
            : renderSeasonalJob(undefined)}
        </div>
      </div>
      <div className="array_container border border-gray-700 bg-[#66666631] p-2 mt-4">
        {renderSelfEmploymentHeader()}{" "}
        {hasIsSelfEmployed
          ? detailedCurrentJobMembers?.map((job) => {
              if (!job?.selfEmployment) return <></>;
              return renderSelfEmployment(job);
            })
          : renderSelfEmployment(undefined)}
      </div>
    </div>
  );
};
