import styled from "styled-components";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  text-align: center;
  height: 100%;
`;

export const ImageSuccess = styled("img")`
  height: 300px;
  display: flex;
  align-content: center;
  justify-content: center;
  object-fit: contain;
  margin: 0 auto;
`;

export const ButtonsBlock = styled("div")`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-direction: row;
  button {
    width: 100%;
  }
`;
