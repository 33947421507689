import React from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface Props {
  url: string;
}
export const PdfImage = ({ url }: Props) => {
  return (
    <Document file={url}>
      <Page pageNumber={1} />
    </Document>
  );
};
