import styled, { css } from "styled-components";
import { Button, Grid } from "@mui/material";
import { colors } from "styles/colors";

export const GridContainer = styled(Grid)(() => ({
  "&.MuiGrid-container": {
    marginTop: "0",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
}));
GridContainer.defaultProps = {
  container: true,
  columnSpacing: 2,
  rowSpacing: 2,
};

export const GridRowItem = styled(Grid)(() => ({
  width: "100%",
}));
GridRowItem.defaultProps = {
  item: true,
  xs: 12,
  md: 12,
};

export const GridRowEnd = styled(GridRowItem)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
}));

export const RemoveButton = styled(Button)(
  () => css`
    display: flex !important;
    color: ${colors.white};
    background: ${colors.error} !important;
  `,
);

export const ButtonContainer = styled("div")`
  padding: 20px;
  width: 100%;
`;
