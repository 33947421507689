import { gql } from "@apollo/client";

export interface PregnancyFragment {
  expectedBabiesCount: number | null;
  isPregnant: boolean;
  birthday: string | null;
}

export const PREGNANCY_FRAGMENT = gql`
  fragment PregnancyFragment on PregnancyType {
    expectedBabiesCount
    isPregnant
    birthday
  }
`;
