import React from "react";
import {Checkbox as MuiCheckbox, FormControlLabel} from '@mui/material';
import {FormikValues} from "formik";


interface CheckboxProps<T> {
  label: string;
  name: keyof T;
  formik: FormikValues;
}

export const Checkbox = <T, >({label, name, formik}: CheckboxProps<T>) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <MuiCheckbox
          checked={formik.values[name] || false}
          onChange={formik.handleChange}
          name={name as string}
        />
      }

    />
  );
};