import dayjs from "dayjs";

/**
 * Returns boolean if date is empty
 * @param dateStr - string which has format mm/dd/yyy
 */
export const isValidDate = (dateStr: string): boolean => {
  const dateObject = new Date(dateStr);
  return !isNaN(dateObject.getTime());
};

// export const getWeeksInCurrentYear = () => {
//   const currentYear = new Date().getFullYear();
//   const firstDay = new Date(currentYear, 0, 1);
//   const lastDay = new Date(currentYear, 11, 31);

//   const daysInYear =
//     (lastDay.getTime() - firstDay.getTime()) / (24 * 60 * 60 * 1000) + 1;

//   return Number((daysInYear / 7).toFixed(2));
// };

export const getCurrentStrDate = (): string => {
  return dayjs(new Date()).format("YYYY-MM-DD");
};

export const formatToISODate = (value: string) => {
  const [month = "", day = "", year = ""] = value?.split("/");
  const date = `${year}-${month}-${day}`;
  return date.replace(/^--?/, "");
};

export const isoDateToFormattedString = (value: string) => {
  const [year = "", month = "", day = ""] = value?.split("-");
  return `${month}/${day}/${year}`;
};
