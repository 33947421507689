import styled from "styled-components";
import { Grid } from "@mui/material";

export const HomePageContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
}));

// common styles for forms
export const GridContainer = styled(Grid)(() => ({
  "&.MuiGrid-container": {
    marginTop: "0",
  },
}));
GridContainer.defaultProps = {
  container: true,
  columnSpacing: 2,
  rowSpacing: 2,
};

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const FormContent = styled.div``;

export const Container = styled.div`
  padding: 20px;
  display: flex;
  flex: 1;
  min-height: 100%;

  & > div {
    min-height: 100%;
    flex: 1;
  }
`;

export const MemberRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
