import React from "react";
import { FastField } from "formik";
import { useTranslation } from "react-i18next";
import { FieldProps } from "formik/dist/Field";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { MenuItemStyled, StyledTextField } from "./FormikSelectField.css";
import { ExtraLabel } from "components";

interface Option {
  uuid: string;
  name: string;
}

interface FormikSelectFieldProps {
  name: string;
  label: string;
  settingKeyName?: keyof Option;
  options: Option[];
  extraLabel?: string;
  setUntranslatedValue?: boolean;
}

export const FormikSelectField = ({
  name,
  label,
  settingKeyName = "name",
  options,
  extraLabel,
  setUntranslatedValue,
  ...rest
}: FormikSelectFieldProps & TextFieldProps) => {
  const { t } = useTranslation();
  return (
    <>
      {extraLabel && <ExtraLabel label={extraLabel} />}
      <FastField name={name}>
        {({ field, meta, form }: FieldProps<string>) => {
          return (
            <StyledTextField
              {...field}
              {...rest}
              select
              fullWidth
              size="small"
              label={t(label)}
              variant="outlined"
              error={!!form.submitCount && Boolean(meta?.error)}
              helperText={Boolean(form.submitCount) && t(meta?.error)}
              autoComplete="off"
            >
              {options?.map((option) => (
                <MenuItemStyled
                  key={option.uuid}
                  value={
                    setUntranslatedValue
                      ? option[settingKeyName as keyof Option]
                      : t(option[settingKeyName as keyof Option])
                  }
                >
                  {t(option.name)}
                </MenuItemStyled>
              ))}
            </StyledTextField>
          );
        }}
      </FastField>
    </>
  );
};
