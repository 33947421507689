import styled from "styled-components";

export const WrapperPages = styled.div`
  margin: 2em;
  font-family: sans-serif;
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

export const FilesContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FileItem = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
`;
