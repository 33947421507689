import * as Yup from "yup";
import i18next from "i18next";
import { CurrentJobEnum } from "enums";
import { DetailJobMemberModel, IncomeTypeModel } from "models";
import { validation } from "formUtils/validation";

export const FORM_LIMIT_COUNT = 4;

export interface DetailsJobFormType {
  detailJobMembers: DetailJobMemberModel[];
}

export interface RenderMemberFormsByIncomeType {
  currentJob: CurrentJobEnum;
  hasSeasonalJob: boolean;
  isSelfEmployed: boolean;
  otherIncomeSource: string;
}

export const seasonableJobPlaceholder = {
  employerName: "",
  employerEin: "",
  employerStreetAddress: "",
  employerCity: "",
  employerZipCode: "",
  employerState: "",
  seasonalIncome: null,
  expectedUnemployment: null,
};
export const currentJobPlaceholder = {
  employerName: "",
  employerEin: "",
  employerStreetAddress: "",
  employerCity: "",
  employerZipCode: "",
  employerState: "",
  wage: "",
  hoursPerWeek: null,
  amount: null,
};
export const wageOptions = [
  { uuid: "hourly", name: "hourly" },
  { uuid: "weekly", name: "weekly" },
  { uuid: "every_two_weeks", name: "every_two_weeks" },
  { uuid: "twice_a_month", name: "twice_a_month" },
  { uuid: "monthly", name: "monthly" },
  { uuid: "yearly", name: "yearly" },
];

const currentJobShape = {
  employerName: validation.stringRequired,
  employerEin: validation.stringNotRequired,
  employerStreetAddress: validation.stringNotRequired,
  employerCity: validation.stringNotRequired,
  employerZipCode: validation.stringNotRequired,
  employerState: validation.stringNotRequired,
  wage: validation.stringRequired,
  hoursPerWeek: validation.numberRequired,
  amount: validation.numberRequired,
};

const seasonalJobShape = {
  employerName: validation.stringRequired,
  employerEin: validation.stringNotRequired,
  employerStreetAddress: validation.stringNotRequired,
  employerCity: validation.stringNotRequired,
  employerState: validation.stringNotRequired,
  employerZipCode: validation.stringNotRequired,
  seasonalIncome: validation.numberRequired,
  expectedUnemployment: validation.numberRequired,
};

const selfEmploymentShape = {
  selfEmployedName: validation.stringRequired,
  typeOfBusiness: validation.stringRequired,
  businessStartDate: validation.dateRequired,
  incomeAmount: validation.numberRequired,
  lossAmount: validation.numberRequired,
};

const otherIncomeTypeShape = {
  type: validation.stringNotRequired,
  amount: validation.numberNotRequired,
  frequency: Yup.string().when("amount", {
    is: (v: number) => !!v,
    then: () => validation.stringRequired,
    otherwise: () => validation.stringNotRequired,
  }),
};

const otherIncomeSourceShape = {
  typeOfIncome: validation.stringRequired,
  howOftenReceived: validation.stringRequired,
  amount: validation.numberRequired,
  notTaxableInterestIncome: validation.numberNotRequired,
  incomes: Yup.array().of(Yup.object().shape(otherIncomeTypeShape)),
};

const defineValidationFormRule = (
  incomeTypeMembers: IncomeTypeModel[],
  id: string,
  validationByFieldName: keyof IncomeTypeModel,
) => {
  const currentMember = incomeTypeMembers.find((m) => m.id === id);
  const value = (currentMember ?? {})[validationByFieldName];
  return (
    value === false ||
    value === "false" ||
    value === i18next.t("no") ||
    value === "no"
  );
};

export const detailJobMemberShape = (incomeTypeMembers: IncomeTypeModel[]) => {
  return {
    currentJobs: Yup.array()
      .of(Yup.object().shape(currentJobShape))
      .when("id", {
        is: (id: string) =>
          !defineValidationFormRule(incomeTypeMembers, id, "currentOrPartJob"),
        then: () => Yup.array().of(Yup.object().shape(currentJobShape)),
        otherwise: (schema) => schema.notRequired(),
      }),
    seasonalJobs: Yup.array().of(
      Yup.object().when("id", {
        is: (id: string) =>
          !defineValidationFormRule(incomeTypeMembers, id, "hasSeasonalJob"),
        then: () => Yup.object().shape(seasonalJobShape),
        otherwise: (schema) => schema.notRequired(),
      }),
    ),
    selfEmployment: Yup.object().when("id", {
      is: (id: string) =>
        !defineValidationFormRule(incomeTypeMembers, id, "isSelfEmployed"),
      then: () => Yup.object().shape(selfEmploymentShape),
      otherwise: (schema) => schema.notRequired(),
    }),

    otherIncomeSource: Yup.object().when("id", {
      is: (id: string) =>
        !defineValidationFormRule(incomeTypeMembers, id, "otherIncomeSource"),
      then: () => Yup.object().shape(otherIncomeSourceShape),
      otherwise: (schema) => schema.notRequired(),
    }),
  };
};

export const validationSchema = (incomeTypeMembers: IncomeTypeModel[]) =>
  Yup.object().shape({
    detailJobMembers: Yup.array().of(
      Yup.object().shape(detailJobMemberShape(incomeTypeMembers)),
    ),
  });
