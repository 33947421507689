import React, { useMemo } from "react";
import { PaiModel } from "models";
import { CaseNumber } from "pages/PdfPages/components";
import { HouseholdMembersFragment } from "graphql/fragments/householdMembersFragment";

const MIN_ROW_LENGTH = 6;

interface Props {
  caseNumber: string;
  householdMembers: HouseholdMembersFragment[];
  sameAnnualIncome: boolean;
}

export const PageTen = ({
  caseNumber,
  householdMembers,
  sameAnnualIncome,
}: Props) => {
  const currentYear = new Date().getFullYear();

  const paiMembers = householdMembers.map((p) => {
    return {
      id: p.id,
      name: p.pai.name,
      expectedIncome: p.pai.expectedIncome,
      adjustmentsIncome: p.pai.adjustmentsIncome,
      projectedAnnualIncome: p.pai.projectedAnnualIncome,
    };
  });

  const renderPaiRow = (item: PaiModel | undefined) => {
    return (
      <tr key={item?.id ?? 1}>
        <td className="border border-gray-700" colSpan={parseInt("")}>
          {item?.name ?? ""}
        </td>
        <td className="border border-gray-700" colSpan={parseInt("")}>
          <p>$ {item?.expectedIncome ?? ""}</p>
        </td>
        <td className="border border-gray-700" colSpan={parseInt("")}>
          <p>$ {item?.adjustmentsIncome ?? ""}</p>
        </td>
        <td className="border border-gray-700" colSpan={parseInt("")}>
          <p>$ {item?.projectedAnnualIncome ?? ""}</p>
        </td>
      </tr>
    );
  };

  const allMembersWithPlaceholders = useMemo(() => {
    // need to display min 6 rows for allMembers, with current members and fill with empty placeholders
    const membersCount = paiMembers?.length ?? 0;
    if (membersCount < MIN_ROW_LENGTH) {
      const existingMembers = paiMembers || [];
      const adjustmentPlaceholder = {
        id: "",
        name: "",
        expectedIncome: "",
        adjustmentsIncome: "",
        projectedAnnualIncome: "",
      };

      return Array.from(
        { length: MIN_ROW_LENGTH },
        (_, index) => existingMembers[index] || adjustmentPlaceholder,
      );
    }
    return paiMembers;
  }, [paiMembers]);

  const renderRadioButtons = () => {
    return (
      <p className="text-xs ml-8 ">
        <label className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio"
            name="isSameTotalIncome"
            value="Yes"
            readOnly
            checked={sameAnnualIncome}
          />
          <span className="ml-1">
            Yes – The total income expected for 2024 will be the same as the
            income listed on this form for each person in the household.
            Continue to question 13.
          </span>
        </label>
        <label className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio ml-0"
            name="isSameTotalIncome"
            value="No"
            readOnly
            checked={!sameAnnualIncome}
          />
          <span className="ml-1">
            No – The total income expected for 2024 will be different than the
            income listed on this form for one or more people in the household.
            For each person who expects a different total income:
          </span>
        </label>
      </p>
    );
  };

  return (
    <div className="container-fluid pt-20">
      <div className="contentContainer flex flex-col space-y-4">
        <CaseNumber caseNumber={caseNumber} />
        <div className="array_container border border-gray-700 bg-[#66666631] p-2">
          <div>
            <h2 className="font-bold text-base">
              12. Do you expect your projected annual income for {currentYear}{" "}
              to be the same as the income you listed on this form?
            </h2>
            <p className="ml-8 text-xs">
              Projected annual income is the total income that a person expects
              to have for the entire year, from January through December
            </p>
            {renderRadioButtons()}
            <div className="ml-8 text-xs mb-4">
              <ul className="mb-4">
                <li>
                  1. Enter all income expected in Box A. Include all the income
                  you would list on a tax return, plus nontaxable Social
                  Security benefits, tax exempt interest and foreign income. See
                  questions 7-10 for types of income to include.
                </li>
                <li>
                  2. Enter all expected adjustments to income in Box B. See
                  question 11 for types of adjustments
                </li>
                <li>
                  3. Subtract the amount in box B from box A. (A-B). Enter the
                  result in box C.
                </li>
              </ul>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr className="text-xs">
                <th className="w-2/5" colSpan={parseInt("")}>
                  Name
                </th>
                <th colSpan={parseInt("")}>
                  A. Expected income <br />
                  for {currentYear}
                </th>
                <th colSpan={parseInt("")}>
                  B. Adjustments to <br /> Income for {currentYear}
                </th>
                <th colSpan={parseInt("")}>
                  C. *Projected annual <br /> income for {currentYear}
                </th>
              </tr>
            </thead>
            <tbody className="text-xs">
              {allMembersWithPlaceholders?.map((item) => {
                return renderPaiRow(item);
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
