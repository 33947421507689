import React from "react";
import { CaseNumber } from "pages/PdfPages/components";

export const PageSeventeen: React.FC<{ caseNumber: string }> = ({
  caseNumber,
}) => {
  return (
    <div className="container-fluid mt-12 pt-12">
      <div className="contentContainer flex flex-col space-y-4">
        <CaseNumber caseNumber={caseNumber} />
        <div className="p-2">
          <div>
            <h2 className="text-base font-bold">
              Do I have to pay back the costs of my health care if I am
              receiving government assistance?
            </h2>
            <div className="text-xs">
              <p>
                In certain circumstances, federal and state law require the
                Minnesota Department of Human Services and local agencies to
                recover costs that the MA program paid for its members. This
                recovery process is done through Minnesota’s MA estate recovery
                and lien program. Read the following if you are enrolled in MA.
              </p>
              <p className="my-2">
                If you are enrolled in MA, then, after you die, Minnesota must
                try to recover the costs of any long-term services and supports
                (LTSS) you received at 55 years old or older. LTSS include:
              </p>
              <ul>
                <li>• Nursing home services</li>
                <li>• Home and community-based services</li>
                <li>• Related hospital and prescription drug costs</li>
                <li>
                  • Managed care premiums (capitations) for coverage of these
                  services
                </li>
              </ul>
              <p className="mt-2">
                Even after you die, Minnesota cannot recover these costs if your
                spouse survives you, you have a child under 21 years old, or you
                have a child who is blind or permanently disabled. Once your
                spouse dies, Minnesota must try to recover your MA LTSS costs
                from your spouse’s estate. However, recovery is further delayed
                if you still have a child who is under 21 years old, blind, or
                permanently disabled. Your children do not have to use their
                assets to reimburse the state for any MA services you received.
              </p>
              <p className="my-2">
                Also, Minnesota must try to recover the costs of all MA services
                an MA member received at any age while permanently living in a
                medical institution. However, MA members who qualify for
                services under modified adjusted gross income (MAGI) eligibility
                criteria are not subject to recovery for services received
                before the age of 55.
              </p>
              <p>
                The state may file an MA lien against your real property to
                recover MA costs before your death, but only if you are
                permanently living in a medical institution. The state also may
                file a notice of potential claim, which is a form of lien,
                against real property to recover MA costs after death. Liens to
                recover MA costs may be filed against the following:
              </p>
              <ul>
                <li>
                  • Your life estate or joint tenancy interest in real property
                </li>
                <li>• Your real property that you own solely</li>
                <li>• Your real property that you own with someone else</li>
              </ul>
              <p className="mt-2">
                You have the right to speak with a legal-aid group or a private
                attorney if you have specific questions about how MA estate
                recovery and liens may affect your circumstance and estate
                planning. The Minnesota Department of Human Services cannot
                provide you with legal advice. For more information, go to
                http://mn.gov/dhs/ma-estate-recovery/.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
