import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";

interface Props {
  type: AlertColor;
  message: string | null;
  setMessage?: (message: string | null) => void;
  isStaticNotification?: boolean;
}

export const Notification = ({
  type,
  message,
  setMessage,
  isStaticNotification,
}: Props) => {
  const handleCloseNotification = (
    _: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage?.(null);
  };

  const conditionalProps = {
    ...(isStaticNotification && { autoHideDuration: 4000 }),
    ...(isStaticNotification && { onClose: handleCloseNotification }),
  };

  return (
    <Snackbar
      {...conditionalProps}
      style={{ minHeight: "fit-content" }}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      open={!!message}
    >
      <Alert onClose={handleCloseNotification} severity={type} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};
